package com.halal.stocks.utils

import com.halal.stocks.data.model.IpoTimetableModel
import com.halal.stocks.data.model.RULES
import com.halal.stocks.data.model.mapping.StockDataVo
import com.halal.stocks.data.model.mapping.debt
import com.halal.stocks.data.model.mapping.fixedAsset
import com.halal.stocks.data.model.mapping.intangibleAssets
import com.halal.stocks.data.model.mapping.inventory
import com.halal.stocks.data.model.mapping.marketCapitalization
import com.halal.stocks.data.model.mapping.nonPermissibleIncomeFromOperationSegment
import com.halal.stocks.data.model.mapping.otherNonPermissibleIncome
import com.halal.stocks.data.model.mapping.totalAsset
import com.halal.stocks.data.model.mapping.totalInterestIncome
import com.halal.stocks.data.model.mapping.totalRevenue
import kotlin.math.roundToInt

// Copyright © 2022 Compass. All rights reserved.
class StringProvider(private val halalScreener: HalalScreener) {

    fun getRulesNominatorDenominatorResult(
        r: RULES,
        stockDataVo: StockDataVo
    ): Triple<String, String, String> {
        when (r) {
            RULES.TWO -> {
                return Triple(
                    first = "${
                        stockDataVo.nonPermissibleIncomeFromOperationSegment().roundTwoDecimal().toPlainString()
                    } + ${
                        stockDataVo.totalInterestIncome().roundTwoDecimal().toPlainString()
                    } + ${stockDataVo.otherNonPermissibleIncome().roundTwoDecimal().toPlainString()}",
                    second = (stockDataVo.totalRevenue() + stockDataVo.totalInterestIncome() + stockDataVo.otherNonPermissibleIncome()).roundTwoDecimal().toPlainString(),
                    third = "=${halalScreener.getNonPermissiblePercent(stockDataVo)}%"
                )
            }
            RULES.THREE -> {
                return Triple(
                    first = stockDataVo.debt().roundTwoDecimal().toPlainString(),
                    second = (stockDataVo.totalAsset().roundTwoDecimal().toPlainString()),
                    third = "=${halalScreener.getRule3DebtPercent(stockDataVo)}%"
                )
            }
            RULES.FOUR -> {
                return Triple(
                    first = "${stockDataVo.fixedAsset().roundTwoDecimal().toPlainString()} + " +
                            "${stockDataVo.inventory().roundTwoDecimal().toPlainString()} + " +
                            stockDataVo.intangibleAssets().roundTwoDecimal().toPlainString(),
                    second = (stockDataVo.totalAsset().roundTwoDecimal().toPlainString()),
                    third = "=${halalScreener.getRule4IlliquidAssetPercent(stockDataVo)}%"
                )
            }
            RULES.FIVE -> {
                return Triple(
                    first = "${halalScreener.getNetLiquidAsset(stockDataVo).roundTwoDecimal().toPlainString()} " +
                            "${if (halalScreener.isRule5MarketCapHalal(stockDataVo)) "<" else ">"} " +
                            stockDataVo.marketCapitalization().roundTwoDecimal().toPlainString(),
                    second = "",
                    third = ""
                )
            }
            else -> return Triple("", "", "")
        }
    }

    fun getCurrentTaqwaString(): String {
        return "Rule 2:   ${halalScreener.getRule2MaxNonPermissibleAllowedPercent()}%,     " +
                "Rule 3:   ${halalScreener.getRule3MaxDebtAllowedPercent()}%,     " +
                "Rule 4:   ${halalScreener.getRule4MaxIlliquidAssetAllowedPercent()}%"
    }

    fun getRulesPercentStrings(stockDataVo: StockDataVo?): Triple<String, String, String> {
        return if (stockDataVo != null) {
            val one = "${
                try {
                    halalScreener.getNonPermissiblePercent(stockDataVo).roundToInt()
                } catch (ex: Exception) {
                    -1
                }
            }%"
            val two = "${
                try {
                    halalScreener.getRule3DebtPercent(stockDataVo).roundToInt()
                } catch (ex: Exception) {
                    -1
                }
            }%"
            val three = "${
                try {
                    halalScreener.getRule4IlliquidAssetPercent(stockDataVo).roundToInt()
                } catch (ex: Exception) {
                    -1
                }
            }%"
            Triple(one, two, three)
        } else
            Triple("", "", "")
    }
}