package com.halal.stocks.data.api

import com.halal.stocks.data.model.DonationHistoryModel
import com.halal.stocks.utils.RConfig
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.coroutines.flow.MutableSharedFlow
import org.koin.core.component.KoinComponent

//
class ApiClientRepo(private val client: HttpClient,private val rConfig: RConfig) : KoinComponent {



     suspend fun downloadFileWithdUrl(
        databaseUrl: String,
        _downloadProgress: MutableSharedFlow<String>?
    ): HttpResponse {
        return client.get(databaseUrl) {
            onDownload { bytesSentTotal, contentLength ->
                val percentage = (bytesSentTotal.toDouble()/contentLength.toDouble())*100
                _downloadProgress?.emit("Download Progress: ${percentage.toInt()}%")
            }
        }
    }




/*     suspend fun getAllBasketsOfUser(loggedInUserId: String?, userid: String?): List<BasketVo> {
        return client.get("$endPointUrl/api/baskets/$userid"){
            headers["loggedInUserId"]= loggedInUserId.toString()
        }.body()
    }*/



     suspend fun registerDonationAtServer(donationHistoryModel: DonationHistoryModel): String {
        return client.post("${rConfig.getAPIUrl}/user/donation"){
            contentType(ContentType.Application.Json)
            setBody(donationHistoryModel)
        }.body()
    }

    //Get and update CSV to get basket idea latest price
/*   suspend fun getBasketIdeaPrice(symbol: String, loggedInUserId: String?): List<String> { //// 7D | 30D | 90D | 180D | 360D | LTP
        return client.get("$endPointUrl/basketIdea/$symbol"){
            headers["loggedInUserId"]= loggedInUserId.toString()
        }.body()
    }*/

}