package com.halal.stocks.ui.ipos

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import kotlinx.coroutines.Dispatchers
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.ui.asCommonFlow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn

class IPOViewModel(private val stock2022DatabaseRepo: Stock2022DatabaseRepo) : ViewModel() {

    fun fetchAllIpoStocksFlow() = flow {
        emit(stock2022DatabaseRepo.getAllIPOs().reversed())
    }.flowOn(Dispatchers.Default).asCommonFlow()
}