package ui.pages

import AppDependenciesContext
import ApplicationProps
import com.halal.stocks.ui.downloads.DownLoadStepAndState
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import mainScope
import mui.material.Box
import mui.material.Typography
import react.FC
import react.router.useNavigate
import react.useContext
import react.useEffectOnce

val DownloadPage = FC<ApplicationProps> { props ->

    console.log("download page isDownloaded = ${props.isDownloaded}")

    val navigate = useNavigate()
    val appDependencies = useContext(AppDependenciesContext)

    useEffectOnce {

        // check if app already initialized
        // if yes avoid downloading again
        val sdk = appDependencies.ourSDK
        sdk.downloadingFragmentViewModel.handleStockDataDownload()

        mainScope.launch {
            sdk.downloadingFragmentViewModel.downloadFileLiveData.collectLatest { isDownloaded ->
                when (isDownloaded) {
                    DownLoadStepAndState.CSV_DOWNLOADED -> {
                        console.log("database downloaded $props")
                        props.setIsDownloaded()

                        // navigate to home page
                        navigate("/home")
                    }
                    else -> {
                        console.log("$isDownloaded")
                    }
                }
            }
        }
    }

    Box {
        if (!props.isDownloaded) {
            Typography {
                +"Downloading Stock Database"
            }
            Typography {
                +"Please wait..."
            }
        } else {
            Typography {
                +"Downloading completed"
            }
        }
    }
}