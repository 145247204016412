package ui.components

import AppDependenciesContext
import ApplicationProps
import com.halal.stocks.data.model.mapping.name
import com.halal.stocks.data.model.mapping.nseCode
import csstype.px
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import kotlinx.js.ReadonlyArray
import mainScope
import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.Box
import mui.material.TextField
import mui.system.sx
import react.FC
import react.ReactNode
import react.create
import react.router.useNavigate
import react.useContext
import react.useState
import ui.pages.Stock

val StockAutoCompleteTextView = FC<ApplicationProps> { props ->

    val appDependencies = useContext(AppDependenciesContext)
    val sdk = appDependencies.ourSDK

    val navigate = useNavigate()

    val (stocks, setStocks) = useState<ReadonlyArray<Stock>>(mutableListOf<Stock>().toTypedArray())

    fun queryStock(query: String) {
        mainScope.launch {
            // load all stocks
            sdk.searchViewModel.searchStockFlowWithLimit(query).collectLatest { data ->
                val all = mutableListOf<Stock>()
                data.forEach { stock ->
                    all.add(Stock(stock.name(), stock.nseCode()))
                }
                setStocks(all.toTypedArray())
            }
        }
    }

    Box {
        @Suppress("UPPER_BOUND_VIOLATED")
        Autocomplete<AutocompleteProps<Stock>> {
            freeSolo = true
            sx { width = 300.px }
            options = stocks
            disablePortal = true
            renderInput = { params ->
                TextField.create {
                    +params
                    label = ReactNode("Search halal stocks")
                }
            }
            onChange = { _, option, _, _ ->
                run {
                    (option as? Stock)?.nseCode?.let { navigate("/stock/$it") }
                }
            }
            onInputChange = { _, newQuery, _ ->
                run {
                    queryStock(newQuery)
                }
            }
        }
    }
}