package com.halal.stocks.ui.result

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import kotlinx.coroutines.Dispatchers
import com.halal.stocks.data.api.NetworkRepo
import com.halal.stocks.data.model.FinancialResultType
import com.halal.stocks.data.model.mapping.StockDataVo
import com.halal.stocks.data.model.mapping.nseCode
import com.halal.stocks.data.api.priceupdate.RealTimePriceDataRepo
import com.halal.stocks.data.api.screener.ScreenerRepo
import com.halal.stocks.data.api.stocks.Stock2021DatabaseRepo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.api.user.UserRepo
import com.halal.stocks.ui.asCommonFlow
import com.halal.stocks.utils.HalalScreener
import com.halal.stocks.utils.StringProvider
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn

class ResultFragmentViewModel(private val halalScreener: HalalScreener,
                              private val stringProvider: StringProvider,
                              private val dao2022: Stock2022DatabaseRepo,
                              private val dao2021: Stock2021DatabaseRepo,
                              private val realTimeDataRepo: RealTimePriceDataRepo,
                              private val networkRepo: NetworkRepo,
                              private val screenerRepo: ScreenerRepo,
) : ViewModel() {

    suspend fun getStockDataByNSECodeAsync(nseCode: String, financialResultType: FinancialResultType) : StockDataVo? {
        return when (financialResultType) {
            FinancialResultType.YEAR_2021 -> dao2021.getStockByNseCode(nseCode)
            FinancialResultType.YEAR_2022 -> dao2022.getStockByNseCode(nseCode)
        }
    }

    fun getStockDataByNSECodeFlow(nseCode: String, financialResultType: FinancialResultType) = flow {
        val result:StockDataVo? = when (financialResultType) {
            FinancialResultType.YEAR_2021 -> dao2021.getStockByNseCode(nseCode)
            FinancialResultType.YEAR_2022 -> dao2022.getStockByNseCode(nseCode)
        }
        emit(result)
    }.flowOn(Dispatchers.Default).asCommonFlow()

    fun getRealTimeStockDataFlow(stockDataVo: StockDataVo?) = realTimeDataRepo.monitorAllRealtimePrice(stockDataVo?.nseCode()!!).flowOn(
        Dispatchers.Default).asCommonFlow()

    suspend fun downloadAnnualReport(saveName: String,url:String) = networkRepo.downloadAnnualReport(saveName,url,null)

    fun isStockHalal(data: StockDataVo)= halalScreener.isStockHalal(data)
    fun isRule1BusinessHalal(data: StockDataVo)=  halalScreener.isRule1BusinessHalal(data)
    fun isRule2NonIncomeHalal(data: StockDataVo)=  halalScreener.isRule2NonIncomeHalal(data)
    fun isRule3DebtHalal(data: StockDataVo)=  halalScreener.isRule3DebtHalal(data)
    fun isRule4IlliquidAssetHalal(data: StockDataVo)=  halalScreener.isRule4IlliquidAssetHalal(data)
    fun isRule5MarketCapHalal(data: StockDataVo)=  halalScreener.isRule5MarketCapHalal(data)

    fun getRulesPercents(data: StockDataVo)=stringProvider.getRulesPercentStrings(data)

    fun getCurrentTaqwaString() = stringProvider.getCurrentTaqwaString()

    fun getAllScreenerThatHaveNseCode(nseCode: String)= screenerRepo.getAllScreenerThatHaveNseCode(nseCode)

}