package com.halal.stocks.data.api.priceupdate.data

import com.halal.stocks.data.api.ApiHttpClient
import com.halal.stocks.data.model.DailyUpdateModelApp

class PriceUpdateNetworkRepo(private val client: ApiHttpClient) {

   suspend fun getRealTimeStockData(symbol: String): DailyUpdateModelApp? {
       if (symbol=="null.NS" || symbol=="null.BO")
           return null
        return client.get("/ltp/$symbol")
    }

    //Not Auth Required
    suspend fun getAllLTP() : List<DailyUpdateModelApp>?{
        return client.get("/getAllLTP")
    }
}