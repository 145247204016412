package com.halal.stocks

import com.halal.stocks.data.api.remoteconfig.RemoteConfigRepo
import com.halal.stocks.ui.SettingsViewModel
import com.halal.stocks.ui.TestViewModel
import com.halal.stocks.ui.allhalal.AllHalalListViewModel
import com.halal.stocks.ui.basket.BasketDetailViewModel
import com.halal.stocks.ui.basket.BasketListViewModel
import com.halal.stocks.ui.downloads.DownloadingFragmentViewModel
import com.halal.stocks.ui.ipos.IPOViewModel
import com.halal.stocks.ui.profile.MyBasketViewModel
import com.halal.stocks.data.api.notifications.NotificationViewModel
import com.halal.stocks.ui.result.ResultFragmentViewModel
import com.halal.stocks.ui.search.SearchViewModel
import com.halal.stocks.ui.signin.SigninViewModel
import com.halal.stocks.utils.HalalScreener
import com.halal.stocks.utils.RConfig
import com.halal.stocks.utils.StringProvider
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

/**changing values of DATABASE_VERSION will recreate entire database on android,**/
internal const val DATABASE_VERSION="Db_Version_V11"

class IslamicStockSDK : KoinComponent {

    val rConfig by inject<RConfig>()
    val halalScreener:HalalScreener by inject()
    val stringProvider:StringProvider by inject()

    /**
     * Repositories
     */
    private val remoteConfigRepo: RemoteConfigRepo by inject()

    /**
     * View Models
     */
    val testViewModel by inject<TestViewModel>()
    val allHalalListViewModel by inject<AllHalalListViewModel>()
    val downloadingFragmentViewModel by inject<DownloadingFragmentViewModel>()
    val searchViewModel by inject<SearchViewModel>()
    val basketDetailViewModel by inject<BasketDetailViewModel>()
    val basketListViewModel by inject<BasketListViewModel>()
    val resultFragmentViewModel by inject<ResultFragmentViewModel>()

//    val pageViewModel by inject<PageViewModel>()

    val settingsViewModel by inject<SettingsViewModel>()
    val ipoViewModel by inject<IPOViewModel>()
    val myBasketViewModel by inject<MyBasketViewModel>()
    val notificationViewModel by inject<NotificationViewModel>()
    val signinViewModel by inject<SigninViewModel>()

    /**
     * Update app forcefully
     * */
    fun isAppForceUpdateAvailable(currentVersionCode:Int): Boolean {
        return remoteConfigRepo.isForceUpdate(currentVersionCode)
    }

    suspend fun isCsvSilentUpdateAvailableAvailable(): Boolean {
       return remoteConfigRepo.isCsvSilentUpdateAvailableAvailable()
    }

    fun isCSVUpdateFor1stTime(): Boolean {
        return remoteConfigRepo.isCSVUpdateFor1stTime()
    }


    fun getUrl(): String {
        return remoteConfigRepo.getDatabaseUrl()
    }
}