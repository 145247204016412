package com.halal.stocks.data

import com.halal.stocks.IslamicStockVN2
import com.halal.stocks.UserDataRoomQueries

class AppDatabase(database: IslamicStockVN2) {

    val getNotificationDao by lazy { database.notificationRoomQueries }
    val getRealtimeDataDao by lazy { database.realtimeDataRoomQueries }
    val getBasketDataDao by lazy { database.basketDataRoomQueries }

    val getUserDao: UserDataRoomQueries? by lazy { database.userDataRoomQueries }

    val getPortfolioDao by lazy { database.portfolioDataRoomQueries }
    val getPortfolioHoldingDao by lazy { database.portfolioHoldingDataRoomQueries }

    val getScreenerDao by lazy { database.screenerDataRoomQueries }

    val getSubscriptionDao by lazy { database.subscriptionStatusDatabaseQueries }

    val getStock2021Dao by lazy { database.stock2021DataRoomQueries }
    val getStock2022Dao by lazy { database.stock2022DataRoomQueries }

    val getWatchlistDataDao by lazy { database.watchlisDataRoomQueries }
    val getWatchlistNameDao by lazy { database.watchlisNameRoomQueries }

    val trackingDataRoomQueries by lazy { database.trackingDataRoomQueries }
    val trackingNameRoomQueries by lazy { database.trackingNameRoomQueries }

}