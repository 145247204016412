package com.halal.stocks.data.room.watchlist.usecases

import com.halal.stocks.WatchlistDataTable
import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.api.*
import com.halal.stocks.data.api.user.auth.UserAuthRepo
import com.halal.stocks.data.room.portfolio.usecases.makeJsonUseAble
import com.halal.stocks.utils.objectify
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*

class GetWatchlistDataFromServerUseCase(
    private val database: DatabaseFactory,
    private val apiHttpClient: ApiHttpClient
) : IUseCase {

    override suspend fun execute(loggedInUserId: String): Boolean {
        return try {
           val serverData = apiHttpClient.get<String?>("/getAllWatchlistJson")?.makeJsonUseAble()

            if (serverData!=null && serverData.isNotEmpty()){
                val dataServer = serverData.objectify<List<WatchlistServerSyncVo>>()
                dataServer.forEach { data->
                    database.appDatabase.getWatchlistNameDao.insertFromServer(data.nameTable.pKey,data.nameTable.watchlistName)
                    data.listOfData.forEach {
                        database.appDatabase.getWatchlistDataDao.insert(WatchlistDataTable(it.watchlistPKey,it.nseCode))
                    }
                }
            }
            true
        } catch (ex: Exception) {
            ex.printStackTrace()
            false
        }
    }

}