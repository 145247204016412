package com.halal.stocks.ui.basket

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import com.halal.stocks.data.api.basket.basket.BasketVo
import com.halal.stocks.data.api.basket.BasketRepo
import com.halal.stocks.data.api.priceupdate.RealTimePriceDataRepo
import com.halal.stocks.data.api.user.UserRepo
import com.halal.stocks.ui.asCommonFlow
import com.halal.stocks.utils.LoadingStates
import com.halal.stocks.utils.StockReturnCalculator
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.map

open class BasketListViewModel(
    private val basketRepo: BasketRepo,
    private val userRepo: UserRepo,
    private val realTimeDataRepo: RealTimePriceDataRepo
) : ViewModel() {

    val monitorLoadingState = MutableStateFlow(LoadingStates.HIDE_LOADING)

    fun monitorAllBasket() = basketRepo.monitorAllBasket().map {
        it?.forEach { basketVo->
            basketVo.mapOfPercentChange = StockReturnCalculator.getBasketChangeData(basketVo,realTimeDataRepo)
        }
        it
    }.asCommonFlow()

    /*fun basketListFlow() = flow {
        emit(LoadingStates.SHOW_LOADING)
        basketRepo.getAllBaskets()?.let {
            emit(it)
            emit(LoadingStates.HIDE_LOADING)
        }?: kotlin.run {
            emit(LoadingStates.LOADING_FAILED)
        }
    }.flowOn(Dispatchers.Default).asCommonFlow()*/

    suspend fun followBasket(basketVo: BasketVo) =
        basketRepo.followBasket(basketVo.basketId, userRepo.getLoggedInUser())
}