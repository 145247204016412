package ui.utils

import csstype.px

object Sizes {
    object Header {
        val Height = 64.px
    }

    object Sidebar {
        val Width = 200.px
    }
}