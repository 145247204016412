package com.halal.stocks.data.api.stocks

import com.halal.stocks.data.api.ApiHttpClient
import com.halal.stocks.data.api.addRequiredHeaders
import com.halal.stocks.data.model.mapping.StockDataVo2022
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class StockNetworkRepo(private val client: ApiHttpClient) {

    suspend fun getAllRecentlyUpdatedStocks(lastMaxUpdatedTimeATClientSide:Long): List<StockDataVo2022>{
        return client.get("/getAllRecentlyUpdatedStocks/$lastMaxUpdatedTimeATClientSide")?: emptyList()
    }

    suspend fun getAllEncryptedStocksData(url:String): List<List<String>>{
        return client.get(url)?: emptyList()
    }
}