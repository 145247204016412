package ui.pages

import ApplicationProps
import com.halal.stocks.IslamicStockSDK
import com.halal.stocks.data.model.mapping.StockDataVo
import com.halal.stocks.data.model.mapping.bseCode
import com.halal.stocks.data.model.mapping.industry
import com.halal.stocks.data.model.mapping.isHalal
import com.halal.stocks.data.model.mapping.name
import com.halal.stocks.data.model.mapping.nseCode
import csstype.AlignItems
import csstype.Display
import csstype.JustifyContent
import csstype.pct
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import mainScope
import mui.material.Card
import mui.material.CardContent
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.router.useNavigate
import react.router.useParams
import react.useEffectOnce
import react.useState
import theme.Colors

val StockResultPage = FC<ApplicationProps> { props ->

    val navigate = useNavigate()
    val nseCode = useParams()

    val (stockData, setStockData) = useState<StockDataVo?>(null)

    val (isRule1Halal, setIsRule1Halal) = useState(false)
    val (isRule2Halal, setIsRule2Halal) = useState(false)
    val (isRule3Halal, setIsRule3Halal) = useState(false)
    val (isRule4Halal, setIsRule4Halal) = useState(false)
    val (isRule5Halal, setIsRule5Halal) = useState(false)

    useEffectOnce {

        // if data not downloaded, navigate to download page
        if (!props.isDownloaded) {
            navigate("/download")
        } else {
            val code = nseCode["nseCode"]
            console.log(code)
            mainScope.launch {
                val sdk = IslamicStockSDK()
                val fy = sdk.rConfig.getDefaultFinancialResultType()
                code?.let {
                    sdk.resultFragmentViewModel.getStockDataByNSECodeFlow(it, fy)
                        .collectLatest { data ->
                            console.log(data)
                            setStockData(data)

                            data?.let { it1 ->
                                sdk.resultFragmentViewModel.isRule1BusinessHalal(
                                    it1
                                )
                            }?.let { it2 ->
                                setIsRule1Halal(it2)
                            }
                            data?.let { it1 ->
                                sdk.resultFragmentViewModel.isRule2NonIncomeHalal(
                                    it1
                                )
                            }?.let { it2 ->
                                setIsRule2Halal(it2)
                            }
                            data?.let { it1 -> sdk.resultFragmentViewModel.isRule3DebtHalal(it1) }
                                ?.let { it2 ->
                                    setIsRule3Halal(it2)
                                }
                            data?.let { it1 ->
                                sdk.resultFragmentViewModel.isRule4IlliquidAssetHalal(
                                    it1
                                )
                            }?.let { it2 ->
                                setIsRule4Halal(it2)
                            }
                            data?.let { it1 ->
                                sdk.resultFragmentViewModel.isRule5MarketCapHalal(
                                    it1
                                )
                            }?.let { it2 ->
                                setIsRule5Halal(it2)
                            }
                        }
                }
            }
        }
    }

    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(2)
        sx {
            display = Display.flex
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
        }

        Stack {
            direction = responsive(StackDirection.column)
            sx {
                display = Display.flex
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
            }

            Typography {
                variant = TypographyVariant.h2
                sx {
                    color =
                        if (stockData?.isHalal() == true) Colors.compliantColor else Colors.nonCompliantColor
                }
                if (stockData?.isHalal() == true) {
                    +"Shariah Compliant"
                } else {
                    +"Not Shariah Compliant"
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            sx {
                display = Display.flex
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
            }

            Typography {
                variant = TypographyVariant.h5
                sx {
                    color = Colors.offWhiteDeep
                }
                +"${stockData?.name()}"
            }
            Typography {
                variant = TypographyVariant.h6
                sx {
                    color = Colors.textLabelColor
                }
                +"${stockData?.industry()}"
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(6)

            Stack {
                direction = responsive(StackDirection.column)
                sx {
                    display = Display.flex
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                }

                Typography {
                    variant = TypographyVariant.h6
                    sx {
                        color = Colors.textLabelColor
                    }
                    +"Instrument Code"
                }

                Typography {
                    variant = TypographyVariant.h5
                    sx {
                        color = Colors.white
                    }
                    +"${stockData?.nseCode()}"
                }
            }

            Stack {
                direction = responsive(StackDirection.column)
                sx {
                    display = Display.flex
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                }

                Typography {
                    variant = TypographyVariant.h6
                    sx {
                        color = Colors.textLabelColor
                    }
                    +"Instrument Id"
                }
                Typography {
                    variant = TypographyVariant.h5
                    sx {
                        color = Colors.white
                    }
                    +"${stockData?.bseCode()}"
                }
            }
        }

        val rulesData = arrayOf(
            arrayOf("1. The Business", isRule1Halal),
            arrayOf("2. Income from non-sharia compliant investments", isRule2Halal),
            arrayOf("3. Interest-bearing debt to total assets", isRule3Halal),
            arrayOf("4. Illiquid assets to total assets ration", isRule4Halal),
            arrayOf("5. Net liquid assets vs market capitalisation", isRule5Halal)
        )

        for ((i, item) in rulesData.withIndex()) {
            Card {
                sx {
                    width = 100.pct
                    background = Colors.resultItemBackground
                }

                CardContent {
                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            display = Display.flex
                            alignItems = AlignItems.center
                            justifyContent = JustifyContent.spaceBetween
                        }

                        Typography {
                            variant = TypographyVariant.h5
                            sx {
                                color = Colors.white
                            }
                            +"${item[0]}"
                        }

                        Typography {
                            variant = TypographyVariant.h5
                            sx {
                                color =
                                    if (item[1] == true) Colors.compliantColor else Colors.nonCompliantColor
                            }
                            +if (item[1] == true) "PASS" else "FAIL"
                        }
                    }
                }
            }
        }
    }
}