package com.halal.stocks.data.model.mapping

import com.halal.stocks.StockDbVo2021Table
import com.halal.stocks.StockDbVo2022Table
import com.halal.stocks.data.api.stocks.CoreActivityEnum
import com.halal.stocks.data.model.CompanyAssetProfileModel
import com.halal.stocks.data.model.DailyUpdateModelApp
import com.halal.stocks.utils.*
import io.ktor.util.*
import kotlinx.datetime.Clock
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import our.libs.parcel.Parcelable
import our.libs.parcel.Parcelize

sealed class StockDataVo

fun StockDataVo2022.toStockDbVo2022Table() = StockDbVo2022Table(
    name = name,
    nse_code = nseCode,
    bse_code = bseCode,
    industry = industry,
    isCoreActivityHalal = isCoreActivityHalal.value.toLong(),
    businessDetail = businessDetail,
    debt = debt,
    market_capitalization = marketCapitalization,
    total_asset = totalAsset,
    current_asset = currentAsset,
    non_current_asset = nonCurrentAsset,
    fixed_asset = fixedAsset,
    intangible_assets = intangibleAssets,
    inventory = inventory,
    total_liabilities = totalLiabilities,
    current_liabilities = currentLiabilities,
    non_current_liabilities = nonCurrentLiabilities,
    total_revenue = totalRevenue,
    interest_income = totalInterestIncome,
    annual_report_url = annualReportUrl,
    watchListPKeys = watchListPKeyArray.jsonify(),
    isHalal = isHalal,
    outstanding_no_share = outstandingNoOfShare,
    isActive = isActive,
    isIPO = isIPO,
    ipoLogo = ipoLogo,
    ipoTimetableJson = ipoTimetableJson,
    assetProfileJSON = assetProfileJSON,
    dailyPriceJson = dailyUpdateModel?.toJson(),
    isBSE = isBSE == true,
    otherNonPermissibleIncome = otherNonPermissibleIncome,
    nonPermissibleIncomeOperationSegment = nonPermissibleIncomeOperationSegment,
    lastUpdatedTime=lastUpdatedTime
)


fun StockDbVo2022Table.toStockDataVo2022() = StockDataVo2022(
    name = name,
    nseCode = nse_code,
    bseCode = bse_code,
    industry = industry,
    isCoreActivityHalal = CoreActivityEnum.getByValue(isCoreActivityHalal),
    businessDetail = businessDetail,
    debt = debt,
    marketCapitalization = market_capitalization,
    totalAsset = total_asset,
    currentAsset = current_asset,
    nonCurrentAsset = non_current_asset,
    fixedAsset = fixed_asset,
    intangibleAssets = intangible_assets,
    inventory = inventory,
    totalLiabilities = total_liabilities,
    currentLiabilities = current_liabilities,
    nonCurrentLiabilities = non_current_liabilities,
    totalRevenue = total_revenue,
    totalInterestIncome = interest_income,
    annualReportUrl = annual_report_url,
    watchListPKeyArray = watchListPKeys?.objectify()?: HashSet(),
    isHalal = isHalal,
    outstandingNoOfShare = outstanding_no_share,
    isActive = isActive,
    isIPO = isIPO,
    ipoLogo = ipoLogo,
    ipoTimetableJson = ipoTimetableJson,
    assetProfileJSON = assetProfileJSON,
    dailyUpdateModel = dailyPriceJson?.toDailyUpdateModelApp(),
    isBSE = isBSE,
    otherNonPermissibleIncome = otherNonPermissibleIncome,
    nonPermissibleIncomeOperationSegment = nonPermissibleIncomeOperationSegment,
    lastUpdatedTime=lastUpdatedTime
)

fun StockDataVo2021.toStockDbVo2021Table() = StockDbVo2021Table(
    name = name,
    nse_code = nseCode,
    bse_code = bseCode,
    industry = industry,
    isCoreActivityHalal = isCoreActivityHalal,
    businessDetail = businessDetail,
    debt = debt,
    market_capitalization = marketCapitalization,
    total_asset = totalAsset,
    current_asset = currentAsset,
    non_current_asset = nonCurrentAsset,
    fixed_asset = fixedAsset,
    intangible_assets = intangibleAssets,
    inventory = inventory,
    total_liabilities = totalLiabilities,
    current_liabilities = currentLiabilities,
    non_current_liabilities = nonCurrentLiabilities,
    total_revenue = totalRevenue,
    interest_income = totalInterestIncome,
    annual_report_url = annualReportUrl,
    watchListIndex = watchListIndex.toLong(),
    isHalal = isHalal,
    outstanding_no_share = outstandingNoOfShare.toLong(),
    isActive = isActive == 1,
    isIPO = isIPO,
    ipoLogo = ipoLogo,
    ipoTimetableJson = ipoTimetableJson,
    interest_expense = interestExpense,
    net_interest_income = netInterestIncome
)

fun StockDbVo2021Table.toStockDataVo2021() = StockDataVo2021(
    name = name,
    nseCode = nse_code,
    bseCode = bse_code,
    industry = industry,
    isCoreActivityHalal = isCoreActivityHalal,
    businessDetail = businessDetail,
    debt = debt,
    marketCapitalization = market_capitalization,
    totalAsset = total_asset,
    currentAsset = current_asset,
    nonCurrentAsset = non_current_asset,
    fixedAsset = fixed_asset,
    intangibleAssets = intangible_assets,
    inventory = inventory,
    totalLiabilities = total_liabilities,
    currentLiabilities = current_liabilities,
    nonCurrentLiabilities = non_current_liabilities,
    totalRevenue = total_revenue,
    totalInterestIncome = interest_income,
    annualReportUrl = annual_report_url,
    watchListIndex = watchListIndex.toInt(),
    isHalal = isHalal,
    outstandingNoOfShare = outstanding_no_share.toInt(),
    isActive = if (isActive) 1 else 0,
    isIPO = isIPO,
    ipoLogo = ipoLogo,
    ipoTimetableJson = ipoTimetableJson
)

@Serializable
@Parcelize
data class StockDataVo2022(
    @SerialName("name") val name: String,
    @SerialName("nseCode") val nseCode: String,
    @SerialName("bseCode") val bseCode: String,
    @SerialName("industry") val industry: String,
    @SerialName("isCoreActivityHalal") val isCoreActivityHalal: CoreActivityEnum,
    @SerialName("businessDetail") val businessDetail: String?,
    @SerialName("debt") val debt: Double,
    @SerialName("marketCapitalization") val marketCapitalization: Double,
    @SerialName("totalAsset") val totalAsset: Double,
    @SerialName("currentAsset") val currentAsset: Double,
    @SerialName("nonCurrentAsset") val nonCurrentAsset: Double,
    @SerialName("fixedAsset") val fixedAsset: Double,
    @SerialName("intangibleAssets") val intangibleAssets: Double,//annualGoodwillAndOtherIntangibleAssets
    @SerialName("inventory") val inventory: Double,
    @SerialName("totalLiabilities") val totalLiabilities: Double,
    @SerialName("currentLiabilities") val currentLiabilities: Double,
    @SerialName("nonCurrentLiabilities") val nonCurrentLiabilities: Double,
    @SerialName("totalRevenue") val totalRevenue: Double, //Remember to add Interest income to total revenue at the time of calculation
    @SerialName("totalInterestIncome") val totalInterestIncome: Double,
    @SerialName("annualReportUrl") val annualReportUrl: String,
    @SerialName("watchListPKeyArray") var watchListPKeyArray: HashSet<String> = HashSet(),
    @SerialName("isHalal") var isHalal: Boolean = true,
    @SerialName("outstandingNoOfShare") var outstandingNoOfShare: Long = 0,
    @SerialName("isActive") var isActive: Boolean = true, //inactive if =<0
    @SerialName("isIPO") var isIPO: Boolean = false,
    @SerialName("ipoLogo") var ipoLogo: String? = null,
    @SerialName("ipoTimetableJson") var ipoTimetableJson: String? = null,
    @SerialName("assetProfileJSON") var assetProfileJSON: String? = null,
    @SerialName("dailyUpdateModel") var dailyUpdateModel: DailyUpdateModelApp? = null,
    @SerialName("isBSE") var isBSE: Boolean? = null,
    @SerialName("otherNonPermissibleIncome") var otherNonPermissibleIncome: Double? = null,
    @SerialName("nonPermissibleIncomeOperationSegment") var nonPermissibleIncomeOperationSegment: Double? = null,
    @SerialName("lastUpdatedTime") var lastUpdatedTime: Long = 0L
) : Parcelable, StockDataVo()
//StockDbVo2022(name,nseCode,bseCode,industry,isCoreActivityHalal,businessDetail,debt,marketCapitalization,
//totalAsset,currentAsset,nonCurrentAsset,fixedAsset,intangibleAssets,inventory,totalLiabilities,currentLiabilities,nonCurrentLiabilities,totalRevenue,
//totalInterestIncome,annualReportUrl,watchListIndex,isHalal,outstandingNoOfShare,isActive,isIPO,ipoLogo,ipoTimetableJson,assetProfileJSON,
//dailyUpdateModel,isBSE,otherNonPermissibleIncome)

@Parcelize
data class StockDataVo2021(
    val name: String,
    val nseCode: String,
    val bseCode: String,
    val industry: String,
    val isCoreActivityHalal: Boolean,
    val businessDetail: String?,
    val debt: Double,
    val marketCapitalization: Double,
    val totalAsset: Double,
    val currentAsset: Double,
    val nonCurrentAsset: Double,
    val fixedAsset: Double,
    val intangibleAssets: Double,//annualGoodwillAndOtherIntangibleAssets
    val inventory: Double,
    val totalLiabilities: Double,
    val currentLiabilities: Double,
    val nonCurrentLiabilities: Double,
    val totalRevenue: Double, //Remember to add Interest income to total revenue at the time of calculation
    val totalInterestIncome: Double,
    val annualReportUrl: String,
    var watchListIndex: Int = 0,
    var isHalal: Boolean = true,
    var outstandingNoOfShare: Int = 0,
    var isActive: Int = 1, //inactive if =<0
    var interestExpense: Double = -1.0,
    var netInterestIncome: Double = -1.0,
    var isIPO: Boolean = false,
    var ipoLogo: String? = null,
    var ipoTimetableJson: String? = null
) : Parcelable, StockDataVo()

fun StockDataVo.name() = when (this) {
    is StockDataVo2021 -> this.name
    is StockDataVo2022 -> this.name
}

fun StockDataVo.nseCode() = when (this) {
    is StockDataVo2021 -> this.nseCode
    is StockDataVo2022 -> this.nseCode
}

fun StockDataVo.bseCode() = when (this) {
    is StockDataVo2021 -> this.bseCode
    is StockDataVo2022 -> this.bseCode
}

fun StockDataVo.industry() = when (this) {
    is StockDataVo2021 -> this.industry
    is StockDataVo2022 -> this.industry
}

fun StockDataVo.isCoreActivityHalal() = when (this) {
    is StockDataVo2021 -> this.isCoreActivityHalal
    is StockDataVo2022 -> this.isCoreActivityHalal != CoreActivityEnum.FAILED
}

fun StockDataVo.businessDetail() = when (this) {
    is StockDataVo2021 -> this.businessDetail
    is StockDataVo2022 -> this.businessDetail
}

fun StockDataVo.debt() = when (this) {
    is StockDataVo2021 -> this.debt
    is StockDataVo2022 -> this.debt
}

fun StockDataVo.marketCapitalization() = when (this) {
    is StockDataVo2021 -> this.marketCapitalization
    is StockDataVo2022 -> this.marketCapitalization
}

fun StockDataVo.totalAsset() = when (this) {
    is StockDataVo2021 -> this.totalAsset
    is StockDataVo2022 -> this.totalAsset
}

fun StockDataVo.currentAsset() = when (this) {
    is StockDataVo2021 -> this.currentAsset
    is StockDataVo2022 -> this.currentAsset
}

fun StockDataVo.nonCurrentAsset() = when (this) {
    is StockDataVo2021 -> this.nonCurrentAsset
    is StockDataVo2022 -> this.nonCurrentAsset
}

fun StockDataVo.fixedAsset() = when (this) {
    is StockDataVo2021 -> this.fixedAsset
    is StockDataVo2022 -> this.fixedAsset
}

fun StockDataVo.intangibleAssets() = when (this) {
    is StockDataVo2021 -> this.intangibleAssets
    is StockDataVo2022 -> this.intangibleAssets
}

fun StockDataVo.inventory() = when (this) {
    is StockDataVo2021 -> this.inventory
    is StockDataVo2022 -> this.inventory
}

fun StockDataVo.totalLiabilities() = when (this) {
    is StockDataVo2021 -> this.totalLiabilities
    is StockDataVo2022 -> this.totalLiabilities
}

fun StockDataVo.currentLiabilities() = when (this) {
    is StockDataVo2021 -> this.currentLiabilities
    is StockDataVo2022 -> this.currentLiabilities
}

fun StockDataVo.nonCurrentLiabilities() = when (this) {
    is StockDataVo2021 -> this.nonCurrentLiabilities
    is StockDataVo2022 -> this.nonCurrentLiabilities
}

fun StockDataVo.totalRevenue() = when (this) {
    is StockDataVo2021 -> this.totalRevenue
    is StockDataVo2022 -> this.totalRevenue
}

fun StockDataVo.totalInterestIncome() = when (this) {
    is StockDataVo2021 -> this.totalInterestIncome
    is StockDataVo2022 -> this.totalInterestIncome
}

fun StockDataVo.otherNonPermissibleIncome() = when (this) {
    is StockDataVo2021 -> 0.0
    is StockDataVo2022 -> this.otherNonPermissibleIncome ?: 0.0
}

fun StockDataVo.nonPermissibleIncomeFromOperationSegment() = when (this) {
    is StockDataVo2021 -> 0.0
    is StockDataVo2022 -> this.nonPermissibleIncomeOperationSegment ?: 0.0
}

fun StockDataVo.annualReportUrl() = when (this) {
    is StockDataVo2021 -> this.annualReportUrl
    is StockDataVo2022 -> this.annualReportUrl
}

fun StockDataVo.isInWatchlist() = when (this) {
    is StockDataVo2021 -> this.watchListIndex!=0
    is StockDataVo2022 -> this.watchListPKeyArray.isNotEmpty()
}

fun StockDataVo.getWatchlistPKeys() = when (this) {
    is StockDataVo2021 -> HashSet<String>()
    is StockDataVo2022 -> this.watchListPKeyArray
}

fun StockDataVo.updateWatchlistKeys(keySet: HashSet<String>) = when (this) {
    is StockDataVo2021 -> this.watchListIndex = 0
    is StockDataVo2022 -> this.watchListPKeyArray = keySet
}

fun StockDataVo.isStockDataAvailable() = run { this.totalAsset()>0 && this.totalRevenue()>0 && this.totalLiabilities() > 0 }

fun StockDataVo.isHalal() = when (this) {
    is StockDataVo2021 -> this.isHalal && isCoreActivityHalal
    is StockDataVo2022 -> this.isHalal
}

fun StockDataVo.setIsHalal(isHalal: Boolean) {
    when (this) {
        is StockDataVo2021 -> this.isHalal = isHalal
        is StockDataVo2022 -> this.isHalal = isHalal
    }
}

fun StockDataVo.isBSE() = when (this) {
    is StockDataVo2021 -> null
    is StockDataVo2022 -> this.isBSE
}

fun StockDataVo.outstandingNoOfShare() = when (this) {
    is StockDataVo2021 -> this.outstandingNoOfShare
    is StockDataVo2022 -> this.outstandingNoOfShare
}

fun StockDataVo.isActive() = when (this) {
    is StockDataVo2021 -> this.isActive==1
    is StockDataVo2022 -> this.isActive
}

fun StockDataVo.isIPO() = when (this) {
    is StockDataVo2021 -> this.isIPO
    is StockDataVo2022 -> this.isIPO
}

fun StockDataVo.ipoLogo() = when (this) {
    is StockDataVo2021 -> this.ipoLogo
    is StockDataVo2022 -> this.ipoLogo
}

fun StockDataVo.lastUpdatedTime() = when (this) {
    is StockDataVo2021 -> 0L
    is StockDataVo2022 -> this.lastUpdatedTime
}

fun StockDataVo.ipoTimetableJson() = when (this) {
    is StockDataVo2021 -> this.ipoTimetableJson
    is StockDataVo2022 -> this.ipoTimetableJson
}

fun StockDataVo.companyProfile() = try {
    when (this) {

        is StockDataVo2021 -> null
        is StockDataVo2022 -> this.assetProfileJSON?.let {
            appJson.decodeFromString(CompanyAssetProfileModel.serializer(), it)
        }
    }
} catch (ex: Exception) {
    null
}

fun StockDataVo.getDailyPriceData() = when (this) {
    is StockDataVo2021 -> null
    is StockDataVo2022 -> this.dailyUpdateModel
}

fun StockDataVo.setDailyPriceData(dailyUpdateModel: DailyUpdateModelApp) = when (this) {
    is StockDataVo2021 -> null
    is StockDataVo2022 -> this.dailyUpdateModel = dailyUpdateModel
}

















