package ui

import ApplicationProps
import csstype.px
import mui.material.Typography
import mui.system.Box
import mui.system.sx
import react.FC
import react.create
import react.dom.html.ReactHTML.main
import react.router.Outlet
import react.router.Route
import react.router.Routes
import ui.pages.DownloadPage
import ui.pages.HomePage
import ui.pages.IPOListPage
import ui.pages.StockResultPage
import ui.utils.Area

private val DEFAULT_PADDING = 30.px

val Content = FC<ApplicationProps> { props ->
    Routes {
        Route {
            path = "/"
            element = Box.create {
                component = main
                sx {
                    gridArea = Area.Content
                    padding = DEFAULT_PADDING
                }

                Outlet()
            }

//            screensPages.forEachIndexed { i, (key, _, Component) ->
//                Route {
//                    index = i == 0
//                    path = key
//                    element = Component.create {
//                        isDownloaded = props.isDownloaded
//                        setIsDownloaded = props.setIsDownloaded
//                    }
//                }
//            }

            Route {
                path = "/home"
                element = HomePage.create {
                    isDownloaded = props.isDownloaded
                }
            }

            Route {
                path = "/ipo"
                element = IPOListPage.create {
                    isDownloaded = props.isDownloaded
                }
            }

            Route {
                path = "/download"
                element = DownloadPage.create {
                    isDownloaded = props.isDownloaded
                    setIsDownloaded = props.setIsDownloaded
                }
            }

            Route {
                path = "/stock/:nseCode"
                element = StockResultPage.create {
                    isDownloaded = props.isDownloaded
                }
            }

            Route {
                path = "*"
                element = Typography.create { +"404 Page Not Found" }
            }
        }
    }
}