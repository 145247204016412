package com.halal.stocks.data.api.priceupdate

import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.priceupdate.data.PriceUpdateNetworkRepo
import com.halal.stocks.data.api.priceupdate.data.RealtimeDatabaseRepo
import com.halal.stocks.data.api.priceupdate.data.RealtimePriceVo
import com.halal.stocks.data.model.mapping.isBSE
import com.halal.stocks.data.model.mapping.nseCode
import com.halal.stocks.data.model.toRealtimeVo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.api.user.UserRepo
import com.halal.stocks.utils.RConfig
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock

class RealTimePriceDataRepo(private val priceUpdateNetworkRepo: PriceUpdateNetworkRepo,
                            private val userRepo: UserRepo,
                            private val dao2022: Stock2022DatabaseRepo,
                            private val realtimePriceDao: RealtimeDatabaseRepo,
                            private val externalScope:CoroutineScope,
                            private val sharedPrefStorageRepo: SharedPrefStorageRepo,
                            private val rConfig: RConfig) {
//    private suspend fun getUserId() = if (userRepo.getLoggedInUserId() != null) userRepo.getLoggedInUserId() else { "IslamicStock" }

    private fun updateLastPriceUpdatedTime(nseCode: String){
        sharedPrefStorageRepo.save("ltp-$nseCode",Clock.System.now().toEpochMilliseconds())
    }

    private fun isTimeToUpdateFromServer(nseCode: String):Boolean {
        val lastUpdateTime = sharedPrefStorageRepo.getLong("ltp-$nseCode",0)
        val currentTime = Clock.System.now().toEpochMilliseconds()
        val maxTime = rConfig.priceUpdateDelayMinutes*60*1000 //0//
        return (currentTime - lastUpdateTime) >= maxTime
    }

    fun monitorAllRealtimePrice(nseCode:String):Flow<RealtimePriceVo?> {
        externalScope.launch(Dispatchers.Default) {
            if (isTimeToUpdateFromServer(nseCode))
                syncRealtimePriceWithServer(nseCode)
        }
        return realtimePriceDao.monitorRealtimePrice(nseCode)
    }

   private suspend fun syncRealtimePriceWithServer(nCode:String) : RealtimePriceVo? {
        var newReturnObj: RealtimePriceVo?=null
        try {
            val stockDataVo = dao2022.getStockByNseCode(nCode) ?: return null
            val symbols = if (stockDataVo.isBSE() == true) "${stockDataVo.nseCode()}.BO" else "${stockDataVo.nseCode()}.NS"

            //Update from server
            val dailyModel = priceUpdateNetworkRepo.getRealTimeStockData(symbols)
            if (dailyModel!=null){
                updateLastPriceUpdatedTime(nseCode = nCode)
                //Fresh insert into database
                newReturnObj =  dailyModel.toRealtimeVo()
                realtimePriceDao.insertRealtimePrice(newReturnObj)
            }
        }catch (ex:Exception){
            ex.printStackTrace()
        }

       return newReturnObj
    }

    suspend fun getRealtimePrice(nseCode: String,offlineOnly:Boolean=false) :  RealtimePriceVo? {
        var savedPriceObj = realtimePriceDao.getRealtimePrice(nseCode)
        if (offlineOnly && savedPriceObj!=null)
            return savedPriceObj

        if ((savedPriceObj==null || isTimeToUpdateFromServer(nseCode)))
            savedPriceObj= syncRealtimePriceWithServer(nseCode)
        return savedPriceObj
    }

    suspend fun syncAllPrice(){
        try {
            priceUpdateNetworkRepo.getAllLTP()?.map { it.toRealtimeVo() }?.let { allLTPData->
                realtimePriceDao.insertAllRealtimePrice(allLTPData)
            }
        }catch (ex:Exception){
            ex.printStackTrace()
        }
    }

//    private suspend fun insertAllRealtimePrice(realtimePriceVoList: List<RealtimePriceVo>) = realtimePriceDao.insertAllRealtimePrice(realtimePriceVoList)

    /*suspend fun getBasketRealtimePrice(nseCode:String) :RealtimePriceVo? {
        return  try {
            realtimePriceDao.getRealtimePrice(nseCode)
                ?: dao2022.getStockByNseCode(nseCode)?.let { stockDataVo ->
                    val symbols = if (stockDataVo.isBSE() == true) "${stockDataVo.nseCode()}.BO" else "${stockDataVo.nseCode()}.NS"
                    val userId = if (userRepo.getLoggedInUserId() != null) userRepo.getLoggedInUserId() else { "IslamicStock" }
                    val listOfPrice = networkRepo.getBasketIDeaPriceData(symbols, userId) //// 7D | 30D | 90D | 180D | 360D | LTP
                    if (listOfPrice.isNotEmpty()) {
                        val price7D = listOfPrice[0]
                        val price30D = listOfPrice[1]
                        val price90D = listOfPrice[2]
                        val price180D = listOfPrice[3]
                        val price360D = listOfPrice[4]
                        val priceLTP = listOfPrice[5]
                        val realObject = RealtimePriceVo(
                            nse_code = nseCode,
                            lastTradePrice = priceLTP.toDouble(),
                            todayHigh = priceLTP.toDouble(),
                            todayLow = priceLTP.toDouble(),
                            price7DaysAgo = price7D.toDouble(),
                            price1MonthAgo = price30D.toDouble(),
                            price3MonthsAgo = price90D.toDoubleOrNull()?:0.0,
                            price6MonthAgo = price180D.toDoubleOrNull()?:0.0,
                            price1YearAgo = price360D.toDoubleOrNull()?:0.0
                        )
                        realtimePriceDao.insertRealtimePrice(realObject)
                        realObject
                    } else
                        null
                }
        }catch (ex:Exception){
            null
        }
    }*/

   /* suspend fun downloadRealtimePriceData(): Boolean {
        return if (rConfig.getBasketIdeaPriceUrl.isNotEmpty()){
            val historicalDataOfStockList = networkRepo.getCSVFileWithUrl(rConfig.getBasketIdeaPriceUrl)//.saveCsvAndGetRowArray("historical_price.csv")
            updateHistoricalData(historicalDataOfStockList)
            return true
        }else
            false
    }*/

    /*private suspend fun updateHistoricalData(downloadedFile: List<List<String>>): Boolean {
        if (downloadedFile != null) {
            val listOfStocks = ArrayList<RealtimePriceVo>()
                downloadedFile.forEachIndexed { index, item ->
                    if (index != 0) {
                        try {
                            val symbol = item[0]
                            var NSECode = item[0]
                            if (symbol.startsWith("NSE:")){
                                NSECode = item[0].replace("NSE:","")
                            }else if (symbol.startsWith("BOM:")){
                                val BSECode = item[0].replace("BOM:","")
                                NSECode =  dao2022.getStockByBseCode(BSECode)?.nseCode()?:BSECode
                            }

                            val price7DaysAgo = item[2].toDouble()
                            val price1MonthAgo = item[3].toDouble()
                            val price3MonthsAgo = item[4].toDoubleOrNull()?:0.0
                            val price6MonthAgo = item[5].toDoubleOrNull()?:0.0
                            val price1YearAgo = item[6].toDoubleOrNull()?:0.0
                            val ltp = item[7].toDouble()

                            listOfStocks.add(
                                RealtimePriceVo(
                                nse_code =  NSECode,
                                lastTradePrice = ltp,
                                todayHigh = ltp,
                                todayLow = ltp,
                                price7DaysAgo = price7DaysAgo,
                                price1MonthAgo = price1MonthAgo,
                                price3MonthsAgo = price3MonthsAgo,
                                price6MonthAgo = price6MonthAgo,
                                price1YearAgo = price1YearAgo
                            )
                            )

                        } catch (ex: Exception) {
                            ex.printStackTrace()
                        }
                    }
                }
            insertAllRealtimePrice(listOfStocks)
            return true
        }
        return false
    }*/

    /*private fun isTimeToUpdateStockPrice(nseCode: String)  :Boolean {
        val ltpUpdatedTime = sharedPrefStorageRepo.getLong("LTP$nseCode",0L)
        val maxDelay = rConfig.priceUpdateDelayMinutes*60*1000
        return (Clock.System.now().toEpochMilliseconds()-ltpUpdatedTime) >= maxDelay
    }*/

//    private fun setLastUpdatedTime(nseCode: String) = sharedPrefStorageRepo.save("LTP$nseCode",Clock.System.now().toEpochMilliseconds())

    //LTP DATA
    /*suspend fun getDailyRealtimePrice(nseCode: String) : DailyUpdateModelApp? {
        val sdt: StockDataVo? =  dao2022.getStockByNseCode(nseCode)
        sdt?.let { stockDataVo->
            val dailyUpdateModel = dao2022.getDailyPriceJson(nseCode = stockDataVo.nseCode())

            if (isTimeToUpdateStockPrice(stockDataVo.nseCode()) || dailyUpdateModel==null){
                //Get Data From Yahoo
                val symbols = if (stockDataVo.isBSE()==true) "${stockDataVo.nseCode()}.BO" else "${stockDataVo.nseCode()}.NS"
                val userId = if (userRepo.getLoggedInUserId()!=null) userRepo.getLoggedInUserId() else { "IslamicStock" }
                return networkRepo.getRealTimeStockData(symbols,userId)?.let { dailyData->
                    val todayLTP = dailyData.regularMarketPrice?.roundTwoDecimal()
                    val todayHigh = dailyData.regularMarketDayHigh?.roundTwoDecimal()
                    val todayLow = dailyData.regularMarketDayLow?.roundTwoDecimal()

                    //update price for basket
                    val ifUpdateRealtime = getBasketRealtimePrice(stockDataVo.nseCode())

                    if (todayLTP!=null && todayHigh!=null && todayLow!=null){
                        //Update Last updated time
                        setLastUpdatedTime(stockDataVo.nseCode())

                        if(ifUpdateRealtime!=null){
                            realtimePriceDao.updateRealtimePrice(todayLTP,todayHigh,todayLow,stockDataVo.nseCode())
                        }else{
                            realtimePriceDao.insertRealtimePrice(
                                RealtimePriceVo(
                                    nse_code = stockDataVo.nseCode(),
                                    lastTradePrice = todayLTP,
                                    todayHigh = todayHigh,
                                    todayLow = todayLow
                                )
                            )
                        }
                    }

                    stockDataVo.setDailyPriceData(dailyData)
                    dao2022.updateDailyPriceJson(stockDataVo.nseCode(), dailyData)
                    dailyData
                }
            }else return dailyUpdateModel//stockDataVo?.getDailyPriceData()
        }?:return null
    }*/
}