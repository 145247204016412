package com.halal.stocks.data.model

import com.halal.stocks.data.api.stocks.CoreActivityEnum
import com.halal.stocks.data.model.mapping.StockDataVo
import com.halal.stocks.data.model.mapping.StockDataVo2021
import com.halal.stocks.data.model.mapping.StockDataVo2022
import kotlinx.serialization.json.Json

fun StockDataVo.getIPOTimeTable() : IpoTimetableModel?{
    return try {
        when(this) {
            is StockDataVo2021 ->{
                ipoTimetableJson?.let { Json.decodeFromString(IpoTimetableModel.serializer(), it) }
            }
            is StockDataVo2022 ->{
                ipoTimetableJson?.let { Json.decodeFromString(IpoTimetableModel.serializer(), it) }
            }
            else ->
                null
        }
    }catch (ex:Exception){
        null
    }
}
fun StockDataVo.isBusinessMuted() : Boolean {
   return when(this) {
        is StockDataVo2021 ->{
            businessDetail?.contains("{{MUTE}}")==true
        }
        is StockDataVo2022 ->{
            (businessDetail?.contains("{{MUTE}}")==true || isCoreActivityHalal == CoreActivityEnum.MUTE || isCoreActivityHalal == CoreActivityEnum.DOUBTFUL)
        }
        else ->false
    }
}

fun StockDataVo.getNonCompliantBusinessActivityDetail() : String{
    return when(this) {
        is StockDataVo2021 ->{
            ""
        }
        is StockDataVo2022 ->{
            if (businessDetail?.contains("{{")==true && businessDetail.contains("}}")){
                val sIndex = businessDetail.indexOf("{{")+2
                val eIndex = businessDetail.indexOf("}}")
                businessDetail.subSequence(sIndex,eIndex).toString()
            }else
                ""

        }
    }
}