package com.halal.stocks.data.api.user

import com.halal.stocks.data.api.ApiHttpClient
import com.halal.stocks.data.api.user.models.UserFcmUpdateModel
import com.halal.stocks.data.model.mapping.UserVo
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*

class UserNetworkRepo(private val client: HttpClient,private val authClient: ApiHttpClient) {

    suspend fun loginSuccess(userModel: UserVo,idToken:String): String {
        return client.post("${authClient.baseAPIUrl}/userLoggedIn"){
            headers["idToken"] = idToken
            contentType(ContentType.Application.Json)
            setBody(userModel)
        }.body()
    }

    suspend fun updateFCMToken(fcmModel: UserFcmUpdateModel): String {
        return authClient.post("/updateFCMToken",fcmModel)
    }
}