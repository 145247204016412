package com.halal.stocks.ui.basket

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import com.halal.stocks.data.api.basket.BasketRepo
import com.halal.stocks.data.api.priceupdate.RealTimePriceDataRepo

class BasketDetailViewModel(private val basketRepo: BasketRepo, private val realTimeDataRepo: RealTimePriceDataRepo) : ViewModel() {

//    val montiroIdeaLiveData = MutableStateFlow<List<IdeasModel>?>(emptyList())

//    fun getAllIdeasByBasketId(basketId: Int) {
//        viewModelScope.launch(Main) {
//            val listOfIdeas = withContext(Dispatchers.IO) {
//                try {
//                    basketRepo.getAllIdeasByBasketId(basketId)
//                } catch (ex: Exception) {
//                    emptyList<IdeasModel>()
//                }
//            }
//            montiroIdeaLiveData.value = listOfIdeas
//        }
//    }

    //    val monitorRealtimePrice = MutableLiveData<List<RealtimePriceVo>>()
    suspend fun getRealtimePrice(nseCode: String) = realTimeDataRepo.getRealtimePrice(nseCode)

}