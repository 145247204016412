package com.halal.stocks.utils

import com.halal.stocks.data.model.DailyUpdateModelApp
import kotlinx.datetime.Clock
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import our.libs.diskio.FileSystem

private const val SECOND = 1
private const val MINUTE = 60 * SECOND
private const val HOUR = 60 * MINUTE
private const val DAY = 24 * HOUR
private const val MONTH = 30 * DAY
private const val YEAR = 12 * MONTH
fun Long.getRelativeTimeSpanString() : String{
    val time = this
    val now = Clock.System.now().toEpochMilliseconds()
    val diff = (now - time) / 1000

    return when {
        this==0L -> "While ago"
        diff < MINUTE -> "Just now"
        diff < 2 * MINUTE -> "a minute ago"
        diff < 60 * MINUTE -> "${diff / MINUTE} minutes ago"
        diff < 2 * HOUR -> "an hour ago"
        diff < 24 * HOUR -> "${diff / HOUR} hours ago"
        diff < 2 * DAY -> "yesterday"
        diff < 30 * DAY -> "${diff / DAY} days ago"
        diff < 2 * MONTH -> "a month ago"
        diff < 12 * MONTH -> "${diff / MONTH} months ago"
        diff < 2 * YEAR -> "a year ago"
        else -> "${diff / YEAR} years ago"
    }
}



val appJson by lazy { Json {
    isLenient = true
    ignoreUnknownKeys = true
} }

inline fun <reified T> T.jsonify() = appJson.encodeToString(this)
inline fun <reified T> String.objectify() : T = appJson.decodeFromString(this)


fun DailyUpdateModelApp.toJson() = appJson.encodeToString(this)

fun String.toDailyUpdateModelApp(): DailyUpdateModelApp?{
    return  if (this.isNotEmpty())
        try {
            appJson.decodeFromString<DailyUpdateModelApp>(this)
        }catch (ex:Exception){
            null
        }
    else
        null
}

fun ByteArray.saveFileGetPath(saveName:String) : String{
    val pathOfCsv = "${FileSystem.cachesDirectory.absolutePath?.component}/$saveName"
    val csvFileWritten = FileSystem.writeFile(pathOfCsv,this, create = true)
    return pathOfCsv
}


fun String?.toIntOrZero() : Int{
    return if (this==null || this.isEmpty()){ 0  } else this.toInt()
}
fun String?.toLongOrZero() : Long{
    return if (this==null || this.isEmpty()){ 0L  } else this.toLong()
}
fun String?.toFloatOrZero() : Float{
    return if (this==null || this.isEmpty()){ 0F } else this.toFloat()
}

fun Double?.toZeroIfNull() : Double{
    return this?:0.0
}

/*
fun <T> String.objectify(type: T): T? {
    if (isEmpty()) {
        return null
    }
    try {
        return Json.decodeFromString<T>(,this)
    } catch (ignored: Exception) {
    }

    return null
}

internal fun Any.jsonify(): String? {
    try {
        return Gson().toJson(this)
    } catch (ignored: Exception) {
    }
    return null
}*/
