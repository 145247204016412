package com.halal.stocks.data.api.priceupdate.data

import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.api.priceupdate.data.RealtimePriceVo
import com.halal.stocks.data.api.priceupdate.data.toRealtimePriceTable
import com.halal.stocks.data.api.priceupdate.data.toRealtimePriceVo
import com.squareup.sqldelight.runtime.coroutines.asFlow
import kotlinx.coroutines.flow.map

class RealtimeDatabaseRepo(private val database: DatabaseFactory) {

    fun getRealtimePrice(nseCode:String): RealtimePriceVo? {
        val result = try {
            database.appDatabase.getRealtimeDataDao.getRealtimePrice(nseCode).executeAsOneOrNull()?.toRealtimePriceVo()
        }catch (ex:Exception){
            database.appDatabase.getRealtimeDataDao.deleteFromRealtimePrice(nseCode)
            null
        }
        return result
    }

    fun monitorRealtimePrice(nseCode:String) = database.appDatabase.getRealtimeDataDao.monitorAllRealtimePrice(nseCode).asFlow().map { it.executeAsOneOrNull()?.toRealtimePriceVo() }

   /* fun updateRealtimePrice( lastTradePrice: Double,
                             todayHigh: Double,
                             todayLow: Double,
                             nse_code: String){
        database.appDatabase.getRealtimeDataDao.updateRealtimePrice(
            lastTradePrice,todayHigh,todayLow,nse_code
        )
    }*/

    fun insertAllRealtimePrice(realtimePriceVoList: List<RealtimePriceVo>) {
        database.appDatabase.getRealtimeDataDao.transaction {
            realtimePriceVoList.forEach {
                insertRealtimePrice(it)
            }
        }
    }

    fun insertRealtimePrice(realtimePriceVo: RealtimePriceVo) {
        database.appDatabase.getRealtimeDataDao.insertRealtimePrice(realtimePriceVo.toRealtimePriceTable())
    }

}