package com.halal.stocks.ui

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import com.halal.stocks.data.api.stocks.Stock2021DatabaseRepo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.model.FinancialResultType
import com.halal.stocks.data.model.mapping.StockDataVo2022
import com.halal.stocks.data.model.mapping.setIsHalal
import com.halal.stocks.utils.HalalScreener
import com.halal.stocks.utils.RConfig

class SettingsViewModel(private val stock2022DatabaseRepo: Stock2022DatabaseRepo,
                        private val stockDao2021: Stock2021DatabaseRepo,
                        private val rConfig: RConfig,
                        val halalScreener: HalalScreener) : ViewModel() {

    suspend fun adjustTaqwaSettings(){
        val stockList = stock2022DatabaseRepo.getAllStockList()
        stockList.forEach {
            it.setIsHalal(halalScreener.isStockHalal(it))
        }

        stock2022DatabaseRepo.insertOrReplaceStockAll(stockList as List<StockDataVo2022>)

    }
}