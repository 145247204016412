package com.halal.stocks.data.room.watchlist.usecases

import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.api.ApiHttpClient
import com.halal.stocks.data.api.IUseCase
import com.halal.stocks.data.room.watchlist.usecases.vo.WatchlistDataVo
import com.halal.stocks.data.room.watchlist.usecases.vo.WatchlistNameVo
import com.halal.stocks.utils.jsonify
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
internal data class WatchlistServerSyncVo(@SerialName("nameTable") val nameTable: WatchlistNameVo,@SerialName("listOfData") val listOfData: List<WatchlistDataVo>)

class UpdateWatchlistDataToServerUseCase(
    private val apiHttpClient: ApiHttpClient,
    private val database: DatabaseFactory,
) : IUseCase {

    override suspend fun execute(loggedInUserId: String): Boolean {
        return try {
            val data = database.appDatabase.getWatchlistNameDao.selectAll().executeAsList().map {
                val list = database.appDatabase.getWatchlistDataDao.selectAll(it.pKey).executeAsList().map { WatchlistDataVo(it.watchlistPKey,it.nseCode) }
                WatchlistServerSyncVo(
                    nameTable = WatchlistNameVo(pKey = it.pKey, watchlistName = it.watchlistName),
                    listOfData = list
                )
            }
            apiHttpClient.post<String>("/updateWatchlistJson",data.jsonify())
            true
        } catch (ex: Exception) {
            ex.printStackTrace()
            false
        }
    }

}