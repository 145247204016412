package com.halal.stocks

import kotlin.Long
import kotlin.String

public data class PortfolioDataTable(
  public val portfolioPKey: Long,
  public val portfolioName: String
) {
  public override fun toString(): String = """
  |PortfolioDataTable [
  |  portfolioPKey: $portfolioPKey
  |  portfolioName: $portfolioName
  |]
  """.trimMargin()
}
