package com.halal.stocks.ui.watchlist

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import com.halal.stocks.WatchlistNameTable
import com.halal.stocks.data.room.watchlist.WatchListRepo
import com.halal.stocks.ui.asCommonFlow
import kotlinx.coroutines.flow.MutableStateFlow

open class WatchlistViewModel(private val watchListRepo: WatchListRepo) : ViewModel() {

    var selectedWatchlistLiveData : MutableStateFlow<WatchlistNameTable> = MutableStateFlow(WatchlistNameTable(0L,""))

    fun getWatchlistData(watchlistNameTable: WatchlistNameTable) = watchListRepo.getWatchlistData(watchlistNameTable).asCommonFlow()

    fun getAllWatchlistNamesFlow() = watchListRepo.getWatchlistNamesFlow().asCommonFlow()

    fun getAllItemSizeInWatchlist(item: WatchlistNameTable) = watchListRepo.getAllItemSizeInWatchlist(item)

    fun addToWatchlist(nseCode:String,selectedWatchlistNameTable: WatchlistNameTable)  = watchListRepo.addToWatchList(nseCode,selectedWatchlistNameTable)

    fun remoteFromWatchlist(nseCode:String,selectedWatchlistNameTable: WatchlistNameTable) = watchListRepo.remoteFromWatchlist(nseCode,selectedWatchlistNameTable)

    fun create(watchlistName:String) = watchListRepo.createWatchlist(watchlistName)
    fun updateName(watchlistName:String, selectedWatchlistNameTable: WatchlistNameTable) = watchListRepo.updateWatchlistName(selectedWatchlistNameTable,watchlistName)

    fun deleteWatchlist(value: WatchlistNameTable) {
        watchListRepo.deleteWatchlist(value)
    }
}