package com.halal.stocks.data.api.user.usecases

import com.halal.stocks.data.api.IUseCase
import com.halal.stocks.data.api.screener.ScreenerRepo
import com.halal.stocks.data.api.screener.screener.ScreenerSyncAfterLoginUseCase
import com.halal.stocks.data.room.calltracking.usecases.GetTrackingDataFromServerUseCase
import com.halal.stocks.data.room.portfolio.usecases.GetPortfolioDataFromServerUseCase
import com.halal.stocks.data.room.watchlist.usecases.GetWatchlistDataFromServerUseCase
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope

class SyncUserDataFromServerAfterLoginUsecase(
    private val screenerSyncAfterLoginUseCase: ScreenerSyncAfterLoginUseCase,
    private val getCallTrackingDataFromServerUseCase: GetTrackingDataFromServerUseCase,
    private val portfolioDataFromServerUseCase: GetPortfolioDataFromServerUseCase,
    private val getWatchlistDataFromServerUseCase: GetWatchlistDataFromServerUseCase) : IUseCase {

    override suspend fun execute(loggedInUserId: String): Boolean {
        return coroutineScope {
            val w = async { getWatchlistDataFromServerUseCase.execute(loggedInUserId) }
            val p = async { portfolioDataFromServerUseCase.execute(loggedInUserId) }
            val t = async { getCallTrackingDataFromServerUseCase.execute(loggedInUserId) }
            val s = async { screenerSyncAfterLoginUseCase.execute(loggedInUserId) }
             w.await() || p.await() || t.await() || s.await()
        }
    }

}