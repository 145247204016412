package theme

import csstype.Color

class Colors {
    companion object {
        val compliantColor = Color("#00ab5e")
        val nonCompliantColor = Color("#eb0f29")
        val white = Color("#FFFFFF")
        val offWhiteDeep = Color("#ACA9A9")
        val textLabelColor = Color("#666")
        val resultItemBackground = Color("#1d2228")
    }
}
