package com.halal.stocks.ui.signin

import com.halal.stocks.data.api.user.UserRepo
import dev.icerock.moko.mvvm.viewmodel.ViewModel

// Copyright © 2022 Compass. All rights reserved.
class SigninViewModel(
    private val userRepo: UserRepo
): ViewModel() {

    fun getUserAsFlow() = userRepo.getUserAsFlow()
    fun getCurrentActiveUser() = userRepo.currentActiveUser

    /*fun loginSuccess(displayName: String?, email: String, photoURL: String?) {

        Logger.i { displayName.toString() }
        Logger.i { email }
        Logger.i { photoURL.toString() }

        viewModelScope.launch(Main) {
            val userVo = UserVo("0", displayName, email, photoURL,
                Clock.System.now().toEpochMilliseconds(),"user","Android|Google")
            userRepo.loginSuccess(userVo)
        }
    }*/
}