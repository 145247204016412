package com.halal.stocks

import com.halal.stocks.data.AppDatabase
import com.halal.stocks.data.DatabaseFactory
import com.squareup.sqldelight.db.SqlDriver
import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.cache.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json
import org.koin.dsl.module

actual class Platform actual constructor() {
    actual val platform: String
        get() = "JS"
}

actual fun platformModule() = module {}

actual fun platformModuleJs(driver: SqlDriver) = module {

    single {
        DatabaseFactory(AppDatabase(IslamicStockVN2(driver)))
    }

    single {
        JsClient()
    }

    single {
        HttpClient(Js){
            install(ContentNegotiation) {
                json(Json {
                    ignoreUnknownKeys = true
                    explicitNulls = false
                    encodeDefaults = true
                    isLenient = true
                    allowSpecialFloatingPointValues = true
                    allowStructuredMapKeys = true
                    prettyPrint = false
                    useArrayPolymorphism = false
                })
            }

            install(HttpCache)
            install(io.ktor.client.plugins.compression.ContentEncoding) {
                deflate()
                gzip()
            }
            /*if (enableNetworkLogs) {
                install(Logging) {
                    logger = Logger.DEFAULT
                    level = LogLevel.ALL
                }
            }*/
        }
    }
}
