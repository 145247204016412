package com.halal.stocks.shared

import com.halal.stocks.BasketDataRoomQueries
import com.halal.stocks.BasketTable
import com.halal.stocks.GetDailyPriceJson
import com.halal.stocks.GetLoggedInUserId
import com.halal.stocks.GetStockCounter
import com.halal.stocks.GetWatchlistPKeysWatchlistByNseCode
import com.halal.stocks.IslamicStockVN2
import com.halal.stocks.NotificationRoomQueries
import com.halal.stocks.NotificationsTable
import com.halal.stocks.PortfolioDataRoomQueries
import com.halal.stocks.PortfolioDataTable
import com.halal.stocks.PortfolioHoldingDataRoom
import com.halal.stocks.PortfolioHoldingDataRoomQueries
import com.halal.stocks.RealtimeDataRoomQueries
import com.halal.stocks.RealtimePriceTable
import com.halal.stocks.ScreenerDataRoomQueries
import com.halal.stocks.ScreenerDataTable
import com.halal.stocks.Stock2021DataRoomQueries
import com.halal.stocks.Stock2022DataRoomQueries
import com.halal.stocks.StockDbVo2021Table
import com.halal.stocks.StockDbVo2022Table
import com.halal.stocks.SubscriptionStatusDatabaseQueries
import com.halal.stocks.SubscriptionStatusTable
import com.halal.stocks.TrackingDataRoomQueries
import com.halal.stocks.TrackingDataTable
import com.halal.stocks.TrackingNameRoomQueries
import com.halal.stocks.TrackingNameTable
import com.halal.stocks.UserDataRoomQueries
import com.halal.stocks.UserTable
import com.halal.stocks.WatchlisDataRoomQueries
import com.halal.stocks.WatchlisNameRoomQueries
import com.halal.stocks.WatchlistDataTable
import com.halal.stocks.WatchlistNameTable
import com.halal.stocks.basketDataRoom.SelectMaxLastUpdatedTime
import com.squareup.sqldelight.Query
import com.squareup.sqldelight.TransacterImpl
import com.squareup.sqldelight.`internal`.copyOnWriteList
import com.squareup.sqldelight.db.SqlCursor
import com.squareup.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Double
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.MutableList
import kotlin.reflect.KClass

internal val KClass<IslamicStockVN2>.schema: SqlDriver.Schema
  get() = IslamicStockVN2Impl.Schema

internal fun KClass<IslamicStockVN2>.newInstance(driver: SqlDriver): IslamicStockVN2 =
    IslamicStockVN2Impl(driver)

private class IslamicStockVN2Impl(
  driver: SqlDriver
) : TransacterImpl(driver), IslamicStockVN2 {
  public override val basketDataRoomQueries: BasketDataRoomQueriesImpl =
      BasketDataRoomQueriesImpl(this, driver)

  public override val notificationRoomQueries: NotificationRoomQueriesImpl =
      NotificationRoomQueriesImpl(this, driver)

  public override val portfolioDataRoomQueries: PortfolioDataRoomQueriesImpl =
      PortfolioDataRoomQueriesImpl(this, driver)

  public override val portfolioHoldingDataRoomQueries: PortfolioHoldingDataRoomQueriesImpl =
      PortfolioHoldingDataRoomQueriesImpl(this, driver)

  public override val realtimeDataRoomQueries: RealtimeDataRoomQueriesImpl =
      RealtimeDataRoomQueriesImpl(this, driver)

  public override val screenerDataRoomQueries: ScreenerDataRoomQueriesImpl =
      ScreenerDataRoomQueriesImpl(this, driver)

  public override val stock2021DataRoomQueries: Stock2021DataRoomQueriesImpl =
      Stock2021DataRoomQueriesImpl(this, driver)

  public override val stock2022DataRoomQueries: Stock2022DataRoomQueriesImpl =
      Stock2022DataRoomQueriesImpl(this, driver)

  public override val subscriptionStatusDatabaseQueries: SubscriptionStatusDatabaseQueriesImpl =
      SubscriptionStatusDatabaseQueriesImpl(this, driver)

  public override val trackingDataRoomQueries: TrackingDataRoomQueriesImpl =
      TrackingDataRoomQueriesImpl(this, driver)

  public override val trackingNameRoomQueries: TrackingNameRoomQueriesImpl =
      TrackingNameRoomQueriesImpl(this, driver)

  public override val userDataRoomQueries: UserDataRoomQueriesImpl = UserDataRoomQueriesImpl(this,
      driver)

  public override val watchlisDataRoomQueries: WatchlisDataRoomQueriesImpl =
      WatchlisDataRoomQueriesImpl(this, driver)

  public override val watchlisNameRoomQueries: WatchlisNameRoomQueriesImpl =
      WatchlisNameRoomQueriesImpl(this, driver)

  public object Schema : SqlDriver.Schema {
    public override val version: Int
      get() = 1

    public override fun create(driver: SqlDriver): Unit {
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS BasketTable(
          |title TEXT NOT NULL,
          |ownerName TEXT NOT NULL,
          |telegramGroup TEXT NOT NULL,
          |basketId INTEGER NOT NULL,
          |followers INTEGER NOT NULL,
          |createdDatetime INTEGER NOT NULL,
          |totalStocks INTEGER NOT NULL,
          |isFollow INTEGER DEFAULT 0 NOT NULL,
          |listOfIdeas TEXT,
          |lastUpdatedTime INTEGER DEFAULT NULL,
          |PRIMARY KEY(basketId))
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS NotificationsTable(
          |    nId INTEGER NOT NULL,
          |    notificationDate INTEGER NOT NULL,
          |    notificationTitle TEXT NOT NULL,
          |    notificationText TEXT NOT NULL,
          |    imageUrl TEXT DEFAULT NULL,
          |    notificationType TEXT NOT NULL,
          |    deeplinkData TEXT DEFAULT NULL, PRIMARY KEY(nId))
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE PortfolioHoldingDataRoom (
          |portfolioPKey INTEGER NOT NULL,-- name of portfolio | holding | tracking etc.
          |nseCode TEXT NOT NULL,
          |qty INTEGER DEFAULT 0 NOT NULL,
          |avgBuyPrice REAL DEFAULT 0 NOT NULL,
          |transactionDate INTEGER DEFAULT 0 NOT NULL,
          |PRIMARY KEY(portfolioPKey,nseCode)
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS SubscriptionStatusTable(
          |primaryKey INTEGER PRIMARY KEY AUTOINCREMENT,
          |subscriptionStatusJson TEXT,
          |subAlreadyOwned INTEGER DEFAULT 0 NOT NULL,
          |isLocalPurchase INTEGER DEFAULT 0 NOT NULL,
          |product TEXT,
          |purchaseToken TEXT,
          |isEntitlementActive INTEGER DEFAULT 0 NOT NULL,
          |willRenew INTEGER DEFAULT 0 NOT NULL,
          |activeUntilMillisec INTEGER NOT NULL,
          |isGracePeriod INTEGER DEFAULT 0 NOT NULL,
          |isAccountHold INTEGER DEFAULT 0 NOT NULL,
          |isPaused INTEGER DEFAULT 0 NOT NULL,
          |isAcknowledged INTEGER DEFAULT 0 NOT NULL,
          |autoResumeTimeMillis INTEGER DEFAULT 0 NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE WatchlistDataTable (
          |watchlistPKey INTEGER NOT NULL,
          |nseCode TEXT NOT NULL,
          |PRIMARY KEY(watchlistPKey,nseCode)
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS StockDbVo2021Table(name TEXT NOT NULL, nse_code TEXT NOT NULL, bse_code TEXT NOT NULL, industry TEXT NOT NULL,
          |isCoreActivityHalal INTEGER DEFAULT 0 NOT NULL, businessDetail TEXT, debt REAL NOT NULL, market_capitalization REAL NOT NULL,
          |total_asset REAL NOT NULL, current_asset REAL NOT NULL, non_current_asset REAL NOT NULL, fixed_asset REAL NOT NULL, intangible_assets REAL NOT NULL,
          | inventory REAL NOT NULL, total_liabilities REAL NOT NULL, current_liabilities REAL NOT NULL, non_current_liabilities REAL NOT NULL,
          |  total_revenue REAL NOT NULL, interest_income REAL NOT NULL, annual_report_url TEXT NOT NULL, watchListIndex INTEGER NOT NULL,
          |  isHalal INTEGER DEFAULT 0 NOT NULL,
          |   outstanding_no_share INTEGER NOT NULL, isActive INTEGER DEFAULT 0 NOT NULL, interest_expense REAL NOT NULL,
          |   net_interest_income REAL NOT NULL, isIPO INTEGER DEFAULT 0 NOT NULL, ipoLogo TEXT, ipoTimetableJson TEXT, PRIMARY KEY(bse_code, nse_code))
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE TrackingNameTable (
          |trackingNamePKey INTEGER PRIMARY KEY AUTOINCREMENT,
          |name TEXT NOT NULL UNIQUE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS RealtimePriceTable(
          |  nse_code TEXT NOT NULL,
          |  lastTradePrice REAL NOT NULL,
          |  todayHigh REAL NOT NULL,
          |  todayLow REAL NOT NULL,
          |  todayPercentChange REAL NOT NULL,
          |  todayPriceChange REAL NOT NULL,
          |  previousClose REAL NOT NULL,
          |  marketLtpTime INTEGER NOT NULL,
          |  price7DaysAgo REAL NOT NULL,
          |  price1MonthAgo REAL NOT NULL,
          |  price3MonthsAgo REAL NOT NULL,
          |  price6MonthAgo REAL NOT NULL,
          |  price1YearAgo REAL NOT NULL, PRIMARY KEY(nse_code))
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS StockDbVo2022Table(name TEXT NOT NULL, nse_code TEXT NOT NULL, bse_code TEXT NOT NULL,
          |industry TEXT NOT NULL, isCoreActivityHalal INTEGER DEFAULT 0 NOT NULL,
          | businessDetail TEXT, debt REAL NOT NULL, market_capitalization REAL NOT NULL, total_asset REAL NOT NULL,
          | current_asset REAL NOT NULL, non_current_asset REAL NOT NULL, fixed_asset REAL NOT NULL, intangible_assets REAL NOT NULL,
          | inventory REAL NOT NULL, total_liabilities REAL NOT NULL, current_liabilities REAL NOT NULL, non_current_liabilities REAL NOT NULL,
          | total_revenue REAL NOT NULL, interest_income REAL NOT NULL, annual_report_url TEXT NOT NULL,
          | watchListPKeys TEXT DEFAULT NULL,
          | isHalal INTEGER DEFAULT 0 NOT NULL,
          | outstanding_no_share INTEGER NOT NULL,
          |  isActive INTEGER DEFAULT 0 NOT NULL,
          |  isIPO INTEGER DEFAULT 0 NOT NULL,
          |   ipoLogo TEXT,
          |    ipoTimetableJson TEXT,
          |     assetProfileJSON TEXT,
          |     dailyPriceJson TEXT,
          |  isBSE INTEGER DEFAULT 0 NOT NULL,
          |   otherNonPermissibleIncome REAL DEFAULT NULL,
          |   nonPermissibleIncomeOperationSegment REAL DEFAULT NULL,
          |   lastUpdatedTime INTEGER NOT NULL,
          |   PRIMARY KEY(bse_code, nse_code))
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE TrackingDataTable (
          |trackingNamePKey INTEGER NOT NULL,-- name of portfolio | holding | tracking etc.
          |nseCode TEXT NOT NULL,
          |callDate INTEGER DEFAULT 0 NOT NULL,
          |callPrice REAL DEFAULT 0 NOT NULL,
          |targetPrice REAL DEFAULT 0 NOT NULL,
          |stopLosePrice REAL DEFAULT 0 NOT NULL,
          |highestSinceCall REAL DEFAULT 0 NOT NULL,
          |highestOnDate INTEGER DEFAULT 0 NOT NULL,
          |lowestSinceCall REAL DEFAULT 0 NOT NULL,
          |lowestOnDate INTEGER DEFAULT 0 NOT NULL,
          |noteOnCall TEXT DEFAULT NULL,
          |PRIMARY KEY(trackingNamePKey,nseCode)
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS ScreenerDataTable(
          |  scan_name TEXT NOT NULL,
          |  scan_url TEXT NOT NULL,
          |  stocks TEXT NOT NULL,
          |  trigger_prices TEXT NOT NULL,
          |  triggered_at TEXT NOT NULL,
          |  screenerDetail TEXT DEFAULT NULL,
          |  isPrivate INTEGER DEFAULT 0 NOT NULL,
          |  isPremium INTEGER DEFAULT 1 NOT NULL,
          |  lastUpdatedTime INTEGER DEFAULT NULL,
          |  PRIMARY KEY(scan_url))
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE WatchlistNameTable (
          |pKey INTEGER PRIMARY KEY AUTOINCREMENT,
          |watchlistName TEXT NOT NULL UNIQUE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE PortfolioDataTable (
          |portfolioPKey INTEGER PRIMARY KEY AUTOINCREMENT,
          |portfolioName TEXT NOT NULL UNIQUE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS UserTable(
          |id TEXT, username TEXT,
          |email TEXT NOT NULL,
          |profilePic TEXT,
          |createDateTime INTEGER NOT NULL,
          |role TEXT NOT NULL,
          |loginType TEXT NOT NULL, PRIMARY KEY(email))
          """.trimMargin(), 0)
    }

    public override fun migrate(
      driver: SqlDriver,
      oldVersion: Int,
      newVersion: Int
    ): Unit {
    }
  }
}

private class BasketDataRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), BasketDataRoomQueries {
  internal val getAllBasketsFromDB: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllBasketsById: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllBasketsOfUser: MutableList<Query<*>> = copyOnWriteList()

  internal val selectMaxLastUpdatedTime: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getAllBasketsFromDB(mapper: (
    title: String,
    ownerName: String,
    telegramGroup: String,
    basketId: Long,
    followers: Long,
    createdDatetime: Long,
    totalStocks: Long,
    isFollow: Boolean,
    listOfIdeas: String?,
    lastUpdatedTime: Long?
  ) -> T): Query<T> = Query(-781375695, getAllBasketsFromDB, driver, "BasketDataRoom.sq",
      "getAllBasketsFromDB", "SELECT * FROM BasketTable") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!,
      cursor.getLong(4)!!,
      cursor.getLong(5)!!,
      cursor.getLong(6)!!,
      cursor.getLong(7)!! == 1L,
      cursor.getString(8),
      cursor.getLong(9)
    )
  }

  public override fun getAllBasketsFromDB(): Query<BasketTable> = getAllBasketsFromDB { title,
      ownerName, telegramGroup, basketId, followers, createdDatetime, totalStocks, isFollow,
      listOfIdeas, lastUpdatedTime ->
    BasketTable(
      title,
      ownerName,
      telegramGroup,
      basketId,
      followers,
      createdDatetime,
      totalStocks,
      isFollow,
      listOfIdeas,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> getAllBasketsById(basketId: Long, mapper: (
    title: String,
    ownerName: String,
    telegramGroup: String,
    basketId: Long,
    followers: Long,
    createdDatetime: Long,
    totalStocks: Long,
    isFollow: Boolean,
    listOfIdeas: String?,
    lastUpdatedTime: Long?
  ) -> T): Query<T> = GetAllBasketsByIdQuery(basketId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!,
      cursor.getLong(4)!!,
      cursor.getLong(5)!!,
      cursor.getLong(6)!!,
      cursor.getLong(7)!! == 1L,
      cursor.getString(8),
      cursor.getLong(9)
    )
  }

  public override fun getAllBasketsById(basketId: Long): Query<BasketTable> =
      getAllBasketsById(basketId) { title, ownerName, telegramGroup, basketId_, followers,
      createdDatetime, totalStocks, isFollow, listOfIdeas, lastUpdatedTime ->
    BasketTable(
      title,
      ownerName,
      telegramGroup,
      basketId_,
      followers,
      createdDatetime,
      totalStocks,
      isFollow,
      listOfIdeas,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> getAllBasketsOfUser(mapper: (
    title: String,
    ownerName: String,
    telegramGroup: String,
    basketId: Long,
    followers: Long,
    createdDatetime: Long,
    totalStocks: Long,
    isFollow: Boolean,
    listOfIdeas: String?,
    lastUpdatedTime: Long?
  ) -> T): Query<T> = Query(-535563317, getAllBasketsOfUser, driver, "BasketDataRoom.sq",
      "getAllBasketsOfUser", "SELECT * FROM BasketTable WHERE isFollow=1") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!,
      cursor.getLong(4)!!,
      cursor.getLong(5)!!,
      cursor.getLong(6)!!,
      cursor.getLong(7)!! == 1L,
      cursor.getString(8),
      cursor.getLong(9)
    )
  }

  public override fun getAllBasketsOfUser(): Query<BasketTable> = getAllBasketsOfUser { title,
      ownerName, telegramGroup, basketId, followers, createdDatetime, totalStocks, isFollow,
      listOfIdeas, lastUpdatedTime ->
    BasketTable(
      title,
      ownerName,
      telegramGroup,
      basketId,
      followers,
      createdDatetime,
      totalStocks,
      isFollow,
      listOfIdeas,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> selectMaxLastUpdatedTime(mapper: (MAX: Long?) -> T): Query<T> =
      Query(-545217085, selectMaxLastUpdatedTime, driver, "BasketDataRoom.sq",
      "selectMaxLastUpdatedTime", "SELECT MAX(lastUpdatedTime) FROM BasketTable") { cursor ->
    mapper(
      cursor.getLong(0)
    )
  }

  public override fun selectMaxLastUpdatedTime(): Query<SelectMaxLastUpdatedTime> =
      selectMaxLastUpdatedTime { MAX ->
    SelectMaxLastUpdatedTime(
      MAX
    )
  }

  public override fun insertAllBasketsData(BasketTable: BasketTable): Unit {
    driver.execute(676691944,
        """INSERT OR REPLACE INTO BasketTable VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?)""", 10) {
      bindString(1, BasketTable.title)
      bindString(2, BasketTable.ownerName)
      bindString(3, BasketTable.telegramGroup)
      bindLong(4, BasketTable.basketId)
      bindLong(5, BasketTable.followers)
      bindLong(6, BasketTable.createdDatetime)
      bindLong(7, BasketTable.totalStocks)
      bindLong(8, if (BasketTable.isFollow) 1L else 0L)
      bindString(9, BasketTable.listOfIdeas)
      bindLong(10, BasketTable.lastUpdatedTime)
    }
    notifyQueries(676691944, {database.basketDataRoomQueries.getAllBasketsById +
        database.basketDataRoomQueries.getAllBasketsFromDB +
        database.basketDataRoomQueries.selectMaxLastUpdatedTime +
        database.basketDataRoomQueries.getAllBasketsOfUser})
  }

  private inner class GetAllBasketsByIdQuery<out T : Any>(
    public val basketId: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getAllBasketsById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1015450725,
        """SELECT * FROM BasketTable WHERE basketId=?""", 1) {
      bindLong(1, basketId)
    }

    public override fun toString(): String = "BasketDataRoom.sq:getAllBasketsById"
  }
}

private class NotificationRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), NotificationRoomQueries {
  internal val getAllNotificationAsFlow: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllNotificationByType: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getAllNotificationAsFlow(mapper: (
    nId: Long,
    notificationDate: Long,
    notificationTitle: String,
    notificationText: String,
    imageUrl: String?,
    notificationType: String,
    deeplinkData: String?
  ) -> T): Query<T> = Query(-2117204630, getAllNotificationAsFlow, driver, "NotificationRoom.sq",
      "getAllNotificationAsFlow",
      "SELECT * FROM NotificationsTable ORDER BY notificationDate DESC") { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5)!!,
      cursor.getString(6)
    )
  }

  public override fun getAllNotificationAsFlow(): Query<NotificationsTable> =
      getAllNotificationAsFlow { nId, notificationDate, notificationTitle, notificationText,
      imageUrl, notificationType, deeplinkData ->
    NotificationsTable(
      nId,
      notificationDate,
      notificationTitle,
      notificationText,
      imageUrl,
      notificationType,
      deeplinkData
    )
  }

  public override fun <T : Any> getAllNotificationByType(notificationType: String, mapper: (
    nId: Long,
    notificationDate: Long,
    notificationTitle: String,
    notificationText: String,
    imageUrl: String?,
    notificationType: String,
    deeplinkData: String?
  ) -> T): Query<T> = GetAllNotificationByTypeQuery(notificationType) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5)!!,
      cursor.getString(6)
    )
  }

  public override fun getAllNotificationByType(notificationType: String): Query<NotificationsTable>
      = getAllNotificationByType(notificationType) { nId, notificationDate, notificationTitle,
      notificationText, imageUrl, notificationType_, deeplinkData ->
    NotificationsTable(
      nId,
      notificationDate,
      notificationTitle,
      notificationText,
      imageUrl,
      notificationType_,
      deeplinkData
    )
  }

  public override fun insertNotification(NotificationsTable: NotificationsTable): Unit {
    driver.execute(-17271912,
        """INSERT OR REPLACE INTO NotificationsTable VALUES (?, ?, ?, ?, ?, ?, ?)""", 7) {
      bindLong(1, NotificationsTable.nId)
      bindLong(2, NotificationsTable.notificationDate)
      bindString(3, NotificationsTable.notificationTitle)
      bindString(4, NotificationsTable.notificationText)
      bindString(5, NotificationsTable.imageUrl)
      bindString(6, NotificationsTable.notificationType)
      bindString(7, NotificationsTable.deeplinkData)
    }
    notifyQueries(-17271912, {database.notificationRoomQueries.getAllNotificationAsFlow +
        database.notificationRoomQueries.getAllNotificationByType})
  }

  private inner class GetAllNotificationByTypeQuery<out T : Any>(
    public val notificationType: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getAllNotificationByType, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-2082604773,
        """SELECT * FROM NotificationsTable WHERE notificationType=? ORDER BY notificationDate DESC""",
        1) {
      bindString(1, notificationType)
    }

    public override fun toString(): String = "NotificationRoom.sq:getAllNotificationByType"
  }
}

private class PortfolioHoldingDataRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), PortfolioHoldingDataRoomQueries {
  internal val selectAllHolding: MutableList<Query<*>> = copyOnWriteList()

  internal val selectHoldingByNse: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectAllHolding(portfolioPKey: Long, mapper: (
    portfolioPKey: Long,
    nseCode: String,
    qty: Long,
    avgBuyPrice: Double,
    transactionDate: Long
  ) -> T): Query<T> = SelectAllHoldingQuery(portfolioPKey) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getDouble(3)!!,
      cursor.getLong(4)!!
    )
  }

  public override fun selectAllHolding(portfolioPKey: Long): Query<PortfolioHoldingDataRoom> =
      selectAllHolding(portfolioPKey) { portfolioPKey_, nseCode, qty, avgBuyPrice,
      transactionDate ->
    PortfolioHoldingDataRoom(
      portfolioPKey_,
      nseCode,
      qty,
      avgBuyPrice,
      transactionDate
    )
  }

  public override fun <T : Any> selectHoldingByNse(nseCode: String, mapper: (
    portfolioPKey: Long,
    nseCode: String,
    qty: Long,
    avgBuyPrice: Double,
    transactionDate: Long
  ) -> T): Query<T> = SelectHoldingByNseQuery(nseCode) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getDouble(3)!!,
      cursor.getLong(4)!!
    )
  }

  public override fun selectHoldingByNse(nseCode: String): Query<PortfolioHoldingDataRoom> =
      selectHoldingByNse(nseCode) { portfolioPKey, nseCode_, qty, avgBuyPrice, transactionDate ->
    PortfolioHoldingDataRoom(
      portfolioPKey,
      nseCode_,
      qty,
      avgBuyPrice,
      transactionDate
    )
  }

  public override fun insert(PortfolioHoldingDataRoom: PortfolioHoldingDataRoom): Unit {
    driver.execute(-1672203705,
        """INSERT OR REPLACE INTO PortfolioHoldingDataRoom VALUES (?, ?, ?, ?, ?)""", 5) {
      bindLong(1, PortfolioHoldingDataRoom.portfolioPKey)
      bindString(2, PortfolioHoldingDataRoom.nseCode)
      bindLong(3, PortfolioHoldingDataRoom.qty)
      bindDouble(4, PortfolioHoldingDataRoom.avgBuyPrice)
      bindLong(5, PortfolioHoldingDataRoom.transactionDate)
    }
    notifyQueries(-1672203705, {database.portfolioHoldingDataRoomQueries.selectHoldingByNse +
        database.portfolioHoldingDataRoomQueries.selectAllHolding})
  }

  public override fun deleteAll(portfolioPKey: Long): Unit {
    driver.execute(730912168, """DELETE FROM PortfolioHoldingDataRoom WHERE portfolioPKey=?""", 1) {
      bindLong(1, portfolioPKey)
    }
    notifyQueries(730912168, {database.portfolioHoldingDataRoomQueries.selectHoldingByNse +
        database.portfolioHoldingDataRoomQueries.selectAllHolding})
  }

  public override fun delete(portfolioPKey: Long, nseCode: String): Unit {
    driver.execute(-1823869639,
        """DELETE FROM PortfolioHoldingDataRoom WHERE portfolioPKey=? AND nseCode=?""", 2) {
      bindLong(1, portfolioPKey)
      bindString(2, nseCode)
    }
    notifyQueries(-1823869639, {database.portfolioHoldingDataRoomQueries.selectHoldingByNse +
        database.portfolioHoldingDataRoomQueries.selectAllHolding})
  }

  private inner class SelectAllHoldingQuery<out T : Any>(
    public val portfolioPKey: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectAllHolding, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1258097164,
        """SELECT * FROM PortfolioHoldingDataRoom WHERE portfolioPKey=?""", 1) {
      bindLong(1, portfolioPKey)
    }

    public override fun toString(): String = "PortfolioHoldingDataRoom.sq:selectAllHolding"
  }

  private inner class SelectHoldingByNseQuery<out T : Any>(
    public val nseCode: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectHoldingByNse, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1452570608,
        """SELECT * FROM  PortfolioHoldingDataRoom WHERE nseCode = ?""", 1) {
      bindString(1, nseCode)
    }

    public override fun toString(): String = "PortfolioHoldingDataRoom.sq:selectHoldingByNse"
  }
}

private class SubscriptionStatusDatabaseQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), SubscriptionStatusDatabaseQueries {
  internal val getAllSubscription: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getAllSubscription(mapper: (
    primaryKey: Long,
    subscriptionStatusJson: String?,
    subAlreadyOwned: Boolean,
    isLocalPurchase: Boolean,
    product: String?,
    purchaseToken: String?,
    isEntitlementActive: Boolean,
    willRenew: Boolean,
    activeUntilMillisec: Long,
    isGracePeriod: Boolean,
    isAccountHold: Boolean,
    isPaused: Boolean,
    isAcknowledged: Boolean,
    autoResumeTimeMillis: Long
  ) -> T): Query<T> = Query(-138027232, getAllSubscription, driver, "SubscriptionStatusDatabase.sq",
      "getAllSubscription", "SELECT * FROM SubscriptionStatusTable") { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1),
      cursor.getLong(2)!! == 1L,
      cursor.getLong(3)!! == 1L,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getLong(6)!! == 1L,
      cursor.getLong(7)!! == 1L,
      cursor.getLong(8)!!,
      cursor.getLong(9)!! == 1L,
      cursor.getLong(10)!! == 1L,
      cursor.getLong(11)!! == 1L,
      cursor.getLong(12)!! == 1L,
      cursor.getLong(13)!!
    )
  }

  public override fun getAllSubscription(): Query<SubscriptionStatusTable> = getAllSubscription {
      primaryKey, subscriptionStatusJson, subAlreadyOwned, isLocalPurchase, product, purchaseToken,
      isEntitlementActive, willRenew, activeUntilMillisec, isGracePeriod, isAccountHold, isPaused,
      isAcknowledged, autoResumeTimeMillis ->
    SubscriptionStatusTable(
      primaryKey,
      subscriptionStatusJson,
      subAlreadyOwned,
      isLocalPurchase,
      product,
      purchaseToken,
      isEntitlementActive,
      willRenew,
      activeUntilMillisec,
      isGracePeriod,
      isAccountHold,
      isPaused,
      isAcknowledged,
      autoResumeTimeMillis
    )
  }

  public override fun insertBillingData(SubscriptionStatusTable: SubscriptionStatusTable): Unit {
    driver.execute(-633878188,
        """INSERT OR REPLACE INTO SubscriptionStatusTable VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)""",
        14) {
      bindLong(1, SubscriptionStatusTable.primaryKey)
      bindString(2, SubscriptionStatusTable.subscriptionStatusJson)
      bindLong(3, if (SubscriptionStatusTable.subAlreadyOwned) 1L else 0L)
      bindLong(4, if (SubscriptionStatusTable.isLocalPurchase) 1L else 0L)
      bindString(5, SubscriptionStatusTable.product)
      bindString(6, SubscriptionStatusTable.purchaseToken)
      bindLong(7, if (SubscriptionStatusTable.isEntitlementActive) 1L else 0L)
      bindLong(8, if (SubscriptionStatusTable.willRenew) 1L else 0L)
      bindLong(9, SubscriptionStatusTable.activeUntilMillisec)
      bindLong(10, if (SubscriptionStatusTable.isGracePeriod) 1L else 0L)
      bindLong(11, if (SubscriptionStatusTable.isAccountHold) 1L else 0L)
      bindLong(12, if (SubscriptionStatusTable.isPaused) 1L else 0L)
      bindLong(13, if (SubscriptionStatusTable.isAcknowledged) 1L else 0L)
      bindLong(14, SubscriptionStatusTable.autoResumeTimeMillis)
    }
    notifyQueries(-633878188, {database.subscriptionStatusDatabaseQueries.getAllSubscription})
  }

  public override fun deleteAllSubscription(): Unit {
    driver.execute(1844482363, """DELETE FROM SubscriptionStatusTable""", 0)
    notifyQueries(1844482363, {database.subscriptionStatusDatabaseQueries.getAllSubscription})
  }
}

private class WatchlisDataRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), WatchlisDataRoomQueries {
  internal val selectAll: MutableList<Query<*>> = copyOnWriteList()

  internal val selectByKey: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectAll(watchlistPKey: Long, mapper: (watchlistPKey: Long,
      nseCode: String) -> T): Query<T> = SelectAllQuery(watchlistPKey) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!
    )
  }

  public override fun selectAll(watchlistPKey: Long): Query<WatchlistDataTable> =
      selectAll(watchlistPKey) { watchlistPKey_, nseCode ->
    WatchlistDataTable(
      watchlistPKey_,
      nseCode
    )
  }

  public override fun <T : Any> selectByKey(watchlistPKey: Long, mapper: (watchlistPKey: Long,
      nseCode: String) -> T): Query<T> = SelectByKeyQuery(watchlistPKey) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!
    )
  }

  public override fun selectByKey(watchlistPKey: Long): Query<WatchlistDataTable> =
      selectByKey(watchlistPKey) { watchlistPKey_, nseCode ->
    WatchlistDataTable(
      watchlistPKey_,
      nseCode
    )
  }

  public override fun insert(WatchlistDataTable: WatchlistDataTable): Unit {
    driver.execute(819738195, """INSERT OR REPLACE INTO WatchlistDataTable VALUES (?, ?)""", 2) {
      bindLong(1, WatchlistDataTable.watchlistPKey)
      bindString(2, WatchlistDataTable.nseCode)
    }
    notifyQueries(819738195, {database.watchlisDataRoomQueries.selectAll +
        database.watchlisDataRoomQueries.selectByKey})
  }

  public override fun remoteFromWatchlist(nseCode: String, watchlistPKey: Long): Unit {
    driver.execute(1803586915,
        """DELETE FROM WatchlistDataTable WHERE nseCode=? AND watchlistPKey=?""", 2) {
      bindString(1, nseCode)
      bindLong(2, watchlistPKey)
    }
    notifyQueries(1803586915, {database.watchlisDataRoomQueries.selectAll +
        database.watchlisDataRoomQueries.selectByKey})
  }

  public override fun deleteWatchlistData(watchlistPKey: Long): Unit {
    driver.execute(-494188046, """DELETE FROM WatchlistDataTable WHERE watchlistPKey =?""", 1) {
      bindLong(1, watchlistPKey)
    }
    notifyQueries(-494188046, {database.watchlisDataRoomQueries.selectAll +
        database.watchlisDataRoomQueries.selectByKey})
  }

  private inner class SelectAllQuery<out T : Any>(
    public val watchlistPKey: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectAll, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1694922453,
        """SELECT * FROM WatchlistDataTable WHERE watchlistPKey=?""", 1) {
      bindLong(1, watchlistPKey)
    }

    public override fun toString(): String = "WatchlisDataRoom.sq:selectAll"
  }

  private inner class SelectByKeyQuery<out T : Any>(
    public val watchlistPKey: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectByKey, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1026589806,
        """SELECT * FROM  WatchlistDataTable WHERE watchlistPKey = ?""", 1) {
      bindLong(1, watchlistPKey)
    }

    public override fun toString(): String = "WatchlisDataRoom.sq:selectByKey"
  }
}

private class Stock2021DataRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), Stock2021DataRoomQueries {
  internal val searchStock: MutableList<Query<*>> = copyOnWriteList()

  internal val searchStockWithLimit: MutableList<Query<*>> = copyOnWriteList()

  internal val getWatchlistByIndex: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllStockList: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllHalalStockList: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllIPOsLiveData: MutableList<Query<*>> = copyOnWriteList()

  internal val getStockByNseCode: MutableList<Query<*>> = copyOnWriteList()

  internal val searchIndustry: MutableList<Query<*>> = copyOnWriteList()

  internal val getStocksByIndustry: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> searchStock(
    name: String,
    nse_code: String,
    bse_code: String,
    mapper: (
      name: String,
      nse_code: String,
      bse_code: String,
      industry: String,
      isCoreActivityHalal: Boolean,
      businessDetail: String?,
      debt: Double,
      market_capitalization: Double,
      total_asset: Double,
      current_asset: Double,
      non_current_asset: Double,
      fixed_asset: Double,
      intangible_assets: Double,
      inventory: Double,
      total_liabilities: Double,
      current_liabilities: Double,
      non_current_liabilities: Double,
      total_revenue: Double,
      interest_income: Double,
      annual_report_url: String,
      watchListIndex: Long,
      isHalal: Boolean,
      outstanding_no_share: Long,
      isActive: Boolean,
      interest_expense: Double,
      net_interest_income: Double,
      isIPO: Boolean,
      ipoLogo: String?,
      ipoTimetableJson: String?
    ) -> T
  ): Query<T> = SearchStockQuery(name, nse_code, bse_code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getLong(20)!!,
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getDouble(24)!!,
      cursor.getDouble(25)!!,
      cursor.getLong(26)!! == 1L,
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public override fun searchStock(
    name: String,
    nse_code: String,
    bse_code: String
  ): Query<StockDbVo2021Table> = searchStock(name, nse_code, bse_code) { name_, nse_code_,
      bse_code_, industry, isCoreActivityHalal, businessDetail, debt, market_capitalization,
      total_asset, current_asset, non_current_asset, fixed_asset, intangible_assets, inventory,
      total_liabilities, current_liabilities, non_current_liabilities, total_revenue,
      interest_income, annual_report_url, watchListIndex, isHalal, outstanding_no_share, isActive,
      interest_expense, net_interest_income, isIPO, ipoLogo, ipoTimetableJson ->
    StockDbVo2021Table(
      name_,
      nse_code_,
      bse_code_,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListIndex,
      isHalal,
      outstanding_no_share,
      isActive,
      interest_expense,
      net_interest_income,
      isIPO,
      ipoLogo,
      ipoTimetableJson
    )
  }

  public override fun <T : Any> searchStockWithLimit(
    name: String,
    nse_code: String,
    bse_code: String,
    mapper: (
      name: String,
      nse_code: String,
      bse_code: String,
      industry: String,
      isCoreActivityHalal: Boolean,
      businessDetail: String?,
      debt: Double,
      market_capitalization: Double,
      total_asset: Double,
      current_asset: Double,
      non_current_asset: Double,
      fixed_asset: Double,
      intangible_assets: Double,
      inventory: Double,
      total_liabilities: Double,
      current_liabilities: Double,
      non_current_liabilities: Double,
      total_revenue: Double,
      interest_income: Double,
      annual_report_url: String,
      watchListIndex: Long,
      isHalal: Boolean,
      outstanding_no_share: Long,
      isActive: Boolean,
      interest_expense: Double,
      net_interest_income: Double,
      isIPO: Boolean,
      ipoLogo: String?,
      ipoTimetableJson: String?
    ) -> T
  ): Query<T> = SearchStockWithLimitQuery(name, nse_code, bse_code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getLong(20)!!,
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getDouble(24)!!,
      cursor.getDouble(25)!!,
      cursor.getLong(26)!! == 1L,
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public override fun searchStockWithLimit(
    name: String,
    nse_code: String,
    bse_code: String
  ): Query<StockDbVo2021Table> = searchStockWithLimit(name, nse_code, bse_code) { name_, nse_code_,
      bse_code_, industry, isCoreActivityHalal, businessDetail, debt, market_capitalization,
      total_asset, current_asset, non_current_asset, fixed_asset, intangible_assets, inventory,
      total_liabilities, current_liabilities, non_current_liabilities, total_revenue,
      interest_income, annual_report_url, watchListIndex, isHalal, outstanding_no_share, isActive,
      interest_expense, net_interest_income, isIPO, ipoLogo, ipoTimetableJson ->
    StockDbVo2021Table(
      name_,
      nse_code_,
      bse_code_,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListIndex,
      isHalal,
      outstanding_no_share,
      isActive,
      interest_expense,
      net_interest_income,
      isIPO,
      ipoLogo,
      ipoTimetableJson
    )
  }

  public override fun <T : Any> getWatchlistByIndex(watchListIndex: Long, mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Boolean,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListIndex: Long,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    interest_expense: Double,
    net_interest_income: Double,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?
  ) -> T): Query<T> = GetWatchlistByIndexQuery(watchListIndex) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getLong(20)!!,
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getDouble(24)!!,
      cursor.getDouble(25)!!,
      cursor.getLong(26)!! == 1L,
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public override fun getWatchlistByIndex(watchListIndex: Long): Query<StockDbVo2021Table> =
      getWatchlistByIndex(watchListIndex) { name, nse_code, bse_code, industry, isCoreActivityHalal,
      businessDetail, debt, market_capitalization, total_asset, current_asset, non_current_asset,
      fixed_asset, intangible_assets, inventory, total_liabilities, current_liabilities,
      non_current_liabilities, total_revenue, interest_income, annual_report_url, watchListIndex_,
      isHalal, outstanding_no_share, isActive, interest_expense, net_interest_income, isIPO,
      ipoLogo, ipoTimetableJson ->
    StockDbVo2021Table(
      name,
      nse_code,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListIndex_,
      isHalal,
      outstanding_no_share,
      isActive,
      interest_expense,
      net_interest_income,
      isIPO,
      ipoLogo,
      ipoTimetableJson
    )
  }

  public override fun <T : Any> getAllStockList(mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Boolean,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListIndex: Long,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    interest_expense: Double,
    net_interest_income: Double,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?
  ) -> T): Query<T> = Query(-807522397, getAllStockList, driver, "Stock2021DataRoom.sq",
      "getAllStockList", "SELECT * FROM StockDbVo2021Table") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getLong(20)!!,
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getDouble(24)!!,
      cursor.getDouble(25)!!,
      cursor.getLong(26)!! == 1L,
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public override fun getAllStockList(): Query<StockDbVo2021Table> = getAllStockList { name,
      nse_code, bse_code, industry, isCoreActivityHalal, businessDetail, debt,
      market_capitalization, total_asset, current_asset, non_current_asset, fixed_asset,
      intangible_assets, inventory, total_liabilities, current_liabilities, non_current_liabilities,
      total_revenue, interest_income, annual_report_url, watchListIndex, isHalal,
      outstanding_no_share, isActive, interest_expense, net_interest_income, isIPO, ipoLogo,
      ipoTimetableJson ->
    StockDbVo2021Table(
      name,
      nse_code,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListIndex,
      isHalal,
      outstanding_no_share,
      isActive,
      interest_expense,
      net_interest_income,
      isIPO,
      ipoLogo,
      ipoTimetableJson
    )
  }

  public override fun <T : Any> getAllHalalStockList(mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Boolean,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListIndex: Long,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    interest_expense: Double,
    net_interest_income: Double,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?
  ) -> T): Query<T> = Query(-1712344313, getAllHalalStockList, driver, "Stock2021DataRoom.sq",
      "getAllHalalStockList", "SELECT * FROM StockDbVo2021Table WHERE isHalal=1") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getLong(20)!!,
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getDouble(24)!!,
      cursor.getDouble(25)!!,
      cursor.getLong(26)!! == 1L,
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public override fun getAllHalalStockList(): Query<StockDbVo2021Table> = getAllHalalStockList {
      name, nse_code, bse_code, industry, isCoreActivityHalal, businessDetail, debt,
      market_capitalization, total_asset, current_asset, non_current_asset, fixed_asset,
      intangible_assets, inventory, total_liabilities, current_liabilities, non_current_liabilities,
      total_revenue, interest_income, annual_report_url, watchListIndex, isHalal,
      outstanding_no_share, isActive, interest_expense, net_interest_income, isIPO, ipoLogo,
      ipoTimetableJson ->
    StockDbVo2021Table(
      name,
      nse_code,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListIndex,
      isHalal,
      outstanding_no_share,
      isActive,
      interest_expense,
      net_interest_income,
      isIPO,
      ipoLogo,
      ipoTimetableJson
    )
  }

  public override fun <T : Any> getAllIPOsLiveData(mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Boolean,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListIndex: Long,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    interest_expense: Double,
    net_interest_income: Double,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?
  ) -> T): Query<T> = Query(-835820558, getAllIPOsLiveData, driver, "Stock2021DataRoom.sq",
      "getAllIPOsLiveData", "SELECT * FROM StockDbVo2021Table WHERE isIPO=1") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getLong(20)!!,
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getDouble(24)!!,
      cursor.getDouble(25)!!,
      cursor.getLong(26)!! == 1L,
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public override fun getAllIPOsLiveData(): Query<StockDbVo2021Table> = getAllIPOsLiveData { name,
      nse_code, bse_code, industry, isCoreActivityHalal, businessDetail, debt,
      market_capitalization, total_asset, current_asset, non_current_asset, fixed_asset,
      intangible_assets, inventory, total_liabilities, current_liabilities, non_current_liabilities,
      total_revenue, interest_income, annual_report_url, watchListIndex, isHalal,
      outstanding_no_share, isActive, interest_expense, net_interest_income, isIPO, ipoLogo,
      ipoTimetableJson ->
    StockDbVo2021Table(
      name,
      nse_code,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListIndex,
      isHalal,
      outstanding_no_share,
      isActive,
      interest_expense,
      net_interest_income,
      isIPO,
      ipoLogo,
      ipoTimetableJson
    )
  }

  public override fun <T : Any> getStockByNseCode(nse_code: String, mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Boolean,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListIndex: Long,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    interest_expense: Double,
    net_interest_income: Double,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?
  ) -> T): Query<T> = GetStockByNseCodeQuery(nse_code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getLong(20)!!,
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getDouble(24)!!,
      cursor.getDouble(25)!!,
      cursor.getLong(26)!! == 1L,
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public override fun getStockByNseCode(nse_code: String): Query<StockDbVo2021Table> =
      getStockByNseCode(nse_code) { name, nse_code_, bse_code, industry, isCoreActivityHalal,
      businessDetail, debt, market_capitalization, total_asset, current_asset, non_current_asset,
      fixed_asset, intangible_assets, inventory, total_liabilities, current_liabilities,
      non_current_liabilities, total_revenue, interest_income, annual_report_url, watchListIndex,
      isHalal, outstanding_no_share, isActive, interest_expense, net_interest_income, isIPO,
      ipoLogo, ipoTimetableJson ->
    StockDbVo2021Table(
      name,
      nse_code_,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListIndex,
      isHalal,
      outstanding_no_share,
      isActive,
      interest_expense,
      net_interest_income,
      isIPO,
      ipoLogo,
      ipoTimetableJson
    )
  }

  public override fun searchIndustry(): Query<String> = Query(-780612756, searchIndustry, driver,
      "Stock2021DataRoom.sq", "searchIndustry",
      "SELECT industry FROM StockDbVo2021Table GROUP BY industry") { cursor ->
    cursor.getString(0)!!
  }

  public override fun <T : Any> getStocksByIndustry(industry: String, mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Boolean,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListIndex: Long,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    interest_expense: Double,
    net_interest_income: Double,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?
  ) -> T): Query<T> = GetStocksByIndustryQuery(industry) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!! == 1L,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getLong(20)!!,
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getDouble(24)!!,
      cursor.getDouble(25)!!,
      cursor.getLong(26)!! == 1L,
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public override fun getStocksByIndustry(industry: String): Query<StockDbVo2021Table> =
      getStocksByIndustry(industry) { name, nse_code, bse_code, industry_, isCoreActivityHalal,
      businessDetail, debt, market_capitalization, total_asset, current_asset, non_current_asset,
      fixed_asset, intangible_assets, inventory, total_liabilities, current_liabilities,
      non_current_liabilities, total_revenue, interest_income, annual_report_url, watchListIndex,
      isHalal, outstanding_no_share, isActive, interest_expense, net_interest_income, isIPO,
      ipoLogo, ipoTimetableJson ->
    StockDbVo2021Table(
      name,
      nse_code,
      bse_code,
      industry_,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListIndex,
      isHalal,
      outstanding_no_share,
      isActive,
      interest_expense,
      net_interest_income,
      isIPO,
      ipoLogo,
      ipoTimetableJson
    )
  }

  public override fun insertStock(StockDbVo2021Table: StockDbVo2021Table): Unit {
    driver.execute(1126542807,
        """INSERT OR REPLACE INTO StockDbVo2021Table VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)""",
        29) {
      bindString(1, StockDbVo2021Table.name)
      bindString(2, StockDbVo2021Table.nse_code)
      bindString(3, StockDbVo2021Table.bse_code)
      bindString(4, StockDbVo2021Table.industry)
      bindLong(5, if (StockDbVo2021Table.isCoreActivityHalal) 1L else 0L)
      bindString(6, StockDbVo2021Table.businessDetail)
      bindDouble(7, StockDbVo2021Table.debt)
      bindDouble(8, StockDbVo2021Table.market_capitalization)
      bindDouble(9, StockDbVo2021Table.total_asset)
      bindDouble(10, StockDbVo2021Table.current_asset)
      bindDouble(11, StockDbVo2021Table.non_current_asset)
      bindDouble(12, StockDbVo2021Table.fixed_asset)
      bindDouble(13, StockDbVo2021Table.intangible_assets)
      bindDouble(14, StockDbVo2021Table.inventory)
      bindDouble(15, StockDbVo2021Table.total_liabilities)
      bindDouble(16, StockDbVo2021Table.current_liabilities)
      bindDouble(17, StockDbVo2021Table.non_current_liabilities)
      bindDouble(18, StockDbVo2021Table.total_revenue)
      bindDouble(19, StockDbVo2021Table.interest_income)
      bindString(20, StockDbVo2021Table.annual_report_url)
      bindLong(21, StockDbVo2021Table.watchListIndex)
      bindLong(22, if (StockDbVo2021Table.isHalal) 1L else 0L)
      bindLong(23, StockDbVo2021Table.outstanding_no_share)
      bindLong(24, if (StockDbVo2021Table.isActive) 1L else 0L)
      bindDouble(25, StockDbVo2021Table.interest_expense)
      bindDouble(26, StockDbVo2021Table.net_interest_income)
      bindLong(27, if (StockDbVo2021Table.isIPO) 1L else 0L)
      bindString(28, StockDbVo2021Table.ipoLogo)
      bindString(29, StockDbVo2021Table.ipoTimetableJson)
    }
    notifyQueries(1126542807, {database.stock2021DataRoomQueries.getStockByNseCode +
        database.stock2021DataRoomQueries.searchStockWithLimit +
        database.stock2021DataRoomQueries.getAllHalalStockList +
        database.stock2021DataRoomQueries.getAllIPOsLiveData +
        database.stock2021DataRoomQueries.getAllStockList +
        database.stock2021DataRoomQueries.searchIndustry +
        database.stock2021DataRoomQueries.getStocksByIndustry +
        database.stock2021DataRoomQueries.searchStock +
        database.stock2021DataRoomQueries.getWatchlistByIndex})
  }

  public override fun updateWatchlistByNseCode(nse_code: String): Unit {
    driver.execute(-734225800,
        """UPDATE StockDbVo2021Table SET watchListIndex=1 WHERE nse_code = ?""", 1) {
      bindString(1, nse_code)
    }
    notifyQueries(-734225800, {database.stock2021DataRoomQueries.getStockByNseCode +
        database.stock2021DataRoomQueries.searchStockWithLimit +
        database.stock2021DataRoomQueries.getAllHalalStockList +
        database.stock2021DataRoomQueries.getAllIPOsLiveData +
        database.stock2021DataRoomQueries.getAllStockList +
        database.stock2021DataRoomQueries.searchIndustry +
        database.stock2021DataRoomQueries.getStocksByIndustry +
        database.stock2021DataRoomQueries.searchStock +
        database.stock2021DataRoomQueries.getWatchlistByIndex})
  }

  public override fun removeWatchlist(nse_code: String): Unit {
    driver.execute(1330512579,
        """UPDATE StockDbVo2021Table SET watchListIndex=0 WHERE nse_code = ?""", 1) {
      bindString(1, nse_code)
    }
    notifyQueries(1330512579, {database.stock2021DataRoomQueries.getStockByNseCode +
        database.stock2021DataRoomQueries.searchStockWithLimit +
        database.stock2021DataRoomQueries.getAllHalalStockList +
        database.stock2021DataRoomQueries.getAllIPOsLiveData +
        database.stock2021DataRoomQueries.getAllStockList +
        database.stock2021DataRoomQueries.searchIndustry +
        database.stock2021DataRoomQueries.getStocksByIndustry +
        database.stock2021DataRoomQueries.searchStock +
        database.stock2021DataRoomQueries.getWatchlistByIndex})
  }

  public override fun setIsHalalStock(
    isHalal: Boolean,
    nse_code: String,
    bse_code: String
  ): Unit {
    driver.execute(31091454,
        """UPDATE StockDbVo2021Table SET isHalal=? WHERE nse_code=? AND bse_code = ?""", 3) {
      bindLong(1, if (isHalal) 1L else 0L)
      bindString(2, nse_code)
      bindString(3, bse_code)
    }
    notifyQueries(31091454, {database.stock2021DataRoomQueries.getStockByNseCode +
        database.stock2021DataRoomQueries.searchStockWithLimit +
        database.stock2021DataRoomQueries.getAllHalalStockList +
        database.stock2021DataRoomQueries.getAllIPOsLiveData +
        database.stock2021DataRoomQueries.getAllStockList +
        database.stock2021DataRoomQueries.searchIndustry +
        database.stock2021DataRoomQueries.getStocksByIndustry +
        database.stock2021DataRoomQueries.searchStock +
        database.stock2021DataRoomQueries.getWatchlistByIndex})
  }

  private inner class SearchStockQuery<out T : Any>(
    public val name: String,
    public val nse_code: String,
    public val bse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(searchStock, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(690456648,
        """SELECT * FROM StockDbVo2021Table WHERE name LIKE ? OR nse_code LIKE ? OR bse_code LIKE ?""",
        3) {
      bindString(1, name)
      bindString(2, nse_code)
      bindString(3, bse_code)
    }

    public override fun toString(): String = "Stock2021DataRoom.sq:searchStock"
  }

  private inner class SearchStockWithLimitQuery<out T : Any>(
    public val name: String,
    public val nse_code: String,
    public val bse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(searchStockWithLimit, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1740529875,
        """SELECT * FROM StockDbVo2021Table WHERE name LIKE ? OR nse_code LIKE ? OR bse_code LIKE ? LIMIT 10""",
        3) {
      bindString(1, name)
      bindString(2, nse_code)
      bindString(3, bse_code)
    }

    public override fun toString(): String = "Stock2021DataRoom.sq:searchStockWithLimit"
  }

  private inner class GetWatchlistByIndexQuery<out T : Any>(
    public val watchListIndex: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getWatchlistByIndex, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1114293118,
        """SELECT * FROM StockDbVo2021Table WHERE watchListIndex =?""", 1) {
      bindLong(1, watchListIndex)
    }

    public override fun toString(): String = "Stock2021DataRoom.sq:getWatchlistByIndex"
  }

  private inner class GetStockByNseCodeQuery<out T : Any>(
    public val nse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getStockByNseCode, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1761033744,
        """SELECT * FROM StockDbVo2021Table WHERE nse_code=? LIMIT 1""", 1) {
      bindString(1, nse_code)
    }

    public override fun toString(): String = "Stock2021DataRoom.sq:getStockByNseCode"
  }

  private inner class GetStocksByIndustryQuery<out T : Any>(
    public val industry: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getStocksByIndustry, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-315629886,
        """SELECT * FROM StockDbVo2021Table WHERE industry LIKE ?""", 1) {
      bindString(1, industry)
    }

    public override fun toString(): String = "Stock2021DataRoom.sq:getStocksByIndustry"
  }
}

private class TrackingNameRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), TrackingNameRoomQueries {
  internal val selectAll: MutableList<Query<*>> = copyOnWriteList()

  internal val selectByName: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectAll(mapper: (trackingNamePKey: Long, name: String) -> T):
      Query<T> = Query(-1983969510, selectAll, driver, "TrackingNameRoom.sq", "selectAll",
      "SELECT * FROM TrackingNameTable") { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!
    )
  }

  public override fun selectAll(): Query<TrackingNameTable> = selectAll { trackingNamePKey, name ->
    TrackingNameTable(
      trackingNamePKey,
      name
    )
  }

  public override fun <T : Any> selectByName(name: String, mapper: (trackingNamePKey: Long,
      name: String) -> T): Query<T> = SelectByNameQuery(name) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!
    )
  }

  public override fun selectByName(name: String): Query<TrackingNameTable> = selectByName(name) {
      trackingNamePKey, name_ ->
    TrackingNameTable(
      trackingNamePKey,
      name_
    )
  }

  public override fun insert(name: String): Unit {
    driver.execute(2021529284, """INSERT OR REPLACE INTO TrackingNameTable(name) VALUES(?)""", 1) {
      bindString(1, name)
    }
    notifyQueries(2021529284, {database.trackingNameRoomQueries.selectAll +
        database.trackingNameRoomQueries.selectByName})
  }

  public override fun insertFromServer(trackingNamePKey: Long?, name: String): Unit {
    driver.execute(269654385,
        """INSERT OR REPLACE INTO TrackingNameTable(trackingNamePKey,name) VALUES(?,?)""", 2) {
      bindLong(1, trackingNamePKey)
      bindString(2, name)
    }
    notifyQueries(269654385, {database.trackingNameRoomQueries.selectAll +
        database.trackingNameRoomQueries.selectByName})
  }

  public override fun delete(trackingNamePKey: Long): Unit {
    driver.execute(1869863350, """DELETE FROM TrackingNameTable WHERE trackingNamePKey =?""", 1) {
      bindLong(1, trackingNamePKey)
    }
    notifyQueries(1869863350, {database.trackingNameRoomQueries.selectAll +
        database.trackingNameRoomQueries.selectByName})
  }

  public override fun update(name: String, trackingNamePKey: Long): Unit {
    driver.execute(-1928491820, """UPDATE TrackingNameTable SET name=? WHERE trackingNamePKey=?""",
        2) {
      bindString(1, name)
      bindLong(2, trackingNamePKey)
    }
    notifyQueries(-1928491820, {database.trackingNameRoomQueries.selectAll +
        database.trackingNameRoomQueries.selectByName})
  }

  private inner class SelectByNameQuery<out T : Any>(
    public val name: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectByName, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1350874263,
        """SELECT * FROM TrackingNameTable WHERE name=?""", 1) {
      bindString(1, name)
    }

    public override fun toString(): String = "TrackingNameRoom.sq:selectByName"
  }
}

private class RealtimeDataRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), RealtimeDataRoomQueries {
  internal val getRealtimePrice: MutableList<Query<*>> = copyOnWriteList()

  internal val monitorAllRealtimePrice: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getRealtimePrice(nse_code: String, mapper: (
    nse_code: String,
    lastTradePrice: Double,
    todayHigh: Double,
    todayLow: Double,
    todayPercentChange: Double,
    todayPriceChange: Double,
    previousClose: Double,
    marketLtpTime: Long,
    price7DaysAgo: Double,
    price1MonthAgo: Double,
    price3MonthsAgo: Double,
    price6MonthAgo: Double,
    price1YearAgo: Double
  ) -> T): Query<T> = GetRealtimePriceQuery(nse_code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getDouble(1)!!,
      cursor.getDouble(2)!!,
      cursor.getDouble(3)!!,
      cursor.getDouble(4)!!,
      cursor.getDouble(5)!!,
      cursor.getDouble(6)!!,
      cursor.getLong(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!
    )
  }

  public override fun getRealtimePrice(nse_code: String): Query<RealtimePriceTable> =
      getRealtimePrice(nse_code) { nse_code_, lastTradePrice, todayHigh, todayLow,
      todayPercentChange, todayPriceChange, previousClose, marketLtpTime, price7DaysAgo,
      price1MonthAgo, price3MonthsAgo, price6MonthAgo, price1YearAgo ->
    RealtimePriceTable(
      nse_code_,
      lastTradePrice,
      todayHigh,
      todayLow,
      todayPercentChange,
      todayPriceChange,
      previousClose,
      marketLtpTime,
      price7DaysAgo,
      price1MonthAgo,
      price3MonthsAgo,
      price6MonthAgo,
      price1YearAgo
    )
  }

  public override fun <T : Any> monitorAllRealtimePrice(nse_code: String, mapper: (
    nse_code: String,
    lastTradePrice: Double,
    todayHigh: Double,
    todayLow: Double,
    todayPercentChange: Double,
    todayPriceChange: Double,
    previousClose: Double,
    marketLtpTime: Long,
    price7DaysAgo: Double,
    price1MonthAgo: Double,
    price3MonthsAgo: Double,
    price6MonthAgo: Double,
    price1YearAgo: Double
  ) -> T): Query<T> = MonitorAllRealtimePriceQuery(nse_code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getDouble(1)!!,
      cursor.getDouble(2)!!,
      cursor.getDouble(3)!!,
      cursor.getDouble(4)!!,
      cursor.getDouble(5)!!,
      cursor.getDouble(6)!!,
      cursor.getLong(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!
    )
  }

  public override fun monitorAllRealtimePrice(nse_code: String): Query<RealtimePriceTable> =
      monitorAllRealtimePrice(nse_code) { nse_code_, lastTradePrice, todayHigh, todayLow,
      todayPercentChange, todayPriceChange, previousClose, marketLtpTime, price7DaysAgo,
      price1MonthAgo, price3MonthsAgo, price6MonthAgo, price1YearAgo ->
    RealtimePriceTable(
      nse_code_,
      lastTradePrice,
      todayHigh,
      todayLow,
      todayPercentChange,
      todayPriceChange,
      previousClose,
      marketLtpTime,
      price7DaysAgo,
      price1MonthAgo,
      price3MonthsAgo,
      price6MonthAgo,
      price1YearAgo
    )
  }

  public override fun deleteFromRealtimePrice(nse_code: String): Unit {
    driver.execute(1078812619, """DELETE FROM RealtimePriceTable WHERE nse_code=?""", 1) {
      bindString(1, nse_code)
    }
    notifyQueries(1078812619, {database.realtimeDataRoomQueries.monitorAllRealtimePrice +
        database.realtimeDataRoomQueries.getRealtimePrice})
  }

  public override fun updateRealtimePrice(
    lastTradePrice: Double,
    todayHigh: Double,
    todayLow: Double,
    nse_code: String
  ): Unit {
    driver.execute(1061995639,
        """UPDATE RealtimePriceTable SET lastTradePrice=?,todayHigh=?,todayLow=? WHERE nse_code=?""",
        4) {
      bindDouble(1, lastTradePrice)
      bindDouble(2, todayHigh)
      bindDouble(3, todayLow)
      bindString(4, nse_code)
    }
    notifyQueries(1061995639, {database.realtimeDataRoomQueries.monitorAllRealtimePrice +
        database.realtimeDataRoomQueries.getRealtimePrice})
  }

  public override fun insertRealtimePrice(RealtimePriceTable: RealtimePriceTable): Unit {
    driver.execute(-626007929,
        """INSERT OR REPLACE INTO RealtimePriceTable VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)""",
        13) {
      bindString(1, RealtimePriceTable.nse_code)
      bindDouble(2, RealtimePriceTable.lastTradePrice)
      bindDouble(3, RealtimePriceTable.todayHigh)
      bindDouble(4, RealtimePriceTable.todayLow)
      bindDouble(5, RealtimePriceTable.todayPercentChange)
      bindDouble(6, RealtimePriceTable.todayPriceChange)
      bindDouble(7, RealtimePriceTable.previousClose)
      bindLong(8, RealtimePriceTable.marketLtpTime)
      bindDouble(9, RealtimePriceTable.price7DaysAgo)
      bindDouble(10, RealtimePriceTable.price1MonthAgo)
      bindDouble(11, RealtimePriceTable.price3MonthsAgo)
      bindDouble(12, RealtimePriceTable.price6MonthAgo)
      bindDouble(13, RealtimePriceTable.price1YearAgo)
    }
    notifyQueries(-626007929, {database.realtimeDataRoomQueries.monitorAllRealtimePrice +
        database.realtimeDataRoomQueries.getRealtimePrice})
  }

  private inner class GetRealtimePriceQuery<out T : Any>(
    public val nse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getRealtimePrice, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1352039942,
        """SELECT * FROM RealtimePriceTable WHERE nse_code=?""", 1) {
      bindString(1, nse_code)
    }

    public override fun toString(): String = "RealtimeDataRoom.sq:getRealtimePrice"
  }

  private inner class MonitorAllRealtimePriceQuery<out T : Any>(
    public val nse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(monitorAllRealtimePrice, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1132973849,
        """SELECT * FROM RealtimePriceTable WHERE nse_code=?""", 1) {
      bindString(1, nse_code)
    }

    public override fun toString(): String = "RealtimeDataRoom.sq:monitorAllRealtimePrice"
  }
}

private class Stock2022DataRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), Stock2022DataRoomQueries {
  internal val searchStock: MutableList<Query<*>> = copyOnWriteList()

  internal val searchStockWithLimit: MutableList<Query<*>> = copyOnWriteList()

  internal val selectMaxLastUpdatedTime: MutableList<Query<*>> = copyOnWriteList()

  internal val getWatchlistPKeysWatchlistByNseCode: MutableList<Query<*>> = copyOnWriteList()

  internal val getWatchlistByWatchlistPKey: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllStockList: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllHalalStockList: MutableList<Query<*>> = copyOnWriteList()

  internal val getStockCounter: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllIPOsLiveData: MutableList<Query<*>> = copyOnWriteList()

  internal val getStockByNseCode: MutableList<Query<*>> = copyOnWriteList()

  internal val getStockByBseCode: MutableList<Query<*>> = copyOnWriteList()

  internal val getRecentlyUpdatedStock: MutableList<Query<*>> = copyOnWriteList()

  internal val getDailyPriceJson: MutableList<Query<*>> = copyOnWriteList()

  internal val searchIndustry: MutableList<Query<*>> = copyOnWriteList()

  internal val getStocksByIndustry: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> searchStock(
    nse_code: String,
    name: String,
    bse_code: String,
    mapper: (
      name: String,
      nse_code: String,
      bse_code: String,
      industry: String,
      isCoreActivityHalal: Long,
      businessDetail: String?,
      debt: Double,
      market_capitalization: Double,
      total_asset: Double,
      current_asset: Double,
      non_current_asset: Double,
      fixed_asset: Double,
      intangible_assets: Double,
      inventory: Double,
      total_liabilities: Double,
      current_liabilities: Double,
      non_current_liabilities: Double,
      total_revenue: Double,
      interest_income: Double,
      annual_report_url: String,
      watchListPKeys: String?,
      isHalal: Boolean,
      outstanding_no_share: Long,
      isActive: Boolean,
      isIPO: Boolean,
      ipoLogo: String?,
      ipoTimetableJson: String?,
      assetProfileJSON: String?,
      dailyPriceJson: String?,
      isBSE: Boolean,
      otherNonPermissibleIncome: Double?,
      nonPermissibleIncomeOperationSegment: Double?,
      lastUpdatedTime: Long
    ) -> T
  ): Query<T> = SearchStockQuery(nse_code, name, bse_code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!!,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getString(20),
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getLong(24)!! == 1L,
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)!! == 1L,
      cursor.getDouble(30),
      cursor.getDouble(31),
      cursor.getLong(32)!!
    )
  }

  public override fun searchStock(
    nse_code: String,
    name: String,
    bse_code: String
  ): Query<StockDbVo2022Table> = searchStock(nse_code, name, bse_code) { name_, nse_code_,
      bse_code_, industry, isCoreActivityHalal, businessDetail, debt, market_capitalization,
      total_asset, current_asset, non_current_asset, fixed_asset, intangible_assets, inventory,
      total_liabilities, current_liabilities, non_current_liabilities, total_revenue,
      interest_income, annual_report_url, watchListPKeys, isHalal, outstanding_no_share, isActive,
      isIPO, ipoLogo, ipoTimetableJson, assetProfileJSON, dailyPriceJson, isBSE,
      otherNonPermissibleIncome, nonPermissibleIncomeOperationSegment, lastUpdatedTime ->
    StockDbVo2022Table(
      name_,
      nse_code_,
      bse_code_,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListPKeys,
      isHalal,
      outstanding_no_share,
      isActive,
      isIPO,
      ipoLogo,
      ipoTimetableJson,
      assetProfileJSON,
      dailyPriceJson,
      isBSE,
      otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> searchStockWithLimit(
    name: String,
    nse_code: String,
    bse_code: String,
    mapper: (
      name: String,
      nse_code: String,
      bse_code: String,
      industry: String,
      isCoreActivityHalal: Long,
      businessDetail: String?,
      debt: Double,
      market_capitalization: Double,
      total_asset: Double,
      current_asset: Double,
      non_current_asset: Double,
      fixed_asset: Double,
      intangible_assets: Double,
      inventory: Double,
      total_liabilities: Double,
      current_liabilities: Double,
      non_current_liabilities: Double,
      total_revenue: Double,
      interest_income: Double,
      annual_report_url: String,
      watchListPKeys: String?,
      isHalal: Boolean,
      outstanding_no_share: Long,
      isActive: Boolean,
      isIPO: Boolean,
      ipoLogo: String?,
      ipoTimetableJson: String?,
      assetProfileJSON: String?,
      dailyPriceJson: String?,
      isBSE: Boolean,
      otherNonPermissibleIncome: Double?,
      nonPermissibleIncomeOperationSegment: Double?,
      lastUpdatedTime: Long
    ) -> T
  ): Query<T> = SearchStockWithLimitQuery(name, nse_code, bse_code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!!,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getString(20),
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getLong(24)!! == 1L,
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)!! == 1L,
      cursor.getDouble(30),
      cursor.getDouble(31),
      cursor.getLong(32)!!
    )
  }

  public override fun searchStockWithLimit(
    name: String,
    nse_code: String,
    bse_code: String
  ): Query<StockDbVo2022Table> = searchStockWithLimit(name, nse_code, bse_code) { name_, nse_code_,
      bse_code_, industry, isCoreActivityHalal, businessDetail, debt, market_capitalization,
      total_asset, current_asset, non_current_asset, fixed_asset, intangible_assets, inventory,
      total_liabilities, current_liabilities, non_current_liabilities, total_revenue,
      interest_income, annual_report_url, watchListPKeys, isHalal, outstanding_no_share, isActive,
      isIPO, ipoLogo, ipoTimetableJson, assetProfileJSON, dailyPriceJson, isBSE,
      otherNonPermissibleIncome, nonPermissibleIncomeOperationSegment, lastUpdatedTime ->
    StockDbVo2022Table(
      name_,
      nse_code_,
      bse_code_,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListPKeys,
      isHalal,
      outstanding_no_share,
      isActive,
      isIPO,
      ipoLogo,
      ipoTimetableJson,
      assetProfileJSON,
      dailyPriceJson,
      isBSE,
      otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> selectMaxLastUpdatedTime(mapper: (MAX: Long?) -> T): Query<T> =
      Query(-1227026031, selectMaxLastUpdatedTime, driver, "Stock2022DataRoom.sq",
      "selectMaxLastUpdatedTime", "SELECT MAX(lastUpdatedTime) FROM StockDbVo2022Table") { cursor ->
    mapper(
      cursor.getLong(0)
    )
  }

  public override fun selectMaxLastUpdatedTime():
      Query<com.halal.stocks.stock2022DataRoom.SelectMaxLastUpdatedTime> =
      selectMaxLastUpdatedTime { MAX ->
    com.halal.stocks.stock2022DataRoom.SelectMaxLastUpdatedTime(
      MAX
    )
  }

  public override fun <T : Any> getWatchlistPKeysWatchlistByNseCode(nse_code: String,
      mapper: (watchListPKeys: String?) -> T): Query<T> =
      GetWatchlistPKeysWatchlistByNseCodeQuery(nse_code) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public override fun getWatchlistPKeysWatchlistByNseCode(nse_code: String):
      Query<GetWatchlistPKeysWatchlistByNseCode> = getWatchlistPKeysWatchlistByNseCode(nse_code) {
      watchListPKeys ->
    GetWatchlistPKeysWatchlistByNseCode(
      watchListPKeys
    )
  }

  public override fun <T : Any> getWatchlistByWatchlistPKey(watchListPKeys: String, mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Long,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListPKeys: String?,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?,
    assetProfileJSON: String?,
    dailyPriceJson: String?,
    isBSE: Boolean,
    otherNonPermissibleIncome: Double?,
    nonPermissibleIncomeOperationSegment: Double?,
    lastUpdatedTime: Long
  ) -> T): Query<T> = GetWatchlistByWatchlistPKeyQuery(watchListPKeys) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!!,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getString(20),
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getLong(24)!! == 1L,
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)!! == 1L,
      cursor.getDouble(30),
      cursor.getDouble(31),
      cursor.getLong(32)!!
    )
  }

  public override fun getWatchlistByWatchlistPKey(watchListPKeys: String): Query<StockDbVo2022Table>
      = getWatchlistByWatchlistPKey(watchListPKeys) { name, nse_code, bse_code, industry,
      isCoreActivityHalal, businessDetail, debt, market_capitalization, total_asset, current_asset,
      non_current_asset, fixed_asset, intangible_assets, inventory, total_liabilities,
      current_liabilities, non_current_liabilities, total_revenue, interest_income,
      annual_report_url, watchListPKeys_, isHalal, outstanding_no_share, isActive, isIPO, ipoLogo,
      ipoTimetableJson, assetProfileJSON, dailyPriceJson, isBSE, otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment, lastUpdatedTime ->
    StockDbVo2022Table(
      name,
      nse_code,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListPKeys_,
      isHalal,
      outstanding_no_share,
      isActive,
      isIPO,
      ipoLogo,
      ipoTimetableJson,
      assetProfileJSON,
      dailyPriceJson,
      isBSE,
      otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> getAllStockList(mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Long,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListPKeys: String?,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?,
    assetProfileJSON: String?,
    dailyPriceJson: String?,
    isBSE: Boolean,
    otherNonPermissibleIncome: Double?,
    nonPermissibleIncomeOperationSegment: Double?,
    lastUpdatedTime: Long
  ) -> T): Query<T> = Query(-1062258942, getAllStockList, driver, "Stock2022DataRoom.sq",
      "getAllStockList", "SELECT * FROM StockDbVo2022Table") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!!,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getString(20),
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getLong(24)!! == 1L,
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)!! == 1L,
      cursor.getDouble(30),
      cursor.getDouble(31),
      cursor.getLong(32)!!
    )
  }

  public override fun getAllStockList(): Query<StockDbVo2022Table> = getAllStockList { name,
      nse_code, bse_code, industry, isCoreActivityHalal, businessDetail, debt,
      market_capitalization, total_asset, current_asset, non_current_asset, fixed_asset,
      intangible_assets, inventory, total_liabilities, current_liabilities, non_current_liabilities,
      total_revenue, interest_income, annual_report_url, watchListPKeys, isHalal,
      outstanding_no_share, isActive, isIPO, ipoLogo, ipoTimetableJson, assetProfileJSON,
      dailyPriceJson, isBSE, otherNonPermissibleIncome, nonPermissibleIncomeOperationSegment,
      lastUpdatedTime ->
    StockDbVo2022Table(
      name,
      nse_code,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListPKeys,
      isHalal,
      outstanding_no_share,
      isActive,
      isIPO,
      ipoLogo,
      ipoTimetableJson,
      assetProfileJSON,
      dailyPriceJson,
      isBSE,
      otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> getAllHalalStockList(mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Long,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListPKeys: String?,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?,
    assetProfileJSON: String?,
    dailyPriceJson: String?,
    isBSE: Boolean,
    otherNonPermissibleIncome: Double?,
    nonPermissibleIncomeOperationSegment: Double?,
    lastUpdatedTime: Long
  ) -> T): Query<T> = Query(398946056, getAllHalalStockList, driver, "Stock2022DataRoom.sq",
      "getAllHalalStockList", "SELECT * FROM StockDbVo2022Table WHERE isHalal=1") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!!,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getString(20),
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getLong(24)!! == 1L,
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)!! == 1L,
      cursor.getDouble(30),
      cursor.getDouble(31),
      cursor.getLong(32)!!
    )
  }

  public override fun getAllHalalStockList(): Query<StockDbVo2022Table> = getAllHalalStockList {
      name, nse_code, bse_code, industry, isCoreActivityHalal, businessDetail, debt,
      market_capitalization, total_asset, current_asset, non_current_asset, fixed_asset,
      intangible_assets, inventory, total_liabilities, current_liabilities, non_current_liabilities,
      total_revenue, interest_income, annual_report_url, watchListPKeys, isHalal,
      outstanding_no_share, isActive, isIPO, ipoLogo, ipoTimetableJson, assetProfileJSON,
      dailyPriceJson, isBSE, otherNonPermissibleIncome, nonPermissibleIncomeOperationSegment,
      lastUpdatedTime ->
    StockDbVo2022Table(
      name,
      nse_code,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListPKeys,
      isHalal,
      outstanding_no_share,
      isActive,
      isIPO,
      ipoLogo,
      ipoTimetableJson,
      assetProfileJSON,
      dailyPriceJson,
      isBSE,
      otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> getStockCounter(mapper: (totalHalalStocks: Long,
      totalStocks: Long) -> T): Query<T> = Query(1779408085, getStockCounter, driver,
      "Stock2022DataRoom.sq", "getStockCounter",
      "SELECT (SELECT COUNT()  FROM StockDbVo2022Table WHERE isHalal=1) AS totalHalalStocks, (SELECT COUNT(*)  FROM StockDbVo2022Table ) AS totalStocks") {
      cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1)!!
    )
  }

  public override fun getStockCounter(): Query<GetStockCounter> = getStockCounter {
      totalHalalStocks, totalStocks ->
    GetStockCounter(
      totalHalalStocks,
      totalStocks
    )
  }

  public override fun <T : Any> getAllIPOsLiveData(mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Long,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListPKeys: String?,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?,
    assetProfileJSON: String?,
    dailyPriceJson: String?,
    isBSE: Boolean,
    otherNonPermissibleIncome: Double?,
    nonPermissibleIncomeOperationSegment: Double?,
    lastUpdatedTime: Long
  ) -> T): Query<T> = Query(-485020621, getAllIPOsLiveData, driver, "Stock2022DataRoom.sq",
      "getAllIPOsLiveData", "SELECT * FROM StockDbVo2022Table WHERE isIPO=1") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!!,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getString(20),
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getLong(24)!! == 1L,
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)!! == 1L,
      cursor.getDouble(30),
      cursor.getDouble(31),
      cursor.getLong(32)!!
    )
  }

  public override fun getAllIPOsLiveData(): Query<StockDbVo2022Table> = getAllIPOsLiveData { name,
      nse_code, bse_code, industry, isCoreActivityHalal, businessDetail, debt,
      market_capitalization, total_asset, current_asset, non_current_asset, fixed_asset,
      intangible_assets, inventory, total_liabilities, current_liabilities, non_current_liabilities,
      total_revenue, interest_income, annual_report_url, watchListPKeys, isHalal,
      outstanding_no_share, isActive, isIPO, ipoLogo, ipoTimetableJson, assetProfileJSON,
      dailyPriceJson, isBSE, otherNonPermissibleIncome, nonPermissibleIncomeOperationSegment,
      lastUpdatedTime ->
    StockDbVo2022Table(
      name,
      nse_code,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListPKeys,
      isHalal,
      outstanding_no_share,
      isActive,
      isIPO,
      ipoLogo,
      ipoTimetableJson,
      assetProfileJSON,
      dailyPriceJson,
      isBSE,
      otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> getStockByNseCode(nse_code: String, mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Long,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListPKeys: String?,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?,
    assetProfileJSON: String?,
    dailyPriceJson: String?,
    isBSE: Boolean,
    otherNonPermissibleIncome: Double?,
    nonPermissibleIncomeOperationSegment: Double?,
    lastUpdatedTime: Long
  ) -> T): Query<T> = GetStockByNseCodeQuery(nse_code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!!,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getString(20),
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getLong(24)!! == 1L,
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)!! == 1L,
      cursor.getDouble(30),
      cursor.getDouble(31),
      cursor.getLong(32)!!
    )
  }

  public override fun getStockByNseCode(nse_code: String): Query<StockDbVo2022Table> =
      getStockByNseCode(nse_code) { name, nse_code_, bse_code, industry, isCoreActivityHalal,
      businessDetail, debt, market_capitalization, total_asset, current_asset, non_current_asset,
      fixed_asset, intangible_assets, inventory, total_liabilities, current_liabilities,
      non_current_liabilities, total_revenue, interest_income, annual_report_url, watchListPKeys,
      isHalal, outstanding_no_share, isActive, isIPO, ipoLogo, ipoTimetableJson, assetProfileJSON,
      dailyPriceJson, isBSE, otherNonPermissibleIncome, nonPermissibleIncomeOperationSegment,
      lastUpdatedTime ->
    StockDbVo2022Table(
      name,
      nse_code_,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListPKeys,
      isHalal,
      outstanding_no_share,
      isActive,
      isIPO,
      ipoLogo,
      ipoTimetableJson,
      assetProfileJSON,
      dailyPriceJson,
      isBSE,
      otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> getStockByBseCode(bse_code: String, mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Long,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListPKeys: String?,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?,
    assetProfileJSON: String?,
    dailyPriceJson: String?,
    isBSE: Boolean,
    otherNonPermissibleIncome: Double?,
    nonPermissibleIncomeOperationSegment: Double?,
    lastUpdatedTime: Long
  ) -> T): Query<T> = GetStockByBseCodeQuery(bse_code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!!,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getString(20),
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getLong(24)!! == 1L,
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)!! == 1L,
      cursor.getDouble(30),
      cursor.getDouble(31),
      cursor.getLong(32)!!
    )
  }

  public override fun getStockByBseCode(bse_code: String): Query<StockDbVo2022Table> =
      getStockByBseCode(bse_code) { name, nse_code, bse_code_, industry, isCoreActivityHalal,
      businessDetail, debt, market_capitalization, total_asset, current_asset, non_current_asset,
      fixed_asset, intangible_assets, inventory, total_liabilities, current_liabilities,
      non_current_liabilities, total_revenue, interest_income, annual_report_url, watchListPKeys,
      isHalal, outstanding_no_share, isActive, isIPO, ipoLogo, ipoTimetableJson, assetProfileJSON,
      dailyPriceJson, isBSE, otherNonPermissibleIncome, nonPermissibleIncomeOperationSegment,
      lastUpdatedTime ->
    StockDbVo2022Table(
      name,
      nse_code,
      bse_code_,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListPKeys,
      isHalal,
      outstanding_no_share,
      isActive,
      isIPO,
      ipoLogo,
      ipoTimetableJson,
      assetProfileJSON,
      dailyPriceJson,
      isBSE,
      otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> getRecentlyUpdatedStock(mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Long,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListPKeys: String?,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?,
    assetProfileJSON: String?,
    dailyPriceJson: String?,
    isBSE: Boolean,
    otherNonPermissibleIncome: Double?,
    nonPermissibleIncomeOperationSegment: Double?,
    lastUpdatedTime: Long
  ) -> T): Query<T> = Query(490000434, getRecentlyUpdatedStock, driver, "Stock2022DataRoom.sq",
      "getRecentlyUpdatedStock",
      "SELECT * FROM StockDbVo2022Table WHERE isActive=1 ORDER BY lastUpdatedTime DESC LIMIT 40") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!!,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getString(20),
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getLong(24)!! == 1L,
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)!! == 1L,
      cursor.getDouble(30),
      cursor.getDouble(31),
      cursor.getLong(32)!!
    )
  }

  public override fun getRecentlyUpdatedStock(): Query<StockDbVo2022Table> =
      getRecentlyUpdatedStock { name, nse_code, bse_code, industry, isCoreActivityHalal,
      businessDetail, debt, market_capitalization, total_asset, current_asset, non_current_asset,
      fixed_asset, intangible_assets, inventory, total_liabilities, current_liabilities,
      non_current_liabilities, total_revenue, interest_income, annual_report_url, watchListPKeys,
      isHalal, outstanding_no_share, isActive, isIPO, ipoLogo, ipoTimetableJson, assetProfileJSON,
      dailyPriceJson, isBSE, otherNonPermissibleIncome, nonPermissibleIncomeOperationSegment,
      lastUpdatedTime ->
    StockDbVo2022Table(
      name,
      nse_code,
      bse_code,
      industry,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListPKeys,
      isHalal,
      outstanding_no_share,
      isActive,
      isIPO,
      ipoLogo,
      ipoTimetableJson,
      assetProfileJSON,
      dailyPriceJson,
      isBSE,
      otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> getDailyPriceJson(nse_code: String,
      mapper: (dailyPriceJson: String?) -> T): Query<T> = GetDailyPriceJsonQuery(nse_code) {
      cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public override fun getDailyPriceJson(nse_code: String): Query<GetDailyPriceJson> =
      getDailyPriceJson(nse_code) { dailyPriceJson ->
    GetDailyPriceJson(
      dailyPriceJson
    )
  }

  public override fun searchIndustry(): Query<String> = Query(181001261, searchIndustry, driver,
      "Stock2022DataRoom.sq", "searchIndustry",
      "SELECT industry FROM StockDbVo2022Table GROUP BY industry") { cursor ->
    cursor.getString(0)!!
  }

  public override fun <T : Any> getStocksByIndustry(industry: String, mapper: (
    name: String,
    nse_code: String,
    bse_code: String,
    industry: String,
    isCoreActivityHalal: Long,
    businessDetail: String?,
    debt: Double,
    market_capitalization: Double,
    total_asset: Double,
    current_asset: Double,
    non_current_asset: Double,
    fixed_asset: Double,
    intangible_assets: Double,
    inventory: Double,
    total_liabilities: Double,
    current_liabilities: Double,
    non_current_liabilities: Double,
    total_revenue: Double,
    interest_income: Double,
    annual_report_url: String,
    watchListPKeys: String?,
    isHalal: Boolean,
    outstanding_no_share: Long,
    isActive: Boolean,
    isIPO: Boolean,
    ipoLogo: String?,
    ipoTimetableJson: String?,
    assetProfileJSON: String?,
    dailyPriceJson: String?,
    isBSE: Boolean,
    otherNonPermissibleIncome: Double?,
    nonPermissibleIncomeOperationSegment: Double?,
    lastUpdatedTime: Long
  ) -> T): Query<T> = GetStocksByIndustryQuery(industry) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getLong(4)!!,
      cursor.getString(5),
      cursor.getDouble(6)!!,
      cursor.getDouble(7)!!,
      cursor.getDouble(8)!!,
      cursor.getDouble(9)!!,
      cursor.getDouble(10)!!,
      cursor.getDouble(11)!!,
      cursor.getDouble(12)!!,
      cursor.getDouble(13)!!,
      cursor.getDouble(14)!!,
      cursor.getDouble(15)!!,
      cursor.getDouble(16)!!,
      cursor.getDouble(17)!!,
      cursor.getDouble(18)!!,
      cursor.getString(19)!!,
      cursor.getString(20),
      cursor.getLong(21)!! == 1L,
      cursor.getLong(22)!!,
      cursor.getLong(23)!! == 1L,
      cursor.getLong(24)!! == 1L,
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)!! == 1L,
      cursor.getDouble(30),
      cursor.getDouble(31),
      cursor.getLong(32)!!
    )
  }

  public override fun getStocksByIndustry(industry: String): Query<StockDbVo2022Table> =
      getStocksByIndustry(industry) { name, nse_code, bse_code, industry_, isCoreActivityHalal,
      businessDetail, debt, market_capitalization, total_asset, current_asset, non_current_asset,
      fixed_asset, intangible_assets, inventory, total_liabilities, current_liabilities,
      non_current_liabilities, total_revenue, interest_income, annual_report_url, watchListPKeys,
      isHalal, outstanding_no_share, isActive, isIPO, ipoLogo, ipoTimetableJson, assetProfileJSON,
      dailyPriceJson, isBSE, otherNonPermissibleIncome, nonPermissibleIncomeOperationSegment,
      lastUpdatedTime ->
    StockDbVo2022Table(
      name,
      nse_code,
      bse_code,
      industry_,
      isCoreActivityHalal,
      businessDetail,
      debt,
      market_capitalization,
      total_asset,
      current_asset,
      non_current_asset,
      fixed_asset,
      intangible_assets,
      inventory,
      total_liabilities,
      current_liabilities,
      non_current_liabilities,
      total_revenue,
      interest_income,
      annual_report_url,
      watchListPKeys,
      isHalal,
      outstanding_no_share,
      isActive,
      isIPO,
      ipoLogo,
      ipoTimetableJson,
      assetProfileJSON,
      dailyPriceJson,
      isBSE,
      otherNonPermissibleIncome,
      nonPermissibleIncomeOperationSegment,
      lastUpdatedTime
    )
  }

  public override fun insertStock(StockDbVo2022Table: StockDbVo2022Table): Unit {
    driver.execute(1059536054,
        """INSERT OR REPLACE INTO StockDbVo2022Table VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)""",
        33) {
      bindString(1, StockDbVo2022Table.name)
      bindString(2, StockDbVo2022Table.nse_code)
      bindString(3, StockDbVo2022Table.bse_code)
      bindString(4, StockDbVo2022Table.industry)
      bindLong(5, StockDbVo2022Table.isCoreActivityHalal)
      bindString(6, StockDbVo2022Table.businessDetail)
      bindDouble(7, StockDbVo2022Table.debt)
      bindDouble(8, StockDbVo2022Table.market_capitalization)
      bindDouble(9, StockDbVo2022Table.total_asset)
      bindDouble(10, StockDbVo2022Table.current_asset)
      bindDouble(11, StockDbVo2022Table.non_current_asset)
      bindDouble(12, StockDbVo2022Table.fixed_asset)
      bindDouble(13, StockDbVo2022Table.intangible_assets)
      bindDouble(14, StockDbVo2022Table.inventory)
      bindDouble(15, StockDbVo2022Table.total_liabilities)
      bindDouble(16, StockDbVo2022Table.current_liabilities)
      bindDouble(17, StockDbVo2022Table.non_current_liabilities)
      bindDouble(18, StockDbVo2022Table.total_revenue)
      bindDouble(19, StockDbVo2022Table.interest_income)
      bindString(20, StockDbVo2022Table.annual_report_url)
      bindString(21, StockDbVo2022Table.watchListPKeys)
      bindLong(22, if (StockDbVo2022Table.isHalal) 1L else 0L)
      bindLong(23, StockDbVo2022Table.outstanding_no_share)
      bindLong(24, if (StockDbVo2022Table.isActive) 1L else 0L)
      bindLong(25, if (StockDbVo2022Table.isIPO) 1L else 0L)
      bindString(26, StockDbVo2022Table.ipoLogo)
      bindString(27, StockDbVo2022Table.ipoTimetableJson)
      bindString(28, StockDbVo2022Table.assetProfileJSON)
      bindString(29, StockDbVo2022Table.dailyPriceJson)
      bindLong(30, if (StockDbVo2022Table.isBSE) 1L else 0L)
      bindDouble(31, StockDbVo2022Table.otherNonPermissibleIncome)
      bindDouble(32, StockDbVo2022Table.nonPermissibleIncomeOperationSegment)
      bindLong(33, StockDbVo2022Table.lastUpdatedTime)
    }
    notifyQueries(1059536054, {database.stock2022DataRoomQueries.getStockByNseCode +
        database.stock2022DataRoomQueries.selectMaxLastUpdatedTime +
        database.stock2022DataRoomQueries.getAllStockList +
        database.stock2022DataRoomQueries.getWatchlistByWatchlistPKey +
        database.stock2022DataRoomQueries.getDailyPriceJson +
        database.stock2022DataRoomQueries.getAllIPOsLiveData +
        database.stock2022DataRoomQueries.searchIndustry +
        database.stock2022DataRoomQueries.getWatchlistPKeysWatchlistByNseCode +
        database.stock2022DataRoomQueries.searchStockWithLimit +
        database.stock2022DataRoomQueries.getAllHalalStockList +
        database.stock2022DataRoomQueries.getStockByBseCode +
        database.stock2022DataRoomQueries.getRecentlyUpdatedStock +
        database.stock2022DataRoomQueries.searchStock +
        database.stock2022DataRoomQueries.getStockCounter +
        database.stock2022DataRoomQueries.getStocksByIndustry})
  }

  public override fun updateWatchlistByNseCode(watchListPKeys: String?, nse_code: String): Unit {
    driver.execute(-404460039,
        """UPDATE StockDbVo2022Table SET watchListPKeys=? WHERE nse_code = ?""", 2) {
      bindString(1, watchListPKeys)
      bindString(2, nse_code)
    }
    notifyQueries(-404460039, {database.stock2022DataRoomQueries.getStockByNseCode +
        database.stock2022DataRoomQueries.selectMaxLastUpdatedTime +
        database.stock2022DataRoomQueries.getAllStockList +
        database.stock2022DataRoomQueries.getWatchlistByWatchlistPKey +
        database.stock2022DataRoomQueries.getDailyPriceJson +
        database.stock2022DataRoomQueries.getAllIPOsLiveData +
        database.stock2022DataRoomQueries.searchIndustry +
        database.stock2022DataRoomQueries.getWatchlistPKeysWatchlistByNseCode +
        database.stock2022DataRoomQueries.searchStockWithLimit +
        database.stock2022DataRoomQueries.getAllHalalStockList +
        database.stock2022DataRoomQueries.getStockByBseCode +
        database.stock2022DataRoomQueries.getRecentlyUpdatedStock +
        database.stock2022DataRoomQueries.searchStock +
        database.stock2022DataRoomQueries.getStockCounter +
        database.stock2022DataRoomQueries.getStocksByIndustry})
  }

  public override fun setIsHalalStock(
    isHalal: Boolean,
    nse_code: String,
    bse_code: String
  ): Unit {
    driver.execute(-223645091,
        """UPDATE StockDbVo2022Table SET isHalal=? WHERE nse_code=? AND bse_code = ?""", 3) {
      bindLong(1, if (isHalal) 1L else 0L)
      bindString(2, nse_code)
      bindString(3, bse_code)
    }
    notifyQueries(-223645091, {database.stock2022DataRoomQueries.getStockByNseCode +
        database.stock2022DataRoomQueries.selectMaxLastUpdatedTime +
        database.stock2022DataRoomQueries.getAllStockList +
        database.stock2022DataRoomQueries.getWatchlistByWatchlistPKey +
        database.stock2022DataRoomQueries.getDailyPriceJson +
        database.stock2022DataRoomQueries.getAllIPOsLiveData +
        database.stock2022DataRoomQueries.searchIndustry +
        database.stock2022DataRoomQueries.getWatchlistPKeysWatchlistByNseCode +
        database.stock2022DataRoomQueries.searchStockWithLimit +
        database.stock2022DataRoomQueries.getAllHalalStockList +
        database.stock2022DataRoomQueries.getStockByBseCode +
        database.stock2022DataRoomQueries.getRecentlyUpdatedStock +
        database.stock2022DataRoomQueries.searchStock +
        database.stock2022DataRoomQueries.getStockCounter +
        database.stock2022DataRoomQueries.getStocksByIndustry})
  }

  public override fun updateDailyPriceJson(dailyPriceJson: String?, nse_code: String): Unit {
    driver.execute(1633156136,
        """UPDATE StockDbVo2022Table SET dailyPriceJson=? WHERE nse_code=?""", 2) {
      bindString(1, dailyPriceJson)
      bindString(2, nse_code)
    }
    notifyQueries(1633156136, {database.stock2022DataRoomQueries.getStockByNseCode +
        database.stock2022DataRoomQueries.selectMaxLastUpdatedTime +
        database.stock2022DataRoomQueries.getAllStockList +
        database.stock2022DataRoomQueries.getWatchlistByWatchlistPKey +
        database.stock2022DataRoomQueries.getDailyPriceJson +
        database.stock2022DataRoomQueries.getAllIPOsLiveData +
        database.stock2022DataRoomQueries.searchIndustry +
        database.stock2022DataRoomQueries.getWatchlistPKeysWatchlistByNseCode +
        database.stock2022DataRoomQueries.searchStockWithLimit +
        database.stock2022DataRoomQueries.getAllHalalStockList +
        database.stock2022DataRoomQueries.getStockByBseCode +
        database.stock2022DataRoomQueries.getRecentlyUpdatedStock +
        database.stock2022DataRoomQueries.searchStock +
        database.stock2022DataRoomQueries.getStockCounter +
        database.stock2022DataRoomQueries.getStocksByIndustry})
  }

  public override fun deleteAll(): Unit {
    driver.execute(-2118408433, """DELETE FROM StockDbVo2022Table""", 0)
    notifyQueries(-2118408433, {database.stock2022DataRoomQueries.getStockByNseCode +
        database.stock2022DataRoomQueries.selectMaxLastUpdatedTime +
        database.stock2022DataRoomQueries.getAllStockList +
        database.stock2022DataRoomQueries.getWatchlistByWatchlistPKey +
        database.stock2022DataRoomQueries.getDailyPriceJson +
        database.stock2022DataRoomQueries.getAllIPOsLiveData +
        database.stock2022DataRoomQueries.searchIndustry +
        database.stock2022DataRoomQueries.getWatchlistPKeysWatchlistByNseCode +
        database.stock2022DataRoomQueries.searchStockWithLimit +
        database.stock2022DataRoomQueries.getAllHalalStockList +
        database.stock2022DataRoomQueries.getStockByBseCode +
        database.stock2022DataRoomQueries.getRecentlyUpdatedStock +
        database.stock2022DataRoomQueries.searchStock +
        database.stock2022DataRoomQueries.getStockCounter +
        database.stock2022DataRoomQueries.getStocksByIndustry})
  }

  private inner class SearchStockQuery<out T : Any>(
    public val nse_code: String,
    public val name: String,
    public val bse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(searchStock, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(623449895,
        """SELECT * FROM StockDbVo2022Table WHERE nse_code LIKE ? OR name LIKE ? OR bse_code LIKE ?""",
        3) {
      bindString(1, nse_code)
      bindString(2, name)
      bindString(3, bse_code)
    }

    public override fun toString(): String = "Stock2022DataRoom.sq:searchStock"
  }

  private inner class SearchStockWithLimitQuery<out T : Any>(
    public val name: String,
    public val nse_code: String,
    public val bse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(searchStockWithLimit, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(370760494,
        """SELECT * FROM StockDbVo2022Table WHERE name LIKE ? OR nse_code LIKE ? OR bse_code LIKE ? LIMIT 10""",
        3) {
      bindString(1, name)
      bindString(2, nse_code)
      bindString(3, bse_code)
    }

    public override fun toString(): String = "Stock2022DataRoom.sq:searchStockWithLimit"
  }

  private inner class GetWatchlistPKeysWatchlistByNseCodeQuery<out T : Any>(
    public val nse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getWatchlistPKeysWatchlistByNseCode, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(325074895,
        """SELECT watchListPKeys FROM StockDbVo2022Table WHERE nse_code LIKE ?""", 1) {
      bindString(1, nse_code)
    }

    public override fun toString(): String =
        "Stock2022DataRoom.sq:getWatchlistPKeysWatchlistByNseCode"
  }

  private inner class GetWatchlistByWatchlistPKeyQuery<out T : Any>(
    public val watchListPKeys: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getWatchlistByWatchlistPKey, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1030923545,
        """SELECT * FROM StockDbVo2022Table WHERE watchListPKeys LIKE ?""", 1) {
      bindString(1, watchListPKeys)
    }

    public override fun toString(): String = "Stock2022DataRoom.sq:getWatchlistByWatchlistPKey"
  }

  private inner class GetStockByNseCodeQuery<out T : Any>(
    public val nse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getStockByNseCode, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1749717617,
        """SELECT * FROM StockDbVo2022Table WHERE nse_code=? LIMIT 1""", 1) {
      bindString(1, nse_code)
    }

    public override fun toString(): String = "Stock2022DataRoom.sq:getStockByNseCode"
  }

  private inner class GetStockByBseCodeQuery<out T : Any>(
    public val bse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getStockByBseCode, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(485140099,
        """SELECT * FROM StockDbVo2022Table WHERE bse_code=? LIMIT 1""", 1) {
      bindString(1, bse_code)
    }

    public override fun toString(): String = "Stock2022DataRoom.sq:getStockByBseCode"
  }

  private inner class GetDailyPriceJsonQuery<out T : Any>(
    public val nse_code: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getDailyPriceJson, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-814381369,
        """SELECT dailyPriceJson FROM StockDbVo2022Table WHERE nse_code=?""", 1) {
      bindString(1, nse_code)
    }

    public override fun toString(): String = "Stock2022DataRoom.sq:getDailyPriceJson"
  }

  private inner class GetStocksByIndustryQuery<out T : Any>(
    public val industry: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getStocksByIndustry, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1969233569,
        """SELECT * FROM StockDbVo2022Table WHERE industry LIKE ?""", 1) {
      bindString(1, industry)
    }

    public override fun toString(): String = "Stock2022DataRoom.sq:getStocksByIndustry"
  }
}

private class TrackingDataRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), TrackingDataRoomQueries {
  internal val selectAll: MutableList<Query<*>> = copyOnWriteList()

  internal val selectByNse: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectAll(trackingNamePKey: Long, mapper: (
    trackingNamePKey: Long,
    nseCode: String,
    callDate: Long,
    callPrice: Double,
    targetPrice: Double,
    stopLosePrice: Double,
    highestSinceCall: Double,
    highestOnDate: Long,
    lowestSinceCall: Double,
    lowestOnDate: Long,
    noteOnCall: String?
  ) -> T): Query<T> = SelectAllQuery(trackingNamePKey) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getDouble(3)!!,
      cursor.getDouble(4)!!,
      cursor.getDouble(5)!!,
      cursor.getDouble(6)!!,
      cursor.getLong(7)!!,
      cursor.getDouble(8)!!,
      cursor.getLong(9)!!,
      cursor.getString(10)
    )
  }

  public override fun selectAll(trackingNamePKey: Long): Query<TrackingDataTable> =
      selectAll(trackingNamePKey) { trackingNamePKey_, nseCode, callDate, callPrice, targetPrice,
      stopLosePrice, highestSinceCall, highestOnDate, lowestSinceCall, lowestOnDate, noteOnCall ->
    TrackingDataTable(
      trackingNamePKey_,
      nseCode,
      callDate,
      callPrice,
      targetPrice,
      stopLosePrice,
      highestSinceCall,
      highestOnDate,
      lowestSinceCall,
      lowestOnDate,
      noteOnCall
    )
  }

  public override fun <T : Any> selectByNse(nseCode: String, mapper: (
    trackingNamePKey: Long,
    nseCode: String,
    callDate: Long,
    callPrice: Double,
    targetPrice: Double,
    stopLosePrice: Double,
    highestSinceCall: Double,
    highestOnDate: Long,
    lowestSinceCall: Double,
    lowestOnDate: Long,
    noteOnCall: String?
  ) -> T): Query<T> = SelectByNseQuery(nseCode) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getDouble(3)!!,
      cursor.getDouble(4)!!,
      cursor.getDouble(5)!!,
      cursor.getDouble(6)!!,
      cursor.getLong(7)!!,
      cursor.getDouble(8)!!,
      cursor.getLong(9)!!,
      cursor.getString(10)
    )
  }

  public override fun selectByNse(nseCode: String): Query<TrackingDataTable> =
      selectByNse(nseCode) { trackingNamePKey, nseCode_, callDate, callPrice, targetPrice,
      stopLosePrice, highestSinceCall, highestOnDate, lowestSinceCall, lowestOnDate, noteOnCall ->
    TrackingDataTable(
      trackingNamePKey,
      nseCode_,
      callDate,
      callPrice,
      targetPrice,
      stopLosePrice,
      highestSinceCall,
      highestOnDate,
      lowestSinceCall,
      lowestOnDate,
      noteOnCall
    )
  }

  public override fun insert(TrackingDataTable: TrackingDataTable): Unit {
    driver.execute(-1906660957,
        """INSERT OR REPLACE INTO TrackingDataTable VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)""", 11)
        {
      bindLong(1, TrackingDataTable.trackingNamePKey)
      bindString(2, TrackingDataTable.nseCode)
      bindLong(3, TrackingDataTable.callDate)
      bindDouble(4, TrackingDataTable.callPrice)
      bindDouble(5, TrackingDataTable.targetPrice)
      bindDouble(6, TrackingDataTable.stopLosePrice)
      bindDouble(7, TrackingDataTable.highestSinceCall)
      bindLong(8, TrackingDataTable.highestOnDate)
      bindDouble(9, TrackingDataTable.lowestSinceCall)
      bindLong(10, TrackingDataTable.lowestOnDate)
      bindString(11, TrackingDataTable.noteOnCall)
    }
    notifyQueries(-1906660957, {database.trackingDataRoomQueries.selectAll +
        database.trackingDataRoomQueries.selectByNse})
  }

  public override fun update(
    callDate: Long,
    callPrice: Double,
    targetPrice: Double,
    stopLosePrice: Double,
    noteOnCall: String?,
    nseCode: String
  ): Unit {
    driver.execute(-1561714765,
        """UPDATE TrackingDataTable SET callDate=?,callPrice=?,targetPrice=?,stopLosePrice=?,noteOnCall=? WHERE nseCode=?""",
        6) {
      bindLong(1, callDate)
      bindDouble(2, callPrice)
      bindDouble(3, targetPrice)
      bindDouble(4, stopLosePrice)
      bindString(5, noteOnCall)
      bindString(6, nseCode)
    }
    notifyQueries(-1561714765, {database.trackingDataRoomQueries.selectAll +
        database.trackingDataRoomQueries.selectByNse})
  }

  public override fun delete(nseCode: String, trackingNamePKey: Long): Unit {
    driver.execute(-2058326891,
        """DELETE FROM TrackingDataTable WHERE nseCode=? AND trackingNamePKey=?""", 2) {
      bindString(1, nseCode)
      bindLong(2, trackingNamePKey)
    }
    notifyQueries(-2058326891, {database.trackingDataRoomQueries.selectAll +
        database.trackingDataRoomQueries.selectByNse})
  }

  private inner class SelectAllQuery<out T : Any>(
    public val trackingNamePKey: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectAll, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1725525029,
        """SELECT * FROM TrackingDataTable WHERE trackingNamePKey=?""", 1) {
      bindLong(1, trackingNamePKey)
    }

    public override fun toString(): String = "TrackingDataRoom.sq:selectAll"
  }

  private inner class SelectByNseQuery<out T : Any>(
    public val nseCode: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectByNse, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-370890973,
        """SELECT * FROM  TrackingDataTable WHERE nseCode = ?""", 1) {
      bindString(1, nseCode)
    }

    public override fun toString(): String = "TrackingDataRoom.sq:selectByNse"
  }
}

private class ScreenerDataRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), ScreenerDataRoomQueries {
  internal val getScreenerData: MutableList<Query<*>> = copyOnWriteList()

  internal val monitorScreenerData: MutableList<Query<*>> = copyOnWriteList()

  internal val selectMaxLastUpdatedTime: MutableList<Query<*>> = copyOnWriteList()

  internal val monitorScreenerDataByScanUrl: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllScreenerThatHaveNseCode: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getScreenerData(scan_url: String, mapper: (
    scan_name: String,
    scan_url: String,
    stocks: String,
    trigger_prices: String,
    triggered_at: String,
    screenerDetail: String?,
    isPrivate: Boolean,
    isPremium: Boolean,
    lastUpdatedTime: Long?
  ) -> T): Query<T> = GetScreenerDataQuery(scan_url) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getLong(6)!! == 1L,
      cursor.getLong(7)!! == 1L,
      cursor.getLong(8)
    )
  }

  public override fun getScreenerData(scan_url: String): Query<ScreenerDataTable> =
      getScreenerData(scan_url) { scan_name, scan_url_, stocks, trigger_prices, triggered_at,
      screenerDetail, isPrivate, isPremium, lastUpdatedTime ->
    ScreenerDataTable(
      scan_name,
      scan_url_,
      stocks,
      trigger_prices,
      triggered_at,
      screenerDetail,
      isPrivate,
      isPremium,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> monitorScreenerData(mapper: (
    scan_name: String,
    scan_url: String,
    stocks: String,
    trigger_prices: String,
    triggered_at: String,
    screenerDetail: String?,
    isPrivate: Boolean,
    isPremium: Boolean,
    lastUpdatedTime: Long?
  ) -> T): Query<T> = Query(1899553169, monitorScreenerData, driver, "ScreenerDataRoom.sq",
      "monitorScreenerData", "SELECT * FROM ScreenerDataTable") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getLong(6)!! == 1L,
      cursor.getLong(7)!! == 1L,
      cursor.getLong(8)
    )
  }

  public override fun monitorScreenerData(): Query<ScreenerDataTable> = monitorScreenerData {
      scan_name, scan_url, stocks, trigger_prices, triggered_at, screenerDetail, isPrivate,
      isPremium, lastUpdatedTime ->
    ScreenerDataTable(
      scan_name,
      scan_url,
      stocks,
      trigger_prices,
      triggered_at,
      screenerDetail,
      isPrivate,
      isPremium,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> selectMaxLastUpdatedTime(mapper: (MAX: Long?) -> T): Query<T> =
      Query(461131926, selectMaxLastUpdatedTime, driver, "ScreenerDataRoom.sq",
      "selectMaxLastUpdatedTime", "SELECT MAX(lastUpdatedTime) FROM ScreenerDataTable") { cursor ->
    mapper(
      cursor.getLong(0)
    )
  }

  public override fun selectMaxLastUpdatedTime():
      Query<com.halal.stocks.screenerDataRoom.SelectMaxLastUpdatedTime> = selectMaxLastUpdatedTime {
      MAX ->
    com.halal.stocks.screenerDataRoom.SelectMaxLastUpdatedTime(
      MAX
    )
  }

  public override fun <T : Any> monitorScreenerDataByScanUrl(scan_url: String, mapper: (
    scan_name: String,
    scan_url: String,
    stocks: String,
    trigger_prices: String,
    triggered_at: String,
    screenerDetail: String?,
    isPrivate: Boolean,
    isPremium: Boolean,
    lastUpdatedTime: Long?
  ) -> T): Query<T> = MonitorScreenerDataByScanUrlQuery(scan_url) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getLong(6)!! == 1L,
      cursor.getLong(7)!! == 1L,
      cursor.getLong(8)
    )
  }

  public override fun monitorScreenerDataByScanUrl(scan_url: String): Query<ScreenerDataTable> =
      monitorScreenerDataByScanUrl(scan_url) { scan_name, scan_url_, stocks, trigger_prices,
      triggered_at, screenerDetail, isPrivate, isPremium, lastUpdatedTime ->
    ScreenerDataTable(
      scan_name,
      scan_url_,
      stocks,
      trigger_prices,
      triggered_at,
      screenerDetail,
      isPrivate,
      isPremium,
      lastUpdatedTime
    )
  }

  public override fun <T : Any> getAllScreenerThatHaveNseCode(stocks: String, mapper: (
    scan_name: String,
    scan_url: String,
    stocks: String,
    trigger_prices: String,
    triggered_at: String,
    screenerDetail: String?,
    isPrivate: Boolean,
    isPremium: Boolean,
    lastUpdatedTime: Long?
  ) -> T): Query<T> = GetAllScreenerThatHaveNseCodeQuery(stocks) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getLong(6)!! == 1L,
      cursor.getLong(7)!! == 1L,
      cursor.getLong(8)
    )
  }

  public override fun getAllScreenerThatHaveNseCode(stocks: String): Query<ScreenerDataTable> =
      getAllScreenerThatHaveNseCode(stocks) { scan_name, scan_url, stocks_, trigger_prices,
      triggered_at, screenerDetail, isPrivate, isPremium, lastUpdatedTime ->
    ScreenerDataTable(
      scan_name,
      scan_url,
      stocks_,
      trigger_prices,
      triggered_at,
      screenerDetail,
      isPrivate,
      isPremium,
      lastUpdatedTime
    )
  }

  public override fun insertOrReplaceScreenerData(ScreenerDataTable: ScreenerDataTable): Unit {
    driver.execute(-192935985,
        """INSERT OR REPLACE INTO ScreenerDataTable VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?)""", 9) {
      bindString(1, ScreenerDataTable.scan_name)
      bindString(2, ScreenerDataTable.scan_url)
      bindString(3, ScreenerDataTable.stocks)
      bindString(4, ScreenerDataTable.trigger_prices)
      bindString(5, ScreenerDataTable.triggered_at)
      bindString(6, ScreenerDataTable.screenerDetail)
      bindLong(7, if (ScreenerDataTable.isPrivate) 1L else 0L)
      bindLong(8, if (ScreenerDataTable.isPremium) 1L else 0L)
      bindLong(9, ScreenerDataTable.lastUpdatedTime)
    }
    notifyQueries(-192935985, {database.screenerDataRoomQueries.getAllScreenerThatHaveNseCode +
        database.screenerDataRoomQueries.getScreenerData +
        database.screenerDataRoomQueries.selectMaxLastUpdatedTime +
        database.screenerDataRoomQueries.monitorScreenerData +
        database.screenerDataRoomQueries.monitorScreenerDataByScanUrl})
  }

  public override fun deleteAll(): Unit {
    driver.execute(1970855594, """DELETE FROM ScreenerDataTable""", 0)
    notifyQueries(1970855594, {database.screenerDataRoomQueries.getAllScreenerThatHaveNseCode +
        database.screenerDataRoomQueries.getScreenerData +
        database.screenerDataRoomQueries.selectMaxLastUpdatedTime +
        database.screenerDataRoomQueries.monitorScreenerData +
        database.screenerDataRoomQueries.monitorScreenerDataByScanUrl})
  }

  public override fun delete(scan_url: String): Unit {
    driver.execute(-560178313, """DELETE FROM ScreenerDataTable WHERE scan_url=?""", 1) {
      bindString(1, scan_url)
    }
    notifyQueries(-560178313, {database.screenerDataRoomQueries.getAllScreenerThatHaveNseCode +
        database.screenerDataRoomQueries.getScreenerData +
        database.screenerDataRoomQueries.selectMaxLastUpdatedTime +
        database.screenerDataRoomQueries.monitorScreenerData +
        database.screenerDataRoomQueries.monitorScreenerDataByScanUrl})
  }

  private inner class GetScreenerDataQuery<out T : Any>(
    public val scan_url: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getScreenerData, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-562457715,
        """SELECT * FROM ScreenerDataTable WHERE scan_url=?""", 1) {
      bindString(1, scan_url)
    }

    public override fun toString(): String = "ScreenerDataRoom.sq:getScreenerData"
  }

  private inner class MonitorScreenerDataByScanUrlQuery<out T : Any>(
    public val scan_url: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(monitorScreenerDataByScanUrl, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(2147440330,
        """SELECT * FROM ScreenerDataTable WHERE scan_url=?""", 1) {
      bindString(1, scan_url)
    }

    public override fun toString(): String = "ScreenerDataRoom.sq:monitorScreenerDataByScanUrl"
  }

  private inner class GetAllScreenerThatHaveNseCodeQuery<out T : Any>(
    public val stocks: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getAllScreenerThatHaveNseCode, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1538030322,
        """SELECT * FROM ScreenerDataTable WHERE stocks LIKE ?""", 1) {
      bindString(1, stocks)
    }

    public override fun toString(): String = "ScreenerDataRoom.sq:getAllScreenerThatHaveNseCode"
  }
}

private class WatchlisNameRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), WatchlisNameRoomQueries {
  internal val selectAll: MutableList<Query<*>> = copyOnWriteList()

  internal val selectByName: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectAll(mapper: (pKey: Long, watchlistName: String) -> T):
      Query<T> = Query(-1953366934, selectAll, driver, "WatchlisNameRoom.sq", "selectAll",
      "SELECT * FROM WatchlistNameTable") { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!
    )
  }

  public override fun selectAll(): Query<WatchlistNameTable> = selectAll { pKey, watchlistName ->
    WatchlistNameTable(
      pKey,
      watchlistName
    )
  }

  public override fun <T : Any> selectByName(watchlistName: String, mapper: (pKey: Long,
      watchlistName: String) -> T): Query<T> = SelectByNameQuery(watchlistName) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!
    )
  }

  public override fun selectByName(watchlistName: String): Query<WatchlistNameTable> =
      selectByName(watchlistName) { pKey, watchlistName_ ->
    WatchlistNameTable(
      pKey,
      watchlistName_
    )
  }

  public override fun insert(watchlistName: String): Unit {
    driver.execute(452961140,
        """INSERT OR REPLACE INTO WatchlistNameTable(watchlistName) VALUES(?)""", 1) {
      bindString(1, watchlistName)
    }
    notifyQueries(452961140, {database.watchlisNameRoomQueries.selectAll +
        database.watchlisNameRoomQueries.selectByName})
  }

  public override fun insertFromServer(pKey: Long?, watchlistName: String): Unit {
    driver.execute(-1269822943,
        """INSERT OR REPLACE INTO WatchlistNameTable(pKey,watchlistName) VALUES(?,?)""", 2) {
      bindLong(1, pKey)
      bindString(2, watchlistName)
    }
    notifyQueries(-1269822943, {database.watchlisNameRoomQueries.selectAll +
        database.watchlisNameRoomQueries.selectByName})
  }

  public override fun delete(pKey: Long): Unit {
    driver.execute(301295206, """DELETE FROM WatchlistNameTable WHERE pKey=?""", 1) {
      bindLong(1, pKey)
    }
    notifyQueries(301295206, {database.watchlisNameRoomQueries.selectAll +
        database.watchlisNameRoomQueries.selectByName})
  }

  public override fun update(watchlistName: String, pKey: Long): Unit {
    driver.execute(797907332, """UPDATE WatchlistNameTable SET watchlistName=? WHERE pKey=?""", 2) {
      bindString(1, watchlistName)
      bindLong(2, pKey)
    }
    notifyQueries(797907332, {database.watchlisNameRoomQueries.selectAll +
        database.watchlisNameRoomQueries.selectByName})
  }

  private inner class SelectByNameQuery<out T : Any>(
    public val watchlistName: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectByName, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-202599399,
        """SELECT * FROM WatchlistNameTable WHERE watchlistName=?""", 1) {
      bindString(1, watchlistName)
    }

    public override fun toString(): String = "WatchlisNameRoom.sq:selectByName"
  }
}

private class PortfolioDataRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), PortfolioDataRoomQueries {
  internal val selectAllPortfolio: MutableList<Query<*>> = copyOnWriteList()

  internal val selectByName: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectAllPortfolio(mapper: (portfolioPKey: Long,
      portfolioName: String) -> T): Query<T> = Query(-1361969666, selectAllPortfolio, driver,
      "PortfolioDataRoom.sq", "selectAllPortfolio", "SELECT * FROM PortfolioDataTable") { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!
    )
  }

  public override fun selectAllPortfolio(): Query<PortfolioDataTable> = selectAllPortfolio {
      portfolioPKey, portfolioName ->
    PortfolioDataTable(
      portfolioPKey,
      portfolioName
    )
  }

  public override fun <T : Any> selectByName(portfolioName: String, mapper: (portfolioPKey: Long,
      portfolioName: String) -> T): Query<T> = SelectByNameQuery(portfolioName) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1)!!
    )
  }

  public override fun selectByName(portfolioName: String): Query<PortfolioDataTable> =
      selectByName(portfolioName) { portfolioPKey, portfolioName_ ->
    PortfolioDataTable(
      portfolioPKey,
      portfolioName_
    )
  }

  public override fun insert(portfolioName: String): Unit {
    driver.execute(-1937956492,
        """INSERT OR REPLACE INTO PortfolioDataTable(portfolioName) VALUES(?)""", 1) {
      bindString(1, portfolioName)
    }
    notifyQueries(-1937956492, {database.portfolioDataRoomQueries.selectAllPortfolio +
        database.portfolioDataRoomQueries.selectByName})
  }

  public override fun insertFromServer(portfolioPKey: Long?, portfolioName: String): Unit {
    driver.execute(-1115158495,
        """INSERT OR REPLACE INTO PortfolioDataTable(portfolioPKey,portfolioName) VALUES(?,?)""", 2)
        {
      bindLong(1, portfolioPKey)
      bindString(2, portfolioName)
    }
    notifyQueries(-1115158495, {database.portfolioDataRoomQueries.selectAllPortfolio +
        database.portfolioDataRoomQueries.selectByName})
  }

  public override fun update(portfolioName: String, portfolioPKey: Long): Unit {
    driver.execute(-1593010300,
        """UPDATE PortfolioDataTable SET portfolioName=? WHERE portfolioPKey=?""", 2) {
      bindString(1, portfolioName)
      bindLong(2, portfolioPKey)
    }
    notifyQueries(-1593010300, {database.portfolioDataRoomQueries.selectAllPortfolio +
        database.portfolioDataRoomQueries.selectByName})
  }

  public override fun delete(portfolioPKey: Long): Unit {
    driver.execute(-2089622426, """DELETE FROM PortfolioDataTable WHERE portfolioPKey=?""", 1) {
      bindLong(1, portfolioPKey)
    }
    notifyQueries(-2089622426, {database.portfolioDataRoomQueries.selectAllPortfolio +
        database.portfolioDataRoomQueries.selectByName})
  }

  private inner class SelectByNameQuery<out T : Any>(
    public val portfolioName: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(selectByName, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-455896551,
        """SELECT * FROM PortfolioDataTable WHERE portfolioName=?""", 1) {
      bindString(1, portfolioName)
    }

    public override fun toString(): String = "PortfolioDataRoom.sq:selectByName"
  }
}

private class UserDataRoomQueriesImpl(
  private val database: IslamicStockVN2Impl,
  private val driver: SqlDriver
) : TransacterImpl(driver), UserDataRoomQueries {
  internal val isRegistered: MutableList<Query<*>> = copyOnWriteList()

  internal val getSavedUser: MutableList<Query<*>> = copyOnWriteList()

  internal val getLoggedInUserId: MutableList<Query<*>> = copyOnWriteList()

  public override fun isRegistered(): Query<Long> = Query(-2090820662, isRegistered, driver,
      "UserDataRoom.sq", "isRegistered", "SELECT COUNT(*) FROM UserTable") { cursor ->
    cursor.getLong(0)!!
  }

  public override fun <T : Any> getSavedUser(mapper: (
    id: String?,
    username: String?,
    email: String,
    profilePic: String?,
    createDateTime: Long,
    role: String,
    loginType: String
  ) -> T): Query<T> = Query(2081039546, getSavedUser, driver, "UserDataRoom.sq", "getSavedUser",
      "SELECT * FROM UserTable") { cursor ->
    mapper(
      cursor.getString(0),
      cursor.getString(1),
      cursor.getString(2)!!,
      cursor.getString(3),
      cursor.getLong(4)!!,
      cursor.getString(5)!!,
      cursor.getString(6)!!
    )
  }

  public override fun getSavedUser(): Query<UserTable> = getSavedUser { id, username, email,
      profilePic, createDateTime, role, loginType ->
    UserTable(
      id,
      username,
      email,
      profilePic,
      createDateTime,
      role,
      loginType
    )
  }

  public override fun <T : Any> getLoggedInUserId(mapper: (id: String?) -> T): Query<T> =
      Query(84318885, getLoggedInUserId, driver, "UserDataRoom.sq", "getLoggedInUserId",
      "SELECT id FROM UserTable LIMIT 1") { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public override fun getLoggedInUserId(): Query<GetLoggedInUserId> = getLoggedInUserId { id ->
    GetLoggedInUserId(
      id
    )
  }

  public override fun loginUserSuccess(UserTable: UserTable): Unit {
    driver.execute(-1019846643, """INSERT OR REPLACE INTO UserTable VALUES (?, ?, ?, ?, ?, ?, ?)""",
        7) {
      bindString(1, UserTable.id)
      bindString(2, UserTable.username)
      bindString(3, UserTable.email)
      bindString(4, UserTable.profilePic)
      bindLong(5, UserTable.createDateTime)
      bindString(6, UserTable.role)
      bindString(7, UserTable.loginType)
    }
    notifyQueries(-1019846643, {database.userDataRoomQueries.isRegistered +
        database.userDataRoomQueries.getLoggedInUserId + database.userDataRoomQueries.getSavedUser})
  }

  public override fun logoutUser(): Unit {
    driver.execute(1018374259, """DELETE FROM UserTable""", 0)
    notifyQueries(1018374259, {database.userDataRoomQueries.isRegistered +
        database.userDataRoomQueries.getLoggedInUserId + database.userDataRoomQueries.getSavedUser})
  }
}
