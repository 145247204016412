package com.halal.stocks

import kotlin.Double
import kotlin.Long
import kotlin.String

public data class RealtimePriceTable(
  public val nse_code: String,
  public val lastTradePrice: Double,
  public val todayHigh: Double,
  public val todayLow: Double,
  public val todayPercentChange: Double,
  public val todayPriceChange: Double,
  public val previousClose: Double,
  public val marketLtpTime: Long,
  public val price7DaysAgo: Double,
  public val price1MonthAgo: Double,
  public val price3MonthsAgo: Double,
  public val price6MonthAgo: Double,
  public val price1YearAgo: Double
) {
  public override fun toString(): String = """
  |RealtimePriceTable [
  |  nse_code: $nse_code
  |  lastTradePrice: $lastTradePrice
  |  todayHigh: $todayHigh
  |  todayLow: $todayLow
  |  todayPercentChange: $todayPercentChange
  |  todayPriceChange: $todayPriceChange
  |  previousClose: $previousClose
  |  marketLtpTime: $marketLtpTime
  |  price7DaysAgo: $price7DaysAgo
  |  price1MonthAgo: $price1MonthAgo
  |  price3MonthsAgo: $price3MonthsAgo
  |  price6MonthAgo: $price6MonthAgo
  |  price1YearAgo: $price1YearAgo
  |]
  """.trimMargin()
}
