package com.halal.stocks

import kotlin.Long
import kotlin.String

public data class WatchlistNameTable(
  public val pKey: Long,
  public val watchlistName: String
) {
  public override fun toString(): String = """
  |WatchlistNameTable [
  |  pKey: $pKey
  |  watchlistName: $watchlistName
  |]
  """.trimMargin()
}
