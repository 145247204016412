package com.halal.stocks.data.model

import com.halal.stocks.data.api.priceupdate.data.RealtimePriceVo
import kotlinx.datetime.Clock
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import our.libs.parcel.Parcelable
import our.libs.parcel.Parcelize


@Serializable
@Parcelize
data class DailyUpdateModelApp(
    @SerialName("regularMarketPrice")
    val regularMarketPrice:Double?,
    @SerialName("regularMarketDayHigh")
    val regularMarketDayHigh:Double?,
    @SerialName("regularMarketDayLow")
    val regularMarketDayLow:Double?,
    @SerialName("regularMarketChangePercent")
    val regularMarketChangePercent:Double?,
    @SerialName("regularMarketPreviousClose")
    val regularMarketPreviousClose:Double?,
    @SerialName("regularMarketChange")
    val regularMarketChange:Double?,
    @SerialName("regularMarketTime")
    val regularMarketTime:Long?,
    @SerialName("symbol")
    val symbol:String?
):Parcelable

fun DailyUpdateModelApp.toRealtimeVo() = RealtimePriceVo(
    nse_code = symbol?.replace(".BO","")?.replace(".NS","")?:"",
    lastTradePrice = regularMarketPrice?:0.0,
    todayHigh = regularMarketDayHigh?:0.0,
    todayLow = regularMarketDayLow?:0.0,
    todayPercentChange = regularMarketChangePercent?:0.0,
    todayPriceChange = regularMarketChange?:0.0,
    previousClose = regularMarketPreviousClose?:0.0,
    marketLtpTime = regularMarketTime?: Clock.System.now().toEpochMilliseconds(),
    price7DaysAgo = 0.0,
    price1MonthAgo = 0.0,
    price3MonthsAgo = 0.0,
    price6MonthAgo = 0.0,
    price1YearAgo = 0.0
)

//fun DailyUpdateModel?.marketCapValueString() = "MarketCap : ${this?.quoteSummary?.result?.get(0)?.price?.marketCap?.fmt}"
//fun DailyUpdateModel?.marketCapRawValue() : Double = this?.quoteSummary?.result?.get(0)?.price?.marketCap?.raw?.toDouble()?:0.0
//
///*
//"regularMarketChange": {
//    "fmt": "-1.05",
//    "raw": -1.0499992
//},*/
//fun DailyUpdateModel?.regularMarketPriceChange() : DailyUpdateModel.QuoteSummary.Result.Price.RegularMarketChange? = this?.quoteSummary?.result?.get(0)?.price?.regularMarketChange
//fun DailyUpdateModel?.regularMarketPrice() : DailyUpdateModel.QuoteSummary.Result.Price.RegularMarketPrice? = this?.quoteSummary?.result?.get(0)?.price?.regularMarketPrice
//fun DailyUpdateModel?.regularMarketPrevClosePrice() : DailyUpdateModel.QuoteSummary.Result.Price.RegularMarketPreviousClose? = this?.quoteSummary?.result?.get(0)?.price?.regularMarketPreviousClose
//
///*"regularMarketChangePercent": {
//    "fmt": "-5.12%",
//    "raw": -0.051219475
//},*/
//fun DailyUpdateModel?.regularMarketChangePercent() : Double = this?.quoteSummary?.result?.get(0)?.price?.regularMarketChangePercent?.raw ?:0.0
//fun DailyUpdateModel?.regularMarketChangePercentString() : String = this?.quoteSummary?.result?.get(0)?.price?.regularMarketChangePercent?.fmt ?:""
//
//fun DailyUpdateModel?.regularMarketDayHigh() : DailyUpdateModel.QuoteSummary.Result.Price.RegularMarketDayHigh? = this?.quoteSummary?.result?.get(0)?.price?.regularMarketDayHigh
//
//fun DailyUpdateModel?.regularMarketDayLow() : DailyUpdateModel.QuoteSummary.Result.Price.RegularMarketDayLow? = this?.quoteSummary?.result?.get(0)?.price?.regularMarketDayLow
//fun DailyUpdateModel?.regularMarketOpen() : Double = this?.quoteSummary?.result?.get(0)?.price?.regularMarketOpen?.raw ?:0.0
//fun DailyUpdateModel?.regularMarketVolume() : String = this?.quoteSummary?.result?.get(0)?.price?.regularMarketVolume?.fmt?:""
//
//fun DailyUpdateModel?.lastUpdatedDateTime() : String = ((this?.quoteSummary?.result?.get(0)?.price?.regularMarketTime?.toLong()?:0)*1000L).getRelativeTimeSpanString()
//fun DailyUpdateModel?.getDailyUpdatedTime() : Long = ((this?.quoteSummary?.result?.get(0)?.price?.regularMarketTime?.toLong()?:0)*1000L)
