package com.halal.stocks.data.room.calltracking

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import com.halal.stocks.TrackingNameTable
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.model.AutoCompleteStockModel
import com.halal.stocks.data.model.mapping.RecommendationModel
import com.halal.stocks.ui.asCommonFlow
import kotlinx.coroutines.flow.MutableStateFlow

class CallTrackingViewModel(private val trackingRepo: TrackingRepo,private val stockDoa2022:Stock2022DatabaseRepo) : ViewModel() {

    suspend fun getAllStockForAutoComplete() = stockDoa2022.getAllStockList().map { AutoCompleteStockModel(it.bseCode,it.nseCode,it.name) }

    var selectedNameLiveData : MutableStateFlow<TrackingNameTable> = MutableStateFlow(TrackingNameTable(0L,""))

    fun getNamesFlow() =  trackingRepo.getNamesFlow().asCommonFlow()
    fun updateName(enterText: String, trackingNameTable: TrackingNameTable): Boolean  = trackingRepo.updateName(
        trackingNameTable,enterText
    )

    fun create(enterText: String): Boolean = trackingRepo.create(enterText)
    fun getRecommendationList(nameData: TrackingNameTable) = trackingRepo.getRecommendationList(nameData).asCommonFlow()
    fun deleteRecommendation(recommendationModel: RecommendationModel) {
        trackingRepo.deleteRecommendation(recommendationModel)
    }

    fun deleteCallName(trackingNameTable: TrackingNameTable){
        trackingRepo.deleteCallName(trackingNameTable)
    }
}