package com.halal.stocks.ui.search

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import com.halal.stocks.GetLoggedInUserId
import com.halal.stocks.data.model.FinancialResultType
import com.halal.stocks.data.model.mapping.StockDataVo
import com.halal.stocks.data.model.mapping.bseCode
import com.halal.stocks.data.model.mapping.nseCode
import com.halal.stocks.data.api.stocks.Stock2021DatabaseRepo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.api.user.UserAccountSyncRepo
import com.halal.stocks.data.api.user.UserRepo
import com.halal.stocks.ui.asCommonFlow
import com.halal.stocks.utils.RConfig
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.launch

class SearchViewModel(
    private val Stock2022DatabaseRepo: Stock2022DatabaseRepo,
    private val stockDao2021: Stock2021DatabaseRepo,
    private val rConfig: RConfig,
    private val userDatSyncRepo: UserAccountSyncRepo,
    private val userRepo: UserRepo
) : ViewModel() {

    suspend fun isRegistered() = userRepo.isRegistered()

    fun searchStockFlow(text: String) = flow {
        val data:List<StockDataVo> = when(rConfig.getDefaultFinancialResultType()){
            FinancialResultType.YEAR_2021 -> stockDao2021.searchStock(text)
            FinancialResultType.YEAR_2022 -> Stock2022DatabaseRepo.searchStock(text)
        }.sortedBy { !it.nseCode().startsWith(text,true) || it.bseCode().startsWith(text,true)}
        emit(data)
    }.flowOn(Dispatchers.Default).asCommonFlow()

    fun searchStockFlowWithLimit(text: String) = flow {
        val data:List<StockDataVo> = when(rConfig.getDefaultFinancialResultType()){
            FinancialResultType.YEAR_2021 -> stockDao2021.searchStockWithLimit(text)
            FinancialResultType.YEAR_2022 -> Stock2022DatabaseRepo.searchStockWithLimit(text)
        }.sortedBy { !it.nseCode().startsWith(text,true) || it.bseCode().startsWith(text,true)}
        emit(data)
    }.flowOn(Dispatchers.Default).asCommonFlow()

    fun getRecentlyUpdatedStock() = Stock2022DatabaseRepo.getRecentlyUpdatedStock().flowOn(Dispatchers.Default).asCommonFlow()

    fun monitorAccountSyncAvailability() = userDatSyncRepo.isAccountSyncAvailable
    fun syncPendingUserChangesToServer() = viewModelScope.launch(Dispatchers.Default) { userDatSyncRepo.syncPendingUserChangesToServer(userRepo.getLoggedInUserId().toString()) }
}