package ui

import csstype.Color
import csstype.None.none
import emotion.react.css
import mui.material.Drawer
import mui.material.DrawerAnchor.left
import mui.material.DrawerVariant.permanent
import mui.material.List
import mui.material.ListItemButton
import mui.material.ListItemText
import mui.material.Toolbar
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.nav
import react.router.dom.NavLink
import react.router.useLocation
import react.useContext
import ui.base.ScreenPageListContext
import ui.utils.Area
import ui.utils.Sizes

val Sidebar = FC<Props> {
    val showcases = useContext(ScreenPageListContext)
    val lastPathname = useLocation().pathname.substringAfterLast("/")

    Box {
        component = nav
        sx { gridArea = Area.Sidebar }

        Drawer {
            variant = permanent
            anchor = left

            Box {
                Toolbar()

                List {
                    sx { width = Sizes.Sidebar.Width }
                    for ((key, name) in showcases) {
                        NavLink {
                            to = key

                            css {
                                textDecoration = none
                                color = Color.currentcolor
                            }

                            ListItemButton {
                                selected = lastPathname == key

                                ListItemText {
                                    primary = ReactNode(name)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}