package com.halal.stocks.data.api.user

import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.model.mapping.UserVo
import com.halal.stocks.data.model.mapping.toUserTable
import com.halal.stocks.data.model.mapping.toUserVo
import com.squareup.sqldelight.runtime.coroutines.asFlow
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map

class UserDatabaseRepo(private val database: DatabaseFactory)  {

    fun loginUserSuccess(userVo: UserVo) {
         database.appDatabase.getUserDao?.loginUserSuccess(userVo.toUserTable())
    }

    fun logoutUser() {
        database.appDatabase.getUserDao?.logoutUser()
    }

    fun isRegistered(): Int {
        return database.appDatabase.getUserDao?.isRegistered()?.executeAsOneOrNull()?.toInt()?:-1
    }

    fun getSavedUser(): UserVo? {
       return database.appDatabase.getUserDao?.getSavedUser()?.executeAsOneOrNull()?.toUserVo()
    }

    fun getUserAsFlow(): Flow<UserVo?>? {
        return database.appDatabase.getUserDao?.getSavedUser()?.asFlow()?.map { it.executeAsOneOrNull()?.toUserVo() }
    }

    fun getLoggedInUserId(): String? {
        return database.appDatabase.getUserDao?.getLoggedInUserId()?.executeAsOneOrNull()?.id
    }
}