package ui.base

import ApplicationProps
import react.FC

data class ScreensPage(
    val key: String,
    val name: String,
    val Component: FC<ApplicationProps>,
)

typealias ScreenPageList = Iterable<ScreensPage>