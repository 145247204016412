package com.halal.stocks

import kotlin.Long
import kotlin.String

public data class TrackingNameTable(
  public val trackingNamePKey: Long,
  public val name: String
) {
  public override fun toString(): String = """
  |TrackingNameTable [
  |  trackingNamePKey: $trackingNamePKey
  |  name: $name
  |]
  """.trimMargin()
}
