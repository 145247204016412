package com.halal.stocks.data.api.screener.screener

import com.halal.stocks.data.api.IUseCase

class ScreenerSyncAfterLoginUseCase(
    private val screenerDatabaseRepo: ScreenerDatabaseRepo,
    private val screenerNetworkRepo: ScreenerNetworkRepo
) : IUseCase {

    override suspend fun execute(loggedInUserId: String): Boolean {
        return try {
           val serverData = screenerNetworkRepo.getAllScreeners(0)
            if (serverData!=null)
                screenerDatabaseRepo.insertOrReplaceScreenerData(serverData)
            true
        } catch (ex: Exception) {
            ex.printStackTrace()
            false
        }
    }

}