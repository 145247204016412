package com.halal.stocks.data.room.calltracking.usecases

import com.halal.stocks.TrackingDataTable
import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.api.*
import com.halal.stocks.data.api.user.auth.UserAuthRepo
import com.halal.stocks.data.room.portfolio.usecases.PortfolioServerSyncVo
import com.halal.stocks.data.room.portfolio.usecases.makeJsonUseAble
import com.halal.stocks.utils.objectify
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class GetTrackingDataFromServerUseCase(
    private val database: DatabaseFactory,
    private val apiHttpClient: ApiHttpClient
) : IUseCase {

    override suspend fun execute(loggedInUserId: String): Boolean {
        return try {

            val serverData = apiHttpClient.get<String?>("/getAllTrackingJson")?.makeJsonUseAble()

            if (serverData!=null && serverData.isNotEmpty()) {
                val dataServer = serverData.objectify<List<TrackingServerSyncVo>>()
                dataServer.forEach { data->
                    database.appDatabase.trackingNameRoomQueries.insertFromServer(data.nameTable.trackingNamePKey,data.nameTable.name)
                    data.listOfData.forEach { trackingData->
                        database.appDatabase.trackingDataRoomQueries.insert(TrackingDataTable(
                            trackingNamePKey = trackingData.trackingNamePKey,
                            nseCode = trackingData.nseCode,
                            callDate = trackingData.callDate,
                            callPrice =trackingData.callPrice,
                            targetPrice = trackingData.targetPrice,
                            stopLosePrice = trackingData.stopLosePrice,
                            highestSinceCall = trackingData.highestSinceCall,
                            highestOnDate = trackingData.highestOnDate,
                            lowestSinceCall = trackingData.lowestSinceCall,
                            lowestOnDate = trackingData.lowestOnDate,
                            noteOnCall = trackingData.noteOnCall
                        ))
                    }
                }
            }
            true
        } catch (ex: Exception) {
            ex.printStackTrace()
            false
        }
    }

}