package com.halal.stocks

import kotlin.Long
import kotlin.String

public data class GetStockCounter(
  public val totalHalalStocks: Long,
  public val totalStocks: Long
) {
  public override fun toString(): String = """
  |GetStockCounter [
  |  totalHalalStocks: $totalHalalStocks
  |  totalStocks: $totalStocks
  |]
  """.trimMargin()
}
