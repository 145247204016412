package ui.pages

import ApplicationProps
import com.halal.stocks.IslamicStockSDK
import com.halal.stocks.data.model.getIPOTimeTable
import com.halal.stocks.data.model.mapping.StockDataVo
import com.halal.stocks.data.model.mapping.ipoLogo
import com.halal.stocks.data.model.mapping.name
import csstype.pc
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import mainScope
import mui.material.Avatar
import mui.material.Box
import mui.material.Divider
import mui.material.DividerVariant
import mui.material.List
import mui.material.ListItem
import mui.material.ListItemAlignItems
import mui.material.ListItemAvatar
import mui.material.ListItemText
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Fragment
import react.ReactNode
import react.create
import react.dom.html.ReactHTML.span
import react.router.useNavigate
import react.useEffectOnce
import react.useState

val IPOListPage = FC<ApplicationProps> { props ->

    val navigate = useNavigate()

    val (listOfIpo, setListOfIpo) = useState<List<StockDataVo>>()

    useEffectOnce {

        // if data not downloaded, navigate to download page
        if (!props.isDownloaded) {
            navigate("/download")
        } else {
            val sdk = IslamicStockSDK()

            val viewModel = sdk.ipoViewModel

            mainScope.launch {
                viewModel.fetchAllIpoStocksFlow().collectLatest {
                    setListOfIpo(it)
                }
            }
        }
    }

    Box {
        List {
            sx {
                width = 100.pc
            }

            listOfIpo?.forEach { ipoItem ->
                ListItem {
                    alignItems = ListItemAlignItems.flexStart

                    ListItemAvatar {
                        Avatar {
                            alt = ipoItem.name()
                            src = ipoItem.ipoLogo()
                        }
                    }

                    val ipoTimeTable = ipoItem.getIPOTimeTable()

                    ListItemText {
                        primary = ReactNode(ipoItem.name())
                        secondary = Fragment.create {
                            Typography {
                                component = span
                                variant = TypographyVariant.body2
                                +"Opening Date ${ipoTimeTable?.ipoOpenDate}"
                            }
                            Typography {
                                component = span
                                variant = TypographyVariant.body2
                                +"Closing Date ${ipoTimeTable?.ipoClosingDate}"
                            }
                            Typography {
                                component = span
                                variant = TypographyVariant.body2
                                +"Listing Date ${ipoTimeTable?.listingDate}"
                            }
                        }
                    }
                }

                Divider {
                    variant = DividerVariant.inset
                }
            }
        }

        /*Stack {
            direction = responsive(StackDirection.column)

            listOfIpo?.forEach { ipoItem ->
                Stack {
                    direction = responsive(StackDirection.row)
                    img {
                        src = ipoItem.ipoLogo()
                        width = 100.0
                    }

                    Stack {
                        direction = responsive(StackDirection.column)
                        Typography {
                            variant = TypographyVariant.h6
                            noWrap = false
                            component = ReactHTML.div
                            +ipoItem.name()
                        }
                        val ipoTimeTable = ipoItem.getIPOTimeTable()
                        Typography {
                            variant = TypographyVariant.body1
                            noWrap = false
                            component = ReactHTML.div
                            +"Opening Date ${ipoTimeTable?.ipoOpenDate} "
                            +"Closing Date ${ipoTimeTable?.ipoClosingDate} \n"
                            +"Closing Date ${ipoTimeTable?.allotmentDate} \n"
                        }
                    }
                }
            }
        }*/
    }
}
