package com.halal.stocks.ui.profile

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import com.halal.stocks.data.api.basket.BasketRepo
import com.halal.stocks.data.api.user.UserRepo
import com.halal.stocks.data.api.basket.basket.BasketVo
import com.halal.stocks.ui.asCommonFlow
import com.halal.stocks.utils.LoadingStates
import kotlinx.coroutines.flow.MutableStateFlow

class MyBasketViewModel(private val basketRepo: BasketRepo, private val userRepo: UserRepo) : ViewModel() {

    val basketListLiveData = MutableStateFlow<List<BasketVo>>(emptyList())
    val monitorLoadingState = MutableStateFlow<LoadingStates>(LoadingStates.HIDE_LOADING)

    fun monitorUserRepo() = basketRepo.monitorAllBasketOfUser().asCommonFlow()

   /* init {
        fetchUserRepo()
    }*/

/*    fun fetchUserRepo(){
        viewModelScope.launch(Main) {
            monitorLoadingState.value = LoadingStates.SHOW_LOADING
            withContext(Dispatchers.Default) {
                try {
                    val result =  basketRepo.getAllBasketsOfUser()
                    if (result.isNullOrEmpty()) {
                        basketRepo.getAllBaskets()
                        basketRepo.getAllBasketsOfUser()
                    }
                    result
                }catch (ex:Exception){
                    emptyList<BasketVo>()
                }
            }?.let {
                basketListLiveData.value = it
                monitorLoadingState.value = LoadingStates.HIDE_LOADING
            }?: kotlin.run {
                monitorLoadingState.value = LoadingStates.LOADING_FAILED
            }
        }
    }*/
}