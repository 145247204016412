package com.halal.stocks.ui.downloads

import co.touchlab.kermit.Logger
import dev.icerock.moko.mvvm.viewmodel.ViewModel
import kotlinx.coroutines.Dispatchers
import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.priceupdate.RealTimePriceDataRepo
import com.halal.stocks.data.api.remoteconfig.RemoteConfigRepo
import com.halal.stocks.data.api.stocks.StockNetworkRepo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.api.user.UserRepo
import com.halal.stocks.ui.CommonFlow
import com.halal.stocks.ui.asCommonFlow
import com.halal.stocks.utils.RConfig
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch

enum class DownLoadStepAndState{
    SHOW_LOADING,
    CSV_DOWNLOADED,
    CSV_2021_INSERTED
}

class DownloadingFragmentViewModel(
    private val remoteConfigRepo: RemoteConfigRepo,
    private val sharedPrefStorageRepo: SharedPrefStorageRepo,
    private val realTimePriceDataRepo: RealTimePriceDataRepo,
    private val download2021Repo: Download2021Repo,
    private val download2022Repo: Download2022Repo)  : ViewModel() {

    private val _downloadFileLiveData = MutableStateFlow(DownLoadStepAndState.SHOW_LOADING)
    val downloadFileLiveData: CommonFlow<DownLoadStepAndState> = _downloadFileLiveData.asStateFlow().asCommonFlow()

    fun handleStockDataDownload(){

        Logger.i {"handleStockDataDownload"}

        viewModelScope.launch(Dispatchers.Default){
            Logger.i {"inside viewModel launch ${remoteConfigRepo.getDatabaseUrl()}"}
            _downloadFileLiveData.emit(DownLoadStepAndState.SHOW_LOADING)

            val result = download2022Repo.downloadAndInsertDatabaseFile2022(remoteConfigRepo.getDatabaseUrl())
            Logger.i {"result is $result"}
            if (result){
                remoteConfigRepo.csvUpdatedSuccessfully1stTime()
                updateLTPOfAllStocks()
            }
            _downloadFileLiveData.emit(DownLoadStepAndState.CSV_DOWNLOADED)

           /* else if(downloadInfoRepo.isStockUpdateAvailable()){
                val allStockRecentlyUpdated = stockNetworkRepo.getAllRecentlyUpdatedStocks(rConfig.lastUpdatedMaxTimeOnServer(),userRepo.getLoggedInUserId())
                stock2022DatabaseRepo.insertStockAll(allStockRecentlyUpdated)
                updateLTPOfAllStocks()
                _downloadFileLiveData.emit(DownLoadStepAndState.STOCK_UPDATED)
            }*/
        }
    }

    private suspend fun updateLTPOfAllStocks(){
        realTimePriceDataRepo.syncAllPrice()
    }

    fun insert2021CSVFileData(csvDataList: List<List<String>>) {
        viewModelScope.launch(Dispatchers.Default) {
            download2021Repo.insert2021CSVFile(csvDataList)
            _downloadFileLiveData.emit(DownLoadStepAndState.CSV_2021_INSERTED)
            set2021DatabaseImportedSuccessfully()
        }
    }

    fun is2021DataAlreadyAvailable(): Boolean {
        return is2021AlreadyImported()
    }

    private fun set2021DatabaseImportedSuccessfully() {
        sharedPrefStorageRepo.save(SharedPrefStorageRepo.KEY_IS_2021_IMPORTED, true)
    }

    private fun is2021AlreadyImported(): Boolean {
        return sharedPrefStorageRepo.getBoolean(SharedPrefStorageRepo.KEY_IS_2021_IMPORTED, false)
    }
}