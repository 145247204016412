package com.halal.stocks.data.api

import com.halal.stocks.data.api.user.auth.UserAuthRepo
import com.halal.stocks.utils.RConfig
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.util.*

class ApiHttpClient(
    val client: HttpClient,
    val authRepo: UserAuthRepo,
    val rConfig: RConfig
) {

    val baseAPIUrl = rConfig.getAPIUrl.ifEmpty { "https://cms.islamicstock.in:8443/api" } // "http://cms.islamicstock.in:8080/api"

    @Suppress("UNCHECKED_CAST")
    suspend inline fun <reified T> post(api: String, any: Any?): T {
        if (api.contains("http://") || api.contains("https://"))
            throw RuntimeException("Wrong api $api")

        return client.post("${baseAPIUrl}$api") {

            if (!PlatformUtils.IS_BROWSER && !PlatformUtils.IS_NODE)
                this.addRequiredHeaders("")

            this.addAuthHeader(authRepo.getAuthToken())
            this.setBody(any)
        }.body()
    }

    suspend inline fun <reified T> get(api: String): T? {
        return client.get("${baseAPIUrl}$api") {
            if (!api.startsWith("/") || api.contains("http://") || api.contains("https://"))
                throw RuntimeException("Wrong api $api")

            if (!PlatformUtils.IS_BROWSER && !PlatformUtils.IS_NODE)
                this.addRequiredHeaders("")

            this.addAuthHeader(authRepo.getAuthToken())

        }.body()
    }

}