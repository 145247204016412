package com.halal.stocks.data.api.notifications

import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.api.notifications.data.NotificationNetworkRepo
import com.halal.stocks.data.api.notifications.data.NotificationsVo
import com.halal.stocks.data.api.notifications.data.toNotificationsTable
import com.halal.stocks.data.api.notifications.data.toNotificationsVo
import com.squareup.sqldelight.runtime.coroutines.asFlow
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map

class NotificationRepo(private val database: DatabaseFactory,
                       private val networkRepo: NotificationNetworkRepo) {

    suspend fun refreshNotifications(){
        try {
            val notificationsAtServer = networkRepo.getAllNotifications()
            if (notificationsAtServer!=null)
                insertAll(notificationsAtServer)
        }catch (ex:Exception){
            ex.printStackTrace()
        }
    }

    fun getAllNotificationLiveData() : Flow<List<NotificationsVo>> {
        return database.appDatabase.getNotificationDao.getAllNotificationAsFlow().asFlow().map { it.executeAsList() }.map { it.map { it.toNotificationsVo() } }
    }

//    fun getAllNotificationLiveData(basketId:Long) = database.appDatabase.getNotificationDao.getAllNotificationByBasketId(basketId).executeAsList().map { it.toNotificationsVo() }

    private suspend fun insertAll(listNotification:List<NotificationsVo>){
        database.appDatabase.getNotificationDao.transaction {
            listNotification.forEach { notificationsVo ->
                database.appDatabase.getNotificationDao.insertNotification(notificationsVo.toNotificationsTable())
            }
        }
    }

    suspend fun insert(notificationsVo: NotificationsVo){
        database.appDatabase.getNotificationDao.insertNotification(notificationsVo.toNotificationsTable())
    }

}