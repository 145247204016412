package com.halal.stocks.data.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class IpoTimetableModel(
    @SerialName("open")
    val ipoOpenDate: String,
    @SerialName("close")
    val ipoClosingDate: String,
    @SerialName("allotment")
    val allotmentDate: String,
    @SerialName("refund")
    val refundDate: String,
    @SerialName("account")
    val dematAccountCreditedDate: String,
    @SerialName("listing")
    val listingDate: String,
)