package com.halal.stocks.data.api.basket

import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.basket.basket.BasketDatabaseRepo
import com.halal.stocks.data.api.basket.basket.BasketNetworkRepo
import com.halal.stocks.data.api.priceupdate.RealTimePriceDataRepo
import com.halal.stocks.data.model.mapping.UserVo
import com.halal.stocks.data.api.user.UserRepo
import com.halal.stocks.utils.RConfig

class BasketRepo(private val basketNetworkRepo: BasketNetworkRepo,
                 private val userRepo: UserRepo,
                 private val realTimeDataRepo: RealTimePriceDataRepo,
                 private val basketDao: BasketDatabaseRepo,
                 private val rConfig: RConfig,
                 private val sharedPrefStorageRepo: SharedPrefStorageRepo) {

//    private suspend fun isUpdateInBasketAvailable() = rConfig.getBasketVersion > sharedPrefStorageRepo.getInt(KEY_BASKET_UPDATE_AVAILABLE)

   /* suspend fun setBasketAvailability(needUpdateFromServer:Boolean) {
        sharedPrefStorageRepo.save(KEY_BASKET_UPDATE_AVAILABLE, if (needUpdateFromServer) 0 else rConfig.getBasketVersion)
    }*/

    fun monitorAllBasket() = basketDao.getAllBasketsFlow()

    suspend fun syncBasketWithServer() : Boolean{
       return try {
            val lastUpdatedTime = basketDao.getMaxLastUpdatedTime()
            val recentlyUpdatedBaskets = basketNetworkRepo.getRecentlyUpdatedBaskets(lastUpdatedTime)
            basketDao.insertAllBasketsData(recentlyUpdatedBaskets)
             true
        }catch (ex:Exception){
             false
        }
    }

/*    suspend fun getAllBaskets() : List<BasketVo>?{
       return if (isUpdateInBasketAvailable()){
            basketNetworkRepo.getAllBaskets(userRepo.getLoggedInUserId()).apply {
                basketDao.insertAllBasketsData(this)
                setBasketAvailability(needUpdateFromServer = false)
                this.forEach {
                    it.mapOfPercentChange = StockReturnCalculator.getBasketChangeData(it, realTimeDataRepo)
                }
            }
        }else{
           basketDao.getAllBasketsFromDB()?.apply {
               if (isEmpty()){
                   setBasketAvailability(needUpdateFromServer = true)
               }
               this.forEach {
                   it.mapOfPercentChange = StockReturnCalculator.getBasketChangeData(it, realTimeDataRepo)
               }
           }?: kotlin.run {
               setBasketAvailability(needUpdateFromServer = true)
               emptyList()
           }
        }
    }*/

    fun monitorAllBasketOfUser() = basketDao.getAllBasketsOfUser()/*?.apply {
        this.forEach {
            it.mapOfPercentChange = StockReturnCalculator.getBasketChangeData(it, realTimeDataRepo)
        }
    }*/

    suspend fun getBasketByBasketId(basketId:Long) = basketDao.getAllBasketsById(basketId)

    suspend fun followBasket(basketId:Long,userVo: UserVo?) : String? {
       return basketNetworkRepo.followBasket(basketId, userVo)
    }
}