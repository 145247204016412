package com.halal.stocks.ui.downloads

import co.touchlab.kermit.Logger
import com.halal.stocks.data.api.stocks.CoreActivityEnum
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.api.stocks.StockNetworkRepo
import com.halal.stocks.data.model.mapping.StockDataVo2022
import com.halal.stocks.data.room.watchlist.WatchListRepo
import com.halal.stocks.utils.HalalScreener
import com.halal.stocks.utils.caesarCipherDecrypte
import com.halal.stocks.utils.roundTwoDecimal
import kotlinx.coroutines.flow.MutableSharedFlow

class Download2022Repo(
    private val stockNetworkRepo: StockNetworkRepo,
    private val stockDoa2022: Stock2022DatabaseRepo,
    private val halalScreener: HalalScreener,
    private val watchListRepo: WatchListRepo
) {

    suspend fun downloadAndInsertDatabaseFile2022(
        databaseUrl: String,
        _downloadProgress: MutableSharedFlow<String> = MutableSharedFlow(0)
    ) : Boolean{
        return try {
            val downloadedFile = stockNetworkRepo.getAllEncryptedStocksData(databaseUrl)

            if (downloadedFile.isNotEmpty()) {
                _downloadProgress.emit("Updating 2022 Database")
                readDownloadedCSVFile2022(downloadedFile)
            } else {
                false
            }
        }catch (ex:Exception){
            Logger.i {"exception is $ex"}
            false
        }
    }

    private suspend fun readDownloadedCSVFile2022(downloadedFile: List<List<String>>)  :Boolean{
        //Delete all existing before new csv data
        stockDoa2022.deleteAll()

            val listOfStocks = ArrayList<StockDataVo2022>()
            downloadedFile.forEach { item ->
                    listOfStocks.add(arrayListToStockModelFromCsvStockDataVo2022(item))
                }

            //Total Halal stock counter
//            var halalCounter = 0
//            val totalAddedStockCounter = listOfStocks.size
            /*listOfStocks.forEach {
                it.isHalal = halalScreener.isStockHalal(it)
                if (it.isHalal){
                    halalCounter++
                }
            }*/

           /* if (halalCounter==0)
                return false*/

            try {
                stockDoa2022.insertOrReplaceStockAll(listOfStocks)
            }catch (ex:Exception){
                ex.printStackTrace()
            }

            return true
    }

    private suspend fun arrayListToStockModelFromCsvStockDataVo2022(item:List<String>) : StockDataVo2022 {
        val nseCode = item[1].caesarCipherDecrypte()
        return StockDataVo2022(
            name = item[0].caesarCipherDecrypte(),
            nseCode = nseCode,
            bseCode = item[2].caesarCipherDecrypte(),
            industry = item[3].caesarCipherDecrypte(),
            isCoreActivityHalal = item[4].caesarCipherDecrypte().let {
                try {
                    CoreActivityEnum.valueOf(it)
                } catch (ex: Exception) {
                    CoreActivityEnum.FAILED
                }
            },
            businessDetail = item[5].caesarCipherDecrypte(),
            debt = item[6].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            marketCapitalization = item[7].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            totalAsset = item[8].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            currentAsset = item[9].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            nonCurrentAsset = item[10].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            fixedAsset = item[11].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            intangibleAssets = item[12].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            inventory = item[13].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            totalLiabilities = item[14].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            currentLiabilities = item[15].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            nonCurrentLiabilities = item[16].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            totalRevenue = item[17].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            totalInterestIncome = item[18].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            annualReportUrl = item[19].caesarCipherDecrypte(),
            watchListPKeyArray = HashSet(),
            //21 outstanding no of share
            isActive = item[21].caesarCipherDecrypte().let {
                try {
                    it.toBoolean()
                } catch (ex: Exception) {
                    false
                }
            },
            isIPO = item[22].caesarCipherDecrypte().let {
                try {
                    it.toBoolean()
                } catch (ex: Exception) {
                    false
                }
            },
            ipoLogo = item[23].caesarCipherDecrypte(),
            ipoTimetableJson = item[24].caesarCipherDecrypte(),
            assetProfileJSON = item[25].caesarCipherDecrypte(),
            isBSE  = item[26].caesarCipherDecrypte().let {
                    try {
                        it.toBoolean()
                    } catch (ex: Exception) {
                        false
                    }
                },
            otherNonPermissibleIncome = item[27].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            nonPermissibleIncomeOperationSegment =item[28].caesarCipherDecrypte().formatToRequiredDoubleValues(),
            lastUpdatedTime  = try { item[29].caesarCipherDecrypte().toLong() } catch (ex:Exception){ 0L},
        ).also {
            it.isHalal = halalScreener.isStockHalal(it)
        }
    }

    private fun String.formatToRequiredDoubleValues()  = try {
        (this.toDouble() / 10000000).roundTwoDecimal() } catch (ex: Exception) { 0.0 }

}