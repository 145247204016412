package com.halal.stocks.data.api.notifications

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import kotlinx.coroutines.Dispatchers
import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.basket.BasketRepo
import com.halal.stocks.data.api.notifications.data.NotificationsVo
import com.halal.stocks.ui.asCommonFlow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn

class NotificationViewModel(private val basketRepo: BasketRepo,
                            private val sharedPrefStorageRepo: SharedPrefStorageRepo,
                            private val notificationRepo: NotificationRepo
) : ViewModel() {

    suspend fun insert(notificationsVo: NotificationsVo){
        notificationRepo.insert(notificationsVo)
    }

    fun notificationsFlow() = notificationRepo.getAllNotificationLiveData().flowOn(Dispatchers.Default).asCommonFlow()
/*
    fun notificationsFlow(basketId: Long?) = flow {
        if (!sharedPrefStorageRepo.getBoolean(SharedPrefStorageRepo.KEY_FETCH_NOTIFICATIONS)) {
            val listOfNotifications = networkRepo.getAllNotifications()
            if (listOfNotifications != null) {
                sharedPrefStorageRepo.save(SharedPrefStorageRepo.KEY_FETCH_NOTIFICATIONS, true)
                notificationRepo.insertAll(listOfNotifications)
            }
        }
        val data = basketId?.let {
            notificationRepo.getAllNotificationLiveData(it)
        }?: kotlin.run {
            notificationRepo.getAllNotificationLiveData()
        }
        emit(data)
    }.flowOn(Dispatchers.Default).asCommonFlow()
*/

    suspend fun fetchNotificationActive(){
        notificationRepo.refreshNotifications()
    }

//
//    suspend fun getBasketModelByBasketId(basketId: Long) = basketRepo.getBasketByBasketId(basketId)

}