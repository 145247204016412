package com.halal.stocks.data.room.calltracking

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import com.halal.stocks.TrackingDataTable
import com.halal.stocks.TrackingNameTable
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.model.AutoCompleteStockModel
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock

class CallTrackingBottomSheetViewModel(private val trackingRepo: TrackingRepo, private val stockDoa2022: Stock2022DatabaseRepo) : ViewModel() {

    fun getRecommendationAsFlow(nseCode: String) = trackingRepo.getRecommendationAsFlow(nseCode)

    fun getAllStockForAutoCompleteAsFlow() = stockDoa2022.getAllStockListAsFlow().map { it.map { AutoCompleteStockModel(it.bseCode,it.nseCode,it.name) } }

    fun getTotalItemList(trackingNamePKey: Long) = trackingRepo.getRecommendationListSize(TrackingNameTable(trackingNamePKey,""))

    fun addStockToTrackingList(
        trackingNamePKey: Long,
        nseCode: String,
        entryPrice: String,
        targetPrice: String,
        stopLosePrice: String,
        tvNote: String
    ) :Boolean {
        val dataToAddTable = TrackingDataTable(
           trackingNamePKey= trackingNamePKey,
            nseCode=nseCode,
            callDate = Clock.System.now().toEpochMilliseconds(),
            callPrice = entryPrice.toDouble(),
            targetPrice = targetPrice.toDouble(),
            stopLosePrice = stopLosePrice.toDouble(),
            highestSinceCall = entryPrice.toDouble(),
            highestOnDate =Clock.System.now().toEpochMilliseconds(),
            lowestSinceCall= entryPrice.toDouble(),
            lowestOnDate=Clock.System.now().toEpochMilliseconds(),
            tvNote
        )
        return trackingRepo.addRecommendation(dataToAddTable)
    }

    fun updateTrackingRecommendation(
        trackingNamePKey: Long,
        stockNseCode: String,
        entryPrice: String,
        targetPrice: String,
        stopLosePrice: String,
        tvNote: String
    ): Boolean {
        val dataToUppdateTable = TrackingDataTable(
            trackingNamePKey= trackingNamePKey,
            nseCode=stockNseCode,
            callDate = Clock.System.now().toEpochMilliseconds(),
            callPrice = entryPrice.toDouble(),
            targetPrice = targetPrice.toDouble(),
            stopLosePrice = stopLosePrice.toDouble(),
            highestSinceCall = entryPrice.toDouble(),
            highestOnDate =Clock.System.now().toEpochMilliseconds(),
            lowestSinceCall= entryPrice.toDouble(),
            lowestOnDate=Clock.System.now().toEpochMilliseconds(),
            tvNote
        )

       return trackingRepo.updateRecommendation(dataToUppdateTable)
    }

}