package com.halal.stocks.data.analytics

import com.halal.stocks.data.api.stocks.Stock2021DatabaseRepo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.model.isBusinessMuted
import com.halal.stocks.data.model.mapping.isHalal
import com.halal.stocks.utils.RConfig
import dev.icerock.moko.mvvm.viewmodel.ViewModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.combine

class AnalyticsViewModel(
    private val stock2022DatabaseRepo: Stock2022DatabaseRepo,
    private val stockDao2021: Stock2021DatabaseRepo, private val rConfig: RConfig
) : ViewModel() {

    val filterFlow = MutableStateFlow(AnalyticFilterEnum.ALL)

   fun getStockDataAsFlow() = stock2022DatabaseRepo.getAllStockListAsFlow().combine(filterFlow) { allStockList, filter->
       when(filter){
           AnalyticFilterEnum.ALL -> allStockList
           AnalyticFilterEnum.MUTE -> {
               val mutedList = allStockList.filter { it.isBusinessMuted() }
               mutedList
           }
           AnalyticFilterEnum.Compliant -> {
               val all = allStockList.filter { it.isHalal() }
               all
           }
           AnalyticFilterEnum.NonCompliant -> {
               val aList = allStockList.filter { !it.isHalal() }
               aList
           }
       }
   }

    suspend fun filterByCheckbox(filterEnum: AnalyticFilterEnum) =  filterFlow.emit(filterEnum)

/*    fun allHalalStocksFlow() = flow {
        val allHalalList: List<StockDataVo> = stock2022DatabaseRepo.getAllStockList()
        allList.addAll(allHalalList)
        emit(allList)
    }.flowOn(Background).asCommonFlow()*/


    /* fun addToWatchList(nseCode: String) : Job {
         return scope.launch(Background) {
             watchListRepo.addToWatchList(nseCode)
         }
     }

     fun removeFromWatchList(nseCodeWatchlist: String,watchlistIndex:Long=0) :Job{
         return scope.launch(Background) {
             watchListRepo.removeFromWatchList(nseCodeWatchlist, watchlistIndex)
         }
     }*/
}