import com.halal.stocks.IslamicStockSDK
import com.halal.stocks.IslamicStockVN2
import com.halal.stocks.di.initKoinJs
import com.squareup.sqldelight.db.SqlDriver
import com.squareup.sqldelight.drivers.sqljs.initSqlDriver
import csstype.Auto
import csstype.Display
import csstype.GridTemplateAreas
import csstype.array
import dev.gitlive.firebase.firebase
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.await
import kotlinx.js.ReadonlyArray
import mui.material.useMediaQuery
import mui.system.Box
import mui.system.sx
import org.koin.core.component.KoinComponent
import react.FC
import react.Props
import react.PropsWithChildren
import react.create
import react.createContext
import react.dom.client.createRoot
import react.router.dom.HashRouter
import react.useState
import theme.ThemeModule
import ui.Content
import ui.Header
import ui.Menu
import ui.Sidebar
import ui.base.ScreenPageModule
import ui.pages.Stock
import ui.utils.Area
import ui.utils.Sizes
import kotlin.js.Json
import kotlin.js.Promise
import kotlin.js.json

//https://github.com/karakum-team/kotlin-mui-showcase

object AppDependencies : KoinComponent {
    var ourSDK: IslamicStockSDK = IslamicStockSDK()

    init {
        initOtherStuff()
    }
}

val AppDependenciesContext = createContext<AppDependencies>()

val mainScope = MainScope()

suspend fun main() {
    val promise: Promise<SqlDriver> = initSqlDriver(IslamicStockVN2.Schema)
    initKoinJs(promise.await())

    js(
        """
    window.originalFetch = window.fetch;
    window.fetch = function (resource, init) {
        init = Object.assign({}, init);
        init.credentials = init.credentials !== undefined ? init.credentials : 'include';
        return window.originalFetch(resource, init);
    };
"""
    )

    createRoot(
        document.createElement("div").also {
            document.body!!.appendChild(it)
        }).render(App.create())
}

external interface ApplicationProps : Props, PropsWithChildren {
    var isDownloaded: Boolean
    var setIsDownloaded: () -> Unit

    var allStocks: ReadonlyArray<Stock>
}

val App = FC<Props> {

    val mobileMode = useMediaQuery("(max-width:960px)")

    val (isDownloadedState, setIsDownloadedState) = useState(false)

    fun setIsDownloadedTrue() {
        setIsDownloadedState(true)
    }

    AppDependenciesContext.Provider(AppDependencies) {
        HashRouter {
            ScreenPageModule {
                ThemeModule {
                    Box {
                        sx {
                            display = Display.grid
                            gridTemplateRows = array(
                                Sizes.Header.Height,
                                Auto.auto,
                            )
                            gridTemplateColumns = array(
                                Sizes.Sidebar.Width, Auto.auto,
                            )
                            gridTemplateAreas = GridTemplateAreas(
                                arrayOf(Area.Header, Area.Header),
                                if (mobileMode)
                                    arrayOf(Area.Content, Area.Content)
                                else
                                    arrayOf(Area.Sidebar, Area.Content),
                            )
                        }

                        Header()
                        if (mobileMode) Menu() else Sidebar()
                        Content {
                            isDownloaded = isDownloadedState
                            setIsDownloaded = { setIsDownloadedTrue() }
                        }
                    }
                }
            }
        }
    }

//    val (isDownloadedState, setIsDownloadedState) = useState(false)
//
//    fun setIsDownloadedTrue() {
//        setIsDownloadedState(true)
//    }


}

fun initOtherStuff() {
    val firebaseConfig: Json = json(
        "apiKey" to "AIzaSyAyKWUH3Cl-8y4vlhtyK_I9kfCjmE8MDRw",
        "projectId" to "halal-stock-screening",
        "authDomain" to "halal-stock-screening.firebaseapp.com",
        "storageBucket" to "halal-stock-screening.appspot.com",
        "messagingSenderId" to "745983078505",
        "appId" to "1:745983078505:web:3fb95a1328827ade39b2f8",
        "measurementId" to "G-M6ZXLZZ64S"
    )
    firebase.initializeApp(firebaseConfig)
    firebase.remoteConfig().fetchAndActivate()
}