package com.halal.stocks.data.api.basket.basket

import com.halal.stocks.data.DatabaseFactory
import com.squareup.sqldelight.runtime.coroutines.asFlow
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map

class BasketDatabaseRepo(private val database: DatabaseFactory)  {

    fun insertAllBasketsData(list: List<BasketVo>) {
        database.appDatabase.getBasketDataDao.transaction {
            list.forEach { data->
                database.appDatabase.getBasketDataDao.insertAllBasketsData(data.toBasketTable())
            }
        }
    }

    fun getAllBasketsFlow(): Flow<List<BasketVo>?> {
       return  database.appDatabase.getBasketDataDao.getAllBasketsFromDB().asFlow().map { it.executeAsList() }.map { list -> list.map { it.toBasketVo() } }
    }

    fun getAllBasketsOfUser(): Flow<List<BasketVo>?> {
       return database.appDatabase.getBasketDataDao.getAllBasketsOfUser().asFlow().map { it.executeAsList() }.map { it.map { table -> table.toBasketVo() } }
    }

    fun getMaxLastUpdatedTime() = database.appDatabase.getBasketDataDao.selectMaxLastUpdatedTime().executeAsOneOrNull()?.MAX
    fun getAllBasketsById(basketId:Long) = database.appDatabase.getBasketDataDao.getAllBasketsById(basketId).executeAsOneOrNull()

}