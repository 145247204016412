package com.halal.stocks.data

import com.halal.stocks.DATABASE_VERSION
import com.russhwolf.settings.Settings

class SharedPrefStorageRepo {

    private val sharedPreference: Settings = Settings()

    companion object{
//            const val KEY_CSV_VERSION="key_CSV_version"
//            const val KEY_FORCE_UPDATE_DATABASE="key_force_update_database_$DATABASE_VERSION"
//            const val KEY_BASKET_UPDATE_AVAILABLE="key_basket_update_available_$DATABASE_VERSION"//Change version to update basket in updates
            const val KEY_FETCH_NOTIFICATIONS="key_fetch_notifications$DATABASE_VERSION"
            const val KEY_IS_2021_IMPORTED="key_is_2021_imported_$DATABASE_VERSION"
    }

    fun <T> save(key: String, value: T) {
        with(sharedPreference) {
            when (value) {
                is String -> putString(key, value)
                is Boolean -> putBoolean(key, value)
                is Long -> putLong(key, value)
                is Float -> putFloat(key, value)
                is Double -> putDouble(key, value)
                is Int -> putInt(key, value)
            }
        }
    }

    fun getBoolean(key: String,defaultValue:Boolean = false): Boolean {
        return try {
            sharedPreference.getBoolean(key, defaultValue)
        }catch (ex:Exception){
            defaultValue
        }
    }

    fun getString(key: String): String {
       return try {
            sharedPreference.getString(key)
        }catch (ex:Exception) {
            ""
        }
    }

    fun getFlot(key: String,def:Float): Float {
        return try { sharedPreference.getFloat(key, def) }catch (ex:Exception) { 0F}
    }

    fun getDouble(key: String,def:Double): Double {
        return try {  sharedPreference.getDouble(key, def) }catch (ex:Exception) { 0.0}
    }

    fun getLong(key: String,def:Long): Long {
        return try { sharedPreference.getLong(key, def) }catch (ex:Exception) { 0L}
    }

    fun getInt(key: String,def:Int=0): Int {
        return try {  return sharedPreference.getInt(key, def) }catch (ex:Exception) { 0 }
    }
}