package com.halal.stocks

import kotlin.Boolean
import kotlin.Double
import kotlin.Long
import kotlin.String

public data class StockDbVo2022Table(
  public val name: String,
  public val nse_code: String,
  public val bse_code: String,
  public val industry: String,
  public val isCoreActivityHalal: Long,
  public val businessDetail: String?,
  public val debt: Double,
  public val market_capitalization: Double,
  public val total_asset: Double,
  public val current_asset: Double,
  public val non_current_asset: Double,
  public val fixed_asset: Double,
  public val intangible_assets: Double,
  public val inventory: Double,
  public val total_liabilities: Double,
  public val current_liabilities: Double,
  public val non_current_liabilities: Double,
  public val total_revenue: Double,
  public val interest_income: Double,
  public val annual_report_url: String,
  public val watchListPKeys: String?,
  public val isHalal: Boolean,
  public val outstanding_no_share: Long,
  public val isActive: Boolean,
  public val isIPO: Boolean,
  public val ipoLogo: String?,
  public val ipoTimetableJson: String?,
  public val assetProfileJSON: String?,
  public val dailyPriceJson: String?,
  public val isBSE: Boolean,
  public val otherNonPermissibleIncome: Double?,
  public val nonPermissibleIncomeOperationSegment: Double?,
  public val lastUpdatedTime: Long
) {
  public override fun toString(): String = """
  |StockDbVo2022Table [
  |  name: $name
  |  nse_code: $nse_code
  |  bse_code: $bse_code
  |  industry: $industry
  |  isCoreActivityHalal: $isCoreActivityHalal
  |  businessDetail: $businessDetail
  |  debt: $debt
  |  market_capitalization: $market_capitalization
  |  total_asset: $total_asset
  |  current_asset: $current_asset
  |  non_current_asset: $non_current_asset
  |  fixed_asset: $fixed_asset
  |  intangible_assets: $intangible_assets
  |  inventory: $inventory
  |  total_liabilities: $total_liabilities
  |  current_liabilities: $current_liabilities
  |  non_current_liabilities: $non_current_liabilities
  |  total_revenue: $total_revenue
  |  interest_income: $interest_income
  |  annual_report_url: $annual_report_url
  |  watchListPKeys: $watchListPKeys
  |  isHalal: $isHalal
  |  outstanding_no_share: $outstanding_no_share
  |  isActive: $isActive
  |  isIPO: $isIPO
  |  ipoLogo: $ipoLogo
  |  ipoTimetableJson: $ipoTimetableJson
  |  assetProfileJSON: $assetProfileJSON
  |  dailyPriceJson: $dailyPriceJson
  |  isBSE: $isBSE
  |  otherNonPermissibleIncome: $otherNonPermissibleIncome
  |  nonPermissibleIncomeOperationSegment: $nonPermissibleIncomeOperationSegment
  |  lastUpdatedTime: $lastUpdatedTime
  |]
  """.trimMargin()
}
