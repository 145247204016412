package com.halal.stocks.ui.allhalal

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import kotlinx.coroutines.Dispatchers
import com.halal.stocks.data.model.mapping.StockDataVo
import com.halal.stocks.data.room.watchlist.WatchListRepo
import com.halal.stocks.data.model.FinancialResultType
import com.halal.stocks.data.api.stocks.Stock2021DatabaseRepo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.ui.asCommonFlow
import com.halal.stocks.utils.RConfig
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch

class AllHalalListViewModel(
    private val Stock2022DatabaseRepo: Stock2022DatabaseRepo,
    private val stockDao2021: Stock2021DatabaseRepo, private val rConfig: RConfig
) : ViewModel() {

    fun allHalalStocksFlow() = flow {
        val allHalalList: List<StockDataVo> = when(rConfig.getDefaultFinancialResultType()) {
            FinancialResultType.YEAR_2021 -> stockDao2021.getAllHalalStockList()
            FinancialResultType.YEAR_2022 -> Stock2022DatabaseRepo.getAllHalalStockList()
        }
        emit(allHalalList)
    }.flowOn(Dispatchers.Default).asCommonFlow()

    /* fun addToWatchList(nseCode: String) : Job {
         return viewModelScope.launch(Dispatchers.Default) {
             watchListRepo.addToWatchList(nseCode)
         }
     }

     fun removeFromWatchList(nseCodeWatchlist: String,watchlistIndex:Long=0) :Job{
         return viewModelScope.launch(Dispatchers.Default) {
             watchListRepo.removeFromWatchList(nseCodeWatchlist, watchlistIndex)
         }
     }*/
}