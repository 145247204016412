package com.halal.stocks.data.api.screener.screener

import com.halal.stocks.data.api.ApiHttpClient

class ScreenerNetworkRepo(private val client: ApiHttpClient) {

    suspend fun getAllScreeners(lastUpdatedTime: Long?): List<ScreenerNetworkModel>? {
        return client.get("/getAllScreener/${lastUpdatedTime ?: 0}")
    }

}