package com.halal.stocks.di

import kotlinx.coroutines.Dispatchers
import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.analytics.AnalyticsViewModel
import com.halal.stocks.data.api.ApiClientRepo
import com.halal.stocks.data.api.ApiHttpClient
import com.halal.stocks.data.api.NetworkRepo
import com.halal.stocks.data.api.basket.BasketRepo
import com.halal.stocks.data.api.basket.basket.BasketDatabaseRepo
import com.halal.stocks.data.api.basket.basket.BasketNetworkRepo
import com.halal.stocks.data.api.notifications.NotificationRepo
import com.halal.stocks.data.api.notifications.data.NotificationNetworkRepo
import com.halal.stocks.data.api.priceupdate.RealTimePriceDataRepo
import com.halal.stocks.data.api.priceupdate.data.PriceUpdateNetworkRepo
import com.halal.stocks.data.api.priceupdate.data.RealtimeDatabaseRepo
import com.halal.stocks.data.api.remoteconfig.RemoteConfigRepo
import com.halal.stocks.data.api.screener.ScreenerRepo
import com.halal.stocks.data.api.screener.screener.ScreenerDatabaseRepo
import com.halal.stocks.data.api.screener.screener.ScreenerNetworkRepo
import com.halal.stocks.data.api.stocks.Stock2021DatabaseRepo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.api.stocks.StockNetworkRepo
import com.halal.stocks.data.api.stocks.StockRepo
import com.halal.stocks.data.api.user.UserAccountSyncRepo
import com.halal.stocks.data.billing.SubscriptionRepo
import com.halal.stocks.data.room.calltracking.CallTrackingBottomSheetViewModel
import com.halal.stocks.data.room.calltracking.CallTrackingViewModel
import com.halal.stocks.data.room.portfolio.PortfolioRepo
import com.halal.stocks.data.room.calltracking.TrackingRepo
import com.halal.stocks.data.api.user.UserDatabaseRepo
import com.halal.stocks.data.api.user.UserNetworkRepo
import com.halal.stocks.data.api.user.UserRepo
import com.halal.stocks.data.api.user.auth.UserAuthRepo
import com.halal.stocks.data.api.user.usecases.SyncUserDataFromServerAfterLoginUsecase
import com.halal.stocks.data.room.calltracking.usecases.GetTrackingDataFromServerUseCase
import com.halal.stocks.data.room.calltracking.usecases.UpdateTrackingDataToServerUseCase
import com.halal.stocks.data.room.portfolio.usecases.GetPortfolioDataFromServerUseCase
import com.halal.stocks.data.room.portfolio.usecases.UpdatePortfolioDataToServerUseCase
import com.halal.stocks.data.room.watchlist.WatchListRepo
import com.halal.stocks.data.room.watchlist.usecases.GetWatchlistDataFromServerUseCase
import com.halal.stocks.data.room.watchlist.usecases.UpdateWatchlistDataToServerUseCase
import com.halal.stocks.platformModule
import com.halal.stocks.ui.SettingsViewModel
import com.halal.stocks.ui.TestViewModel
import com.halal.stocks.ui.allhalal.AllHalalListViewModel
import com.halal.stocks.ui.basket.BasketDetailViewModel
import com.halal.stocks.ui.basket.BasketListViewModel
import com.halal.stocks.ui.downloads.Download2021Repo
import com.halal.stocks.ui.downloads.Download2022Repo
import com.halal.stocks.ui.downloads.DownloadingFragmentViewModel
import com.halal.stocks.ui.ipos.IPOViewModel
import com.halal.stocks.ui.profile.MyBasketViewModel
import com.halal.stocks.data.api.notifications.NotificationViewModel
import com.halal.stocks.data.api.screener.screener.ScreenerSyncAfterLoginUseCase
import com.halal.stocks.platformModuleJs
import com.halal.stocks.ui.result.ResultFragmentViewModel
import com.halal.stocks.ui.search.SearchViewModel
import com.halal.stocks.ui.signin.SigninViewModel
import com.halal.stocks.ui.watchlist.WatchlistViewModel
import com.halal.stocks.utils.HalalScreener
import com.halal.stocks.utils.RConfig
import com.halal.stocks.utils.StringProvider
import com.squareup.sqldelight.db.SqlDriver
import io.ktor.client.*
import io.ktor.client.engine.*
import io.ktor.client.plugins.cache.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.SupervisorJob
import kotlinx.serialization.json.Json
import org.koin.core.Koin
import org.koin.core.context.startKoin
import org.koin.dsl.KoinAppDeclaration
import org.koin.dsl.module
import org.koin.mp.KoinPlatformTools

fun initKoin(enableNetworkLogs: Boolean = false, appDeclaration: KoinAppDeclaration = {}) =
    startKoin {
        appDeclaration()
        modules(
            commonModule(enableNetworkLogs = enableNetworkLogs),
            basketModule(),
            screenerModule(),
            dailyPriceModule(),
            platformModule(),
            platformViewModel(),
            jobsModule(),
            trackingModule(),
            useCasesModule()
        )
    }

fun initKoinJs(driver: SqlDriver, enableNetworkLogs: Boolean = false, appDeclaration: KoinAppDeclaration = {}) =
    startKoin {
        appDeclaration()
        modules(
            commonModule(enableNetworkLogs = enableNetworkLogs),
            basketModule(),
            screenerModule(),
            dailyPriceModule(),
            platformModuleJs(driver),
            platformViewModel(),
            jobsModule(),
            trackingModule(),
            useCasesModule()
        )
    }

fun getKoin(): Koin {
    return KoinPlatformTools.defaultContext().get()
}

// called by android, iOS, js etc
fun initKoin() = initKoin(enableNetworkLogs = false) {}
fun initKoinJs(driver: SqlDriver) = initKoinJs(driver, enableNetworkLogs = false) {}

fun dailyPriceModule() = module {
    single { PriceUpdateNetworkRepo(get()) }
}

fun jobsModule() = module {
    single { RemoteConfigRepo(clientRepo = get(), sharedPrefStorageRepo = get(), rConfig = get()) }
}

fun screenerModule() = module {
    single { ScreenerNetworkRepo(get()) }
    single { ScreenerDatabaseRepo(get()) }
    single { ScreenerRepo(networkRepo = get(), stock2022DatabaseRepo = get(),screenerDatabaseRepo=get(),
        sharedPrefStorageRepo = get()
    ) }
}

fun basketModule() = module {
    single { BasketNetworkRepo(client = get()) }
    single { BasketDetailViewModel(basketRepo = get(), realTimeDataRepo = get()) }
    single { BasketListViewModel(basketRepo = get(), userRepo = get(), realTimeDataRepo = get()) }
}

fun trackingModule() = module {
    single { TrackingRepo(get(),get(),get(),get(),get()) }
    single { CallTrackingViewModel(get(),get()) }
    single { CallTrackingBottomSheetViewModel(get(),get()) }
}

fun useCasesModule() = module {
    single { UpdateTrackingDataToServerUseCase(get(),get()) }
    single { GetTrackingDataFromServerUseCase(get(),get()) }

    single { UpdatePortfolioDataToServerUseCase(get(),get()) }
    single { GetPortfolioDataFromServerUseCase(get(),get()) }

    single { UpdateWatchlistDataToServerUseCase(get(),get()) }
    single { GetWatchlistDataFromServerUseCase(get(),get()) }
    single { ScreenerSyncAfterLoginUseCase(get(),get()) }

    single { SyncUserDataFromServerAfterLoginUsecase(
        screenerSyncAfterLoginUseCase= get(),
        portfolioDataFromServerUseCase = get(),
        getWatchlistDataFromServerUseCase = get(),
        getCallTrackingDataFromServerUseCase = get()
    ) }
}

fun commonModule(enableNetworkLogs: Boolean) = module {

    single { TestViewModel() }

    single { WatchlistViewModel(get()) }

    single { HalalScreener(sharedPrefStorageRepo = get(), stock2022DatabaseRepo = get()) }
    single { RConfig() }

    single { AnalyticsViewModel(get(),get(),get()) }

    single { createHttpClient(get(), enableNetworkLogs) }

    single { CoroutineScope(Dispatchers.Default + SupervisorJob()) }
    single { StringProvider(halalScreener = get()) }

    single { NotificationNetworkRepo(get()) }

    /**
     * View Models
     */
    single {
        SigninViewModel(
            userRepo = get()
        )
    }
    single {
        AllHalalListViewModel(
            Stock2022DatabaseRepo = get(),
            stockDao2021 = get(),
            rConfig = get()
        )
    }


    single {
        DownloadingFragmentViewModel(
            remoteConfigRepo=get(),
            download2021Repo = get(),
            download2022Repo = get(),
            realTimePriceDataRepo= get(),
            sharedPrefStorageRepo = get()
        )
    }

    single { StockRepo(get(),get(),get(),get()) }

    single {
        SearchViewModel(
            Stock2022DatabaseRepo = get(),
            stockDao2021 = get(),
            rConfig = get(),
            userDatSyncRepo=get(),
            userRepo = get()
        )
    }

    single {
        ResultFragmentViewModel(
            halalScreener = get(),
            dao2022 = get(),
            dao2021 = get(),
            realTimeDataRepo = get(),
            networkRepo = get(),
            stringProvider = get(),
            screenerRepo = get()
        )
    }

    single {
        SettingsViewModel(
            stock2022DatabaseRepo = get(),
            stockDao2021 = get(),
            rConfig = get(),
            halalScreener = get()
        )
    }
    single { IPOViewModel(get()) }
    single { MyBasketViewModel(get(), get()) }
    single {
        NotificationViewModel(
            notificationRepo = get(),
            basketRepo = get(),
            sharedPrefStorageRepo = get()
        )
    }


    /**
     * Repositories
     */
    single { ApiHttpClient(get(),get(),get()) }
    single { ApiClientRepo(get(),get()) }
    single { NetworkRepo(get(), get()) }

    single { NotificationRepo(get(), get()) }


    single { RealtimeDatabaseRepo(get()) }
    single {
        RealTimePriceDataRepo(
            priceUpdateNetworkRepo = get(),
            userRepo = get(),
            dao2022 = get(),
            realtimePriceDao = get(),
            externalScope = get(),
            sharedPrefStorageRepo = get(),
            rConfig = get()
        )
    }

    single { UserRepo(
        userNetworkRepo = get(),
        userDatabaseRepo = get(),
        sharedPrefStorageRepo = get(),
        authRepo = get(),
        userAccountSyncRepo=get())
    }

    single { UserAccountSyncRepo(
        getUserDataFromServerAfterLoginUsecase = get(),
        sharedPrefStorageRepo = get(),
        updateWatchlistDataToServerUseCase = get(),
        updatePortfolioDataToServerUseCase = get(),
        updateTrackingDataToServerUseCase = get(),
        externalScope = get()
    ) }

    single { UserNetworkRepo(get(),get()) }
    single { UserAuthRepo(get()) }

    single { SharedPrefStorageRepo() }
    single { UserDatabaseRepo(get()) }
    single { WatchListRepo(database = get(),stockDao2022 = get(), sharedPrefStorageRepo = get(),
        externalScope = get(),
        userAccountSyncRepo = get()
    ) }
    single {
        BasketRepo(
            get(),
            userRepo = get(),
            realTimeDataRepo = get(),
            basketDao = get(),
            rConfig = get(),
            sharedPrefStorageRepo = get()
        )
    }

    single {
        Download2021Repo(
            stockDoa2021 = get(),
            halalScreener = get(),
            watchListRepo = get()
        )
    }

    single {
        Download2022Repo(
            stockNetworkRepo = get(),
            stockDoa2022 = get(),
            halalScreener = get(),
            watchListRepo = get()
        )
    }

    single { Stock2022DatabaseRepo(get()) }
    single { Stock2021DatabaseRepo(get()) }
    single { BasketDatabaseRepo(get()) }

    single { StockNetworkRepo(get()) }

    single { SubscriptionRepo(get(),get()) }
    single { PortfolioRepo(database = get(), sharedPrefStorageRepo = get(), externalScope = get(),
        userAccountSyncRepo = get()
    ) }
}

fun createHttpClient(
    httpClientEngine: HttpClientEngine,
    enableNetworkLogs: Boolean) = HttpClient(httpClientEngine) {
    install(ContentNegotiation) {

        json(Json {
            ignoreUnknownKeys = true
            explicitNulls = false
            encodeDefaults = true
            isLenient = true
            allowSpecialFloatingPointValues = true
            allowStructuredMapKeys = true
            prettyPrint = false
            useArrayPolymorphism = false
        })
    }

    install(HttpCache)
/*    install(Auth) {
        bearer {
            loadTokens {
                // Load tokens from a local storage and return them as the 'BearerTokens' instance
                BearerTokens(authRepo.getAuthToken(), "")
            }
        }
    }*/

    if (enableNetworkLogs) {
        install(Logging) {
            logger = Logger.DEFAULT
            level = LogLevel.ALL
        }
    }
}