package com.halal.stocks.data.api.user

import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.user.auth.UserAuthRepo
import com.halal.stocks.data.api.user.models.UserFcmUpdateModel
import com.halal.stocks.data.api.user.models.UserLoginAndToken
import com.halal.stocks.data.model.mapping.UserVo
import com.halal.stocks.utils.objectify
import kotlinx.coroutines.flow.map

class UserRepo(
    private val userAccountSyncRepo: UserAccountSyncRepo,
    private val authRepo: UserAuthRepo,
    private val userNetworkRepo: UserNetworkRepo,
    private val userDatabaseRepo: UserDatabaseRepo,
    private val sharedPrefStorageRepo: SharedPrefStorageRepo
) {

    var currentActiveUser:UserVo?=null
    fun getUserAsFlow() = userDatabaseRepo.getUserAsFlow()?.map {
        currentActiveUser = it
        it
    }

    suspend fun isRegistered() = userDatabaseRepo.isRegistered() > 0

    suspend fun loginSuccess(userVo: UserVo,idToken:String): Boolean {
        //If login success get basket detail again
        return try {
            userNetworkRepo.loginSuccess(userVo,idToken).let { serverUser ->
                val loginModel = serverUser.objectify<UserLoginAndToken>()
               return if (loginModel.userModel.id!="0"){
                    authRepo.saveAuthToken(loginModel.authToken)
                    val isSynced = userAccountSyncRepo.afterLoginSync(loginModel.userModel.id.toString())
                    if (isSynced)
                        userDatabaseRepo.loginUserSuccess(loginModel.userModel)
                    isSynced
                }else
                   false
            }
        } catch (ex: Exception) {
            false
        }
    }

    fun logout(){
        authRepo.deleteAuthToken()
        userDatabaseRepo.logoutUser()
    }

    suspend fun getLoggedInUser() = userDatabaseRepo.getSavedUser()
    suspend fun getLoggedInUserId() = userDatabaseRepo.getLoggedInUserId()

    suspend fun isFCMUpdatedToServer() = sharedPrefStorageRepo.getBoolean("fmcUpdatedToServer")

    suspend fun fcmUpdatedToServer(token: String) {
        getLoggedInUser()?.let { user ->
            try {
                userNetworkRepo.updateFCMToken(UserFcmUpdateModel(
                    email = user.email,
                    token
                )).let { res ->
                    val isSuccess = res.toBoolean()
                    if (isSuccess) {
                        sharedPrefStorageRepo.save("fmcUpdatedToServer", true)
                    }
                }

            } catch (ex: Exception) {
                ex.printStackTrace()
                sharedPrefStorageRepo.save("fmcUpdatedToServer", false)
            }
        }

    }


}