package com.halal.stocks.data.api.stocks

import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.model.mapping.*
import org.koin.core.component.KoinComponent

class Stock2021DatabaseRepo(private val database: DatabaseFactory) : IStockDao2021, KoinComponent {
    override fun insertStock(stockDataVo: StockDataVo2021) {
        database.appDatabase.getStock2021Dao.insertStock(stockDataVo.toStockDbVo2021Table())
    }

    override fun insertStockAll(stockDataVoList: List<StockDataVo2021>) {
       database.appDatabase.getStock2021Dao.transaction {
           stockDataVoList.forEach {
               database.appDatabase.getStock2021Dao.insertStock(it.toStockDbVo2021Table())
           }
       }
    }

    fun searchIndustry() : List<String>{
        return database.appDatabase.getStock2021Dao.searchIndustry().executeAsList()
    }

    fun getStocksByIndustry(industry:String) : List<StockDataVo2021>{
        return database.appDatabase.getStock2021Dao.getStocksByIndustry("%$industry%").executeAsList().map { it.toStockDataVo2021() }
    }

    //--('% ' || :text) OR name LIKE (:text || ' %') OR nse_code LIKE ('% ' || :text) OR nse_code LIKE (:text || ' %') OR bse_code LIKE ('% ' || :text) OR bse_code LIKE (:text || ' %');
    override fun searchStock(text: String): List<StockDataVo2021> {
       return database.appDatabase.getStock2021Dao.searchStock("%$text%","%$text%","%$text%").executeAsList().map {
           it.toStockDataVo2021()
       }
    }

    //--('% ' || :text) OR name LIKE (:text || ' %') OR nse_code LIKE ('% ' || :text) OR nse_code LIKE (:text || ' %') OR bse_code LIKE ('% ' || :text) OR bse_code LIKE (:text || ' %');
    override fun searchStockWithLimit(text: String): List<StockDataVo2021> {
        return database.appDatabase.getStock2021Dao.searchStockWithLimit("%$text%","%$text%","%$text%").executeAsList().map {
            it.toStockDataVo2021()
        }
    }

    override fun addToWatchList(nseCodeWatchlist: String) {
        database.appDatabase.getStock2021Dao.updateWatchlistByNseCode(nseCodeWatchlist)
    }

    override fun removeFromWatchlist(nseCodeWatchlist: String) {
        database.appDatabase.getStock2021Dao.removeWatchlist(nseCodeWatchlist)
    }

    override fun getAllStockList(): List<StockDataVo2021> {
        return database.appDatabase.getStock2021Dao.getAllStockList().executeAsList().map { it.toStockDataVo2021() }
    }

    override fun getAllHalalStockList(): List<StockDataVo2021> {
       return database.appDatabase.getStock2021Dao.getAllHalalStockList().executeAsList().map {
           it.toStockDataVo2021()
       }
    }

    override fun getStockByNseCode(nseCode: String): StockDataVo2021? {
        return database.appDatabase.getStock2021Dao.getStockByNseCode(nseCode).executeAsOneOrNull()?.toStockDataVo2021()
    }
}