package com.halal.stocks.data.api.user.auth

import com.halal.stocks.data.SharedPrefStorageRepo

private const val KEY_AUTH_TOKEN ="SOMETHING_ENCRYPTED"
class UserAuthRepo(private val sharedPrefStorageRepo: SharedPrefStorageRepo) {

    fun deleteAuthToken() = sharedPrefStorageRepo.save(KEY_AUTH_TOKEN,"")

    fun saveAuthToken(authToken: String){
        sharedPrefStorageRepo.save(KEY_AUTH_TOKEN,authToken)
    }

    fun getAuthToken() :String{
        return sharedPrefStorageRepo.getString(KEY_AUTH_TOKEN)
    }

}