package com.halal.stocks.utils

fun Double.roundTwoDecimal(): Double {
    return try {
        round(2)
    }catch (ex:Exception){
        this
    }
}

fun Double.toPlainString(): String = "${this.toLong()}${(this%1).toString().drop(1)}".take(16).removeTrailingZero()

fun String.removeTrailingZero() = if (this.endsWith(".0")) this.dropLast(2) else this

private fun Double.round(decimals: Int): Double {
    var multiplier = 1.0
    repeat(decimals) { multiplier *= 10 }
    return kotlin.math.round(this * multiplier) / multiplier
}

//fun Double.roundTo(numFractionDigits: Int): Double {
//    val factor = 10.0.pow(numFractionDigits.toDouble())
//    return (this * factor).roundToInt() / factor
//}

fun Double.percentOf(value:Double) : Double {
    return (((this-value)/value)*100).roundTwoDecimal()
}

//private fun Float.roundZeroToOne() : Float{
//    return if (this == 0F ) 1F else this
//}