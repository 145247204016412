package ui.base

import react.FC
import react.PropsWithChildren
import react.createContext

val ScreenPageListContext = createContext<ScreenPageList>()
val ScreenPageModule = FC<PropsWithChildren> { props ->
    val users = useScreenPageMapping()
    ScreenPageListContext(users) {
        +props.children
    }
}