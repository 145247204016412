package com.halal.stocks.data.room.portfolio.usecases

import com.halal.stocks.PortfolioHoldingDataRoom
import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.api.*
import com.halal.stocks.data.api.user.auth.UserAuthRepo
import com.halal.stocks.data.room.portfolio.usecases.vo.PortfolioDataVo
import com.halal.stocks.data.room.watchlist.usecases.WatchlistServerSyncVo
import com.halal.stocks.utils.objectify
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

class GetPortfolioDataFromServerUseCase(
    private val database: DatabaseFactory,
    private val apiHttpClient: ApiHttpClient
) : IUseCase {

    override suspend fun execute(loggedInUserId: String): Boolean {
        return try {
           val serverData = apiHttpClient.get<String?>("/getAllPortfolioJson")?.makeJsonUseAble()
            if (serverData!=null && serverData.isNotEmpty()) {
                val dataServer = serverData.objectify<List<PortfolioServerSyncVo>>()
                dataServer.forEach { data->
                    database.appDatabase.getPortfolioDao.insertFromServer(data.nameTable.portfolioPKey,data.nameTable.portfolioName)
                    data.listOfData.forEach { voData->
                        database.appDatabase.getPortfolioHoldingDao.insert(PortfolioHoldingDataRoom(
                            portfolioPKey = voData.portfolioPKey,
                            nseCode = voData.nseCode,
                            qty = voData.qty,
                            avgBuyPrice = voData.avgBuyPrice,
                            transactionDate = voData.transactionDate
                        ))
                    }
                }
            }
            true
        } catch (ex: Exception) {
            ex.printStackTrace()
            false
        }
    }

}

fun String.makeJsonUseAble():String{
    return if (this.startsWith("\""))
        this.replace("\\","").drop(1).dropLast(1)
    else
        this
}