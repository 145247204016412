package com.halal.stocks.data.api.user

import kotlinx.coroutines.Dispatchers
import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.user.usecases.SyncUserDataFromServerAfterLoginUsecase
import com.halal.stocks.data.room.calltracking.usecases.UpdateTrackingDataToServerUseCase
import com.halal.stocks.data.room.portfolio.usecases.UpdatePortfolioDataToServerUseCase
import com.halal.stocks.data.room.watchlist.usecases.UpdateWatchlistDataToServerUseCase
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.launch

private const val KEY_WATCH_SYNC="KEY_WATCH_SYNC"
private const val KEY_TRACKING_SYNC="KEY_TRACKING_SYNC"
private const val KEY_PORTFOLIO_SYNC="KEY_PORTFOLIO_SYNC"

class UserAccountSyncRepo(
    private val getUserDataFromServerAfterLoginUsecase: SyncUserDataFromServerAfterLoginUsecase,
    private val sharedPrefStorageRepo: SharedPrefStorageRepo,
    private val updateWatchlistDataToServerUseCase: UpdateWatchlistDataToServerUseCase,
    private val updatePortfolioDataToServerUseCase: UpdatePortfolioDataToServerUseCase,
    private val updateTrackingDataToServerUseCase: UpdateTrackingDataToServerUseCase,
    private val externalScope: CoroutineScope) {

    suspend fun afterLoginSync(loggedInUserId: String) = getUserDataFromServerAfterLoginUsecase.execute(loggedInUserId)

    suspend fun syncPendingUserChangesToServer(loggedInUserId: String){
        if (sharedPrefStorageRepo.getBoolean(KEY_WATCH_SYNC)){
            uploadWatchlistToServer(loggedInUserId)
        }

        if (sharedPrefStorageRepo.getBoolean(KEY_TRACKING_SYNC)){
            uploadTrackingToServer(loggedInUserId)
        }

        if (sharedPrefStorageRepo.getBoolean(KEY_PORTFOLIO_SYNC)){
            uploadPortfolioToServer(loggedInUserId)
        }
    }

    private suspend fun uploadWatchlistToServer(loggedInUserId: String) {
        if (updateWatchlistDataToServerUseCase.execute(loggedInUserId)) {
            sharedPrefStorageRepo.save(KEY_WATCH_SYNC, false)
            isAccountSyncAvailable.emit(getCurrentFlowValue())
        }
    }

    private  suspend fun uploadTrackingToServer(loggedInUserId: String) {
        if (updateTrackingDataToServerUseCase.execute(loggedInUserId)) {
            sharedPrefStorageRepo.save(KEY_TRACKING_SYNC, false)
            isAccountSyncAvailable.emit(getCurrentFlowValue())
        }
    }

    private  suspend fun uploadPortfolioToServer(loggedInUserId: String) {
        if (updatePortfolioDataToServerUseCase.execute(loggedInUserId)) {
            sharedPrefStorageRepo.save(KEY_PORTFOLIO_SYNC, false)
            isAccountSyncAvailable.emit(getCurrentFlowValue())
        }
    }

     fun watchlistUpdated(){
         externalScope.launch(Dispatchers.Default) {
             sharedPrefStorageRepo.save(KEY_WATCH_SYNC,true)
             isAccountSyncAvailable.emit(getCurrentFlowValue())
         }
    }

    fun trackingUpdated(){
        externalScope.launch(Dispatchers.Default) {
            sharedPrefStorageRepo.save(KEY_TRACKING_SYNC,true)
            isAccountSyncAvailable.emit(getCurrentFlowValue())
        }
    }

    fun portfolioUpdated(){
        externalScope.launch(Dispatchers.Default) {
            sharedPrefStorageRepo.save(KEY_PORTFOLIO_SYNC,true)
            isAccountSyncAvailable.emit(getCurrentFlowValue())
        }
    }

    private fun getCurrentFlowValue() = sharedPrefStorageRepo.getBoolean(KEY_WATCH_SYNC) || sharedPrefStorageRepo.getBoolean(KEY_TRACKING_SYNC) || sharedPrefStorageRepo.getBoolean(KEY_PORTFOLIO_SYNC)
    val isAccountSyncAvailable: MutableStateFlow<Boolean> = MutableStateFlow(getCurrentFlowValue())
}
