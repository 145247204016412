package com.halal.stocks.utils

import io.ktor.util.*

//fun String?.caesarCipherEncrypt() = StockEncryptDecrypt.caesarCipherEncrypt(this)
fun String?.caesarCipherDecrypte() = StockEncryptDecrypt.caesarCipherDecrypte(this)

internal object StockEncryptDecrypt {

/*    fun caesarCipherEncrypt(plain: String?): String {
        if (plain==null)
            return ""

        val b64encoded: String =Base64.encodeToString(plain.toByteArray(),Base64.DEFAULT)

        // Reverse the string
        val reverse = StringBuffer(b64encoded).reverse().toString()
        val tmp = StringBuilder()
        val OFFSET = 4
        for (element in reverse) {
            tmp.append((element.code + OFFSET).toChar())
        }
        return tmp.toString()
    }*/

    fun caesarCipherDecrypte(secret: String?): String {
        if (secret==null)
            return ""

        val tmp = StringBuilder()
        val OFFSET = 4
        for (element in secret) {
            tmp.append((element.code - OFFSET).toChar())
        }
        return tmp.reverse().toString().decodeBase64String()
    }
}