package com.halal.stocks.data.api.screener

import kotlinx.coroutines.Dispatchers
import com.halal.stocks.ScreenerDataTable
import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.screener.screener.*
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.api.user.UserRepo
import com.halal.stocks.data.model.mapping.isHalal
import com.halal.stocks.data.model.mapping.nseCode
import com.squareup.sqldelight.runtime.coroutines.asFlow
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map

class ScreenerRepo(private val networkRepo: ScreenerNetworkRepo,
                   private val stock2022DatabaseRepo: Stock2022DatabaseRepo,
                   private val screenerDatabaseRepo: ScreenerDatabaseRepo,
                   private val sharedPrefStorageRepo: SharedPrefStorageRepo) {

    private suspend fun isSubscribeForNotifications(screenerUrl:String)= sharedPrefStorageRepo.getBoolean(screenerUrl)
    suspend fun subOrUnsubFromNotification(screenerUrl:String, isSubscribe:Boolean) = sharedPrefStorageRepo.save(screenerUrl,isSubscribe)

    suspend fun syncScreenersWithServer():Boolean{
        return try {
            val lastUpdatedTime = screenerDatabaseRepo.getMaxLastUpdatedTime()
            val screenerListOnServer = networkRepo.getAllScreeners(lastUpdatedTime)?: emptyList()
            screenerDatabaseRepo.insertOrReplaceScreenerData(screenerListOnServer)
            true
        }catch (ex:Exception){
            ex.printStackTrace()
            false
        }
    }

    fun monitorScreenerDetailByScanURL(scanUrl:String):Flow<List<ScreenerDetailModel>>{
        return screenerDatabaseRepo.monitorScreenerDataByScanUrl(scanUrl).asFlow().map { it.executeAsOne() }.map { screenerDataTable->
            getScreenerDetailModelFromTableData(screenerDataTable)
        }
    }

    suspend fun getScreenerDetailByScanURL(scanUrl:String): List<ScreenerDetailModel> {
        return screenerDatabaseRepo.monitorScreenerDataByScanUrl(scanUrl).executeAsOne().let { screenerDataTable->
            getScreenerDetailModelFromTableData(screenerDataTable)
        }
    }

    fun getAllScreenerThatHaveNseCode(nseCode:String): List<ScreenerDataTable> {
        return screenerDatabaseRepo.getAllScreenerThatHaveNseCode(nseCode).executeAsList()
    }

    suspend fun getScreenerDetailModelFromTableData(screenerDataTable: ScreenerDataTable) : List<ScreenerDetailModel>{
        val stockList = screenerDataTable.stocks.split(",").map { nseCode->
            stock2022DatabaseRepo.getStockByNseCode(nseCode.trim())
        }
        val priceList = screenerDataTable.trigger_prices.split(",")
        val scan_url = screenerDataTable.scan_url

       return priceList.mapIndexed { index, s ->
            ScreenerDetailModel(scan_url,stockList.getOrNull(index),s.trim()).also {
                it.triggered_at = screenerDataTable.triggered_at
                it.scan_name = screenerDataTable.scan_name
                it.isPremium = screenerDataTable.isPremium
                it.stock?.nseCode()?.let { nseCode->
                    it.alsoFoundIn = getAllScreenerThatHaveNseCode(nseCode).filter { it.scan_url!=screenerDataTable.scan_url }
                }
            }
        }.filter { it.stock!=null && it.stock.isHalal()}
    }

    fun monitorScreeners(): Flow<List<ScreenerNetworkModel>?> {
        return screenerDatabaseRepo.monitorScreenerData().asFlow().flowOn(Dispatchers.Default).map { it.executeAsList() }.map {
            it.map { screenerTableModel->
                screenerTableModel.toScreenerNetworkModel().apply {
                    isSubscribeForNotification = isSubscribeForNotifications(screenerTableModel.scan_url)
                }
            }
        }
    }

    fun delete(scanUrl: String) = screenerDatabaseRepo.delete(scanUrl)

  /* suspend fun getAllScreeners(loggedInUserId:String): List<ScreenerModel>? {
       return  networkRepo.getAllScreeners(loggedInUserId).map { networkScreenerModel->
             val priceList = networkScreenerModel.trigger_prices.split(",")
             val stockList = networkScreenerModel.stocks.split(",").map { nseCode->
                 stock2022DatabaseRepo.getStockByNseCode(nseCode)
             }
             ScreenerModel(networkScreenerModel.scan_name,
                 networkScreenerModel.scan_url,
                 stockList,priceList,networkScreenerModel.triggered_at,networkScreenerModel.screenerDetail)
         }
   }*/

}