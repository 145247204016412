package com.halal.stocks.data.api.screener.screener

import com.halal.stocks.GetWatchlistPKeysWatchlistByNseCode
import com.halal.stocks.data.DatabaseFactory

class ScreenerDatabaseRepo(private val database: DatabaseFactory) {

    fun insertOrReplaceScreenerData(screenerModels:List<ScreenerNetworkModel>){
        screenerModels.forEach {
            database.appDatabase.getScreenerDao.insertOrReplaceScreenerData(it.toScreenerTable())
        }
    }

    fun monitorScreenerData() = database.appDatabase.getScreenerDao.monitorScreenerData()
    fun monitorScreenerDataByScanUrl(scanUrl:String) = database.appDatabase.getScreenerDao.monitorScreenerDataByScanUrl(scanUrl)

    fun getAllScreenerThatHaveNseCode(nseCode:String) = database.appDatabase.getScreenerDao.getAllScreenerThatHaveNseCode("%${nseCode}%")

    fun getMaxLastUpdatedTime() = database.appDatabase.getScreenerDao.selectMaxLastUpdatedTime().executeAsOneOrNull()?.MAX

    fun deleteAllScreener() = database.appDatabase.getScreenerDao.deleteAll()
    fun delete(scanUrl: String) = database.appDatabase.getScreenerDao.delete(scanUrl)
}