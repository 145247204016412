package com.halal.stocks.data.billing

import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.api.ApiHttpClient
import com.squareup.sqldelight.runtime.coroutines.asFlow
import kotlinx.coroutines.flow.map
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

class SubscriptionRepo(private val database: DatabaseFactory,private val apiHttpClient: ApiHttpClient) {

    fun getSubscriptionsFlow() = database.appDatabase.getSubscriptionDao.getAllSubscription().asFlow().map { it.executeAsList().map { it.toSubscriptionStatusModel() } }

    fun updateSubscriptions(listOfSubscription:List<SubscriptionStatusModel>){
        with(database.appDatabase.getSubscriptionDao){
            deleteAllSubscription()
            listOfSubscription.forEach { insertBillingData->
                insertBillingData(insertBillingData.toSubscriptionStatusTable())
            }
        }
    }

    fun deleteLocalUserData() = database.appDatabase.getSubscriptionDao.deleteAllSubscription()

    suspend fun registerPurchase(product: String, purchaseToken: String) {

        val serverVerifyPurchase = try {
            apiHttpClient.post<SubscriptionStatusModel>("/registerPurchase",RegisterProdModel(product,purchaseToken,false))
        }catch (ex:Exception){
            ex.printStackTrace()
            null
        }

        if (serverVerifyPurchase!=null)
            updateSubscriptions(listOf(serverVerifyPurchase))
    }
}

@Serializable
data class RegisterProdModel(@SerialName("product") val product:String, @SerialName("purchaseToken") val purchaseToken: String,@SerialName("isFromIos") val isFromIos: Boolean)