package com.halal.stocks.ui

import dev.icerock.moko.mvvm.viewmodel.ViewModel
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock

// Copyright © 2022 Compass. All rights reserved.
class TestViewModel: ViewModel() {

    private var count = 0

    private val _stateFlow = MutableSharedFlow<Int>(0)
    val stateFlow = _stateFlow.asCommonFlow()

    fun getStateFlow() {
        viewModelScope.launch(Dispatchers.Default) {
            _stateFlow.emit(count)
            count++
        }
    }

    fun reset() {
        count = 0
    }

    fun getData() = flow {
        val x = Clock.System.now().toString()
        emit(x)
    }.flowOn(Dispatchers.Default).asCommonFlow()

    fun getDataWithParam(s: String) = flow {
        val x = Clock.System.now().toString()
        emit("$s $x")
    }.flowOn(Dispatchers.Default).asCommonFlow()
}