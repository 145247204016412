package com.halal.stocks.data.api.stocks

import co.touchlab.kermit.Logger
import kotlinx.coroutines.Dispatchers
import com.halal.stocks.GetStockCounter
import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.model.DailyUpdateModelApp
import com.halal.stocks.data.model.mapping.*
import com.halal.stocks.utils.jsonify
import com.halal.stocks.utils.objectify
import com.halal.stocks.utils.toDailyUpdateModelApp
import com.halal.stocks.utils.toJson
import com.squareup.sqldelight.runtime.coroutines.asFlow
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import org.koin.core.component.KoinComponent

class Stock2022DatabaseRepo(private val database: DatabaseFactory) : IStockDao2022, KoinComponent {
    override fun insertStock(stockDataVo: StockDataVo2022) {
        database.appDatabase.getStock2022Dao.insertStock(stockDataVo.toStockDbVo2022Table())
    }

    override fun insertOrReplaceStockAll(stockDataVoList: List<StockDataVo2022>) {
        database.appDatabase.getStock2022Dao.transaction {
            stockDataVoList.forEach {
                database.appDatabase.getStock2022Dao.insertStock(it.toStockDbVo2022Table())
            }
        }
    }

    fun getMaxLastUpdatedTimeOfStock() : Long{
        return database.appDatabase.getStock2022Dao.selectMaxLastUpdatedTime().executeAsOneOrNull()?.MAX?:Clock.System.now().toEpochMilliseconds()
    }

    fun getRecentlyUpdatedStock()  =  database.appDatabase.getStock2022Dao.getRecentlyUpdatedStock().asFlow().map {
        it.executeAsList()
    }.map { list ->
        list.map {
            it.toStockDataVo2022()
        }
    }

    fun searchIndustry() : List<String>{
        return database.appDatabase.getStock2022Dao.searchIndustry().executeAsList()
    }

    fun getStocksByIndustry(industry:String) : List<StockDataVo2022>{
        return database.appDatabase.getStock2022Dao.getStocksByIndustry("%$industry%").executeAsList().map { it.toStockDataVo2022() }
    }

    override fun searchStock(text: String): List<StockDataVo2022> {
        return database.appDatabase.getStock2022Dao.searchStock("%$text%","%$text%","%$text%").executeAsList().map { it.toStockDataVo2022() }
    }

    override fun searchStockWithLimit(text: String): List<StockDataVo2022> {
        return database.appDatabase.getStock2022Dao.searchStockWithLimit("%$text%","%$text%","%$text%").executeAsList().map { it.toStockDataVo2022() }
    }

    override fun getWatchlistByPKey(watchlistPKey: String): Flow<List<StockDataVo2022>> {
        return database.appDatabase.getStock2022Dao.getWatchlistByWatchlistPKey(watchlistPKey).asFlow().map { query -> query.executeAsList().map { it.toStockDataVo2022() } }
    }

    override fun addedToWatchlist(nseCode: String, watchlistPKey: String) {
        updateWatchlist(nseCode,watchlistPKey,true)
    }

    override fun removedFromWatchlist(nseCode: String, watchlistPKey: String) {
        updateWatchlist(nseCode,watchlistPKey,false)
    }

    private fun updateWatchlist(nseCode: String, watchlistPKey: String, isAdded: Boolean) {
        val allKeys = database.appDatabase.getStock2022Dao.getWatchlistPKeysWatchlistByNseCode(nseCode).executeAsOneOrNull()?.watchListPKeys?.objectify<ArrayList<String>>()
        if (allKeys!=null && isAdded && !allKeys.contains(watchlistPKey)){
            allKeys.add(watchlistPKey)
            database.appDatabase.getStock2022Dao.updateWatchlistByNseCode(allKeys.jsonify(),nseCode)
        }else{
            allKeys?.remove(watchlistPKey)
            database.appDatabase.getStock2022Dao.updateWatchlistByNseCode(allKeys?.jsonify(),nseCode)
        }
    }

    fun getAllStockListAsFlow() : Flow<List<StockDataVo2022>>{
        return  database.appDatabase.getStock2022Dao.getAllStockList().asFlow().flowOn(Dispatchers.Default).map { it.executeAsList().map { it.toStockDataVo2022() } }
    }

    fun getTotalNoOfStockFlow() : Flow<GetStockCounter?>{
        return  database.appDatabase.getStock2022Dao.getStockCounter().asFlow().flowOn(Dispatchers.Default).map { it.executeAsOneOrNull() }
    }

    override fun getAllStockList(): List<StockDataVo2022> {
        return database.appDatabase.getStock2022Dao.getAllStockList().executeAsList().map { it.toStockDataVo2022() }
    }

    override fun getAllHalalStockList(): List<StockDataVo2022> {
        return database.appDatabase.getStock2022Dao.getAllHalalStockList().executeAsList().map { it.toStockDataVo2022() }
    }

    override fun setIsHalalStock(bseCode: String, nseCode: String, isHalal: Boolean) {
        database.appDatabase.getStock2022Dao.setIsHalalStock(isHalal,nseCode,bseCode)
    }

    override fun getAllIPOs(): List<StockDataVo2022> {
        return database.appDatabase.getStock2022Dao.getAllIPOsLiveData().executeAsList().map { it.toStockDataVo2022() }
    }

    override suspend fun getStockByNseCode(nseCode: String): StockDataVo2022? {
        return database.appDatabase.getStock2022Dao.getStockByNseCode(nseCode).executeAsOneOrNull()?.toStockDataVo2022()
    }

    suspend fun getStockByBseCode(bseCode: String): StockDataVo2022? {
        return database.appDatabase.getStock2022Dao.getStockByBseCode(bseCode).executeAsOneOrNull()?.toStockDataVo2022()
    }

    override fun updateDailyPriceJson(nseCode: String, dailyUpdateModel: DailyUpdateModelApp?) {
        database.appDatabase.getStock2022Dao.updateDailyPriceJson(dailyUpdateModel?.toJson(),nseCode)
    }

    override fun getDailyPriceJson(nseCode: String): DailyUpdateModelApp? {
        return try {
            database.appDatabase.getStock2022Dao.getDailyPriceJson(nseCode).executeAsOneOrNull()?.dailyPriceJson?.toDailyUpdateModelApp()
        }catch (ex:Exception){
            return null
        }
    }

    suspend fun deleteAll() = database.appDatabase.getStock2022Dao.deleteAll()
}