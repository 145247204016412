package com.halal.stocks.data.api.stocks

enum class CoreActivityEnum(val value:Int){
    FAILED(0),//0
    PASS(1),//1
    MUTE(2), //2
    DOUBTFUL(3); //3
    companion object{
        fun getByValue(value: Long) = values()[value.toInt()]
    }
}