package com.halal.stocks.data.room.calltracking.usecases

import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.api.ApiHttpClient
import com.halal.stocks.data.api.IUseCase
import com.halal.stocks.data.room.calltracking.usecases.vo.TrackingDataVo
import com.halal.stocks.data.room.calltracking.usecases.vo.TrackingNameVo
import com.halal.stocks.utils.jsonify
import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class TrackingServerSyncVo(@SerialName("nameTable") val nameTable: TrackingNameVo, @SerialName("listOfData") val listOfData: List<TrackingDataVo>)

class UpdateTrackingDataToServerUseCase(
    private val apiHttpClient: ApiHttpClient,
    private val database: DatabaseFactory,
) : IUseCase {

    override suspend fun execute(loggedInUserId: String): Boolean {
        return try {
            val data = database.appDatabase.trackingNameRoomQueries.selectAll().executeAsList().map { name->
                val list = database.appDatabase.trackingDataRoomQueries.selectAll(name.trackingNamePKey).executeAsList().map { TrackingDataVo(
                    trackingNamePKey = it.trackingNamePKey,
                    nseCode = it.nseCode,
                    callDate = it.callDate,
                    callPrice = it.callPrice,
                    targetPrice = it.targetPrice,
                    stopLosePrice = it.stopLosePrice,
                    highestSinceCall = it.highestSinceCall,
                    highestOnDate = it.highestOnDate,
                    lowestSinceCall = it.lowestSinceCall,
                    lowestOnDate = it.lowestOnDate,
                    noteOnCall = it.noteOnCall
                ) }
                TrackingServerSyncVo(TrackingNameVo(name.trackingNamePKey,name.name), list)
            }
            apiHttpClient.post<String>("/updateTrackingJson",data.jsonify())
            true
        } catch (ex: Exception) {
            ex.printStackTrace()
            false
        }
    }

}