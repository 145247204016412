package com.halal.stocks.utils

import com.halal.stocks.data.model.FinancialResultType
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.remoteconfig.remoteConfig
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.launch

class RConfig {

    private val remoteConfig = Firebase.remoteConfig
    private val externalScope: CoroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Default)

    init {

//        val configSettings = remoteConfig.settings {
//            minimumFetchIntervalInSeconds = 3600
//        }
//        remoteConfig.setConfigSettingsAsync(configSettings)

        externalScope.launch {
            remoteConfig.setDefaults(
                Pair("getEncryptedDatabaseUrl","/getAllEncryptedStocks")
            )
        }
    }

    val getAPIUrl:String
        get() =remoteConfig.getValue("base_api_url").asString()// "https://192.168.0.178:8443/api"//

    //Just to prevent extra loading at 1st launch
    val getCSVRemoteVersion:Int
        get() =remoteConfig.getValue("csv_remote_version").asLong().toInt()

    val getFreeUserRolls:String
        get() = remoteConfig.getValue("free_user_rolls").asString()

   fun isRemoteAdmin(email:String):Boolean{
       return remoteConfig.getValue("AdminToolFor").asString().contains(email)
   }

    public fun forceRefreshRemoteConfig(){
//        val configSettings = remoteConfigSettings {
//            minimumFetchIntervalInSeconds = 0
//        }
//        remoteConfig.setConfigSettingsAsync(configSettings)
//        remoteConfig.setDefaultsAsync(R.xml.remote_config)
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //Default financial year will be 2022 by default
    fun getDefaultFinancialResultType() = FinancialResultType.YEAR_2022

    /*private val keyLastUpdatedMaxTimeOnServer="lastUpdatedMaxTimeOnServer"
    fun lastUpdatedMaxTimeOnServer(): Long {
        return remoteConfig.getValue(keyLastUpdatedMaxTimeOnServer).asLong()
    }*/

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//    private val stockBasicListKey = "islamic_stock_basic_list"
//    private val listType = object : TypeToken<List<String>>() {}.type
//    val getStockIslamicBasicList: List<String>?
//        get() = remoteConfig.getString(stockBasicListKey).objectify(listType)
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//    private val databaseCSVVersionKey = "force_download_csv_database_version_v2"
//    val csvVersionOnServer: Int
//         get() =remoteConfig.getValue(databaseCSVVersionKey).asString().let { if (it.isEmpty()) 1 else it.toInt() }


    val priceUpdateDelayMinutes: Int
        get() =remoteConfig.getValue("priceUpdateDelayMinutes").asString().let { if (it.isEmpty()) 1 else it.toInt() }

    val getEncryptedDatabaseUrl:String
        get() = remoteConfig.getValue("getEncryptedDatabaseUrl").asString()

//    val screenerAdminUrl:String
//        get() = remoteConfig.getValue("screener_admin_url").asString()

//  val getRealtimePriceCSVUrl:String
//         get() = remoteConfig.getValue("stock_real_time_price")

    val getKiteAPISecret:String
         get() = remoteConfig.getValue("kite_api_secret").asString()

    val isDonationBoxDisabled:Boolean
         get() = remoteConfig.getValue("disable_donation_box").asBoolean()

    val getLastSupportedVersion:Int
        get() = remoteConfig.getValue("last_supported_version").asString().let { if (it.isEmpty()) 5 else it.toInt() }

//    val getBasketVersion:Int
//        get() = remoteConfig.getValue("basket_version").asString().let { if (it.isEmpty()) 1 else it.toInt() }

    suspend fun handleRemoteConfigUpdate(): Boolean {
        return  try {
            remoteConfig.fetchAndActivate()
        }catch (ex:Exception){
            false
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

}