package com.halal.stocks.data.api

import io.ktor.client.request.*
import io.ktor.http.*

fun HttpRequestBuilder.addRequiredHeaders(loggedInUserId: String?) {
//    headers["loggedInUserId"]= loggedInUserId.toString()
    contentType(ContentType.Application.Json)
}

fun HttpRequestBuilder.addAuthHeader(idToken:String){
    headers["Authorization"]="Bearer $idToken"
}