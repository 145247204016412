package com.halal.stocks.data.room.calltracking

import kotlinx.coroutines.Dispatchers
import com.halal.stocks.TrackingDataTable
import com.halal.stocks.TrackingNameTable
import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.api.user.UserAccountSyncRepo
import com.halal.stocks.data.model.mapping.RecommendationModel
import com.squareup.sqldelight.runtime.coroutines.asFlow
import com.squareup.sqldelight.runtime.coroutines.mapToList
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch

class TrackingRepo(
    private val database: DatabaseFactory,
    private val sharedPrefStorageRepo: SharedPrefStorageRepo,
    externalScope: CoroutineScope,
    private val stockDao2022: Stock2022DatabaseRepo,
    private val userAccountSyncRepo: UserAccountSyncRepo,
) {
    init {
        externalScope.launch {
            if (!sharedPrefStorageRepo.getBoolean("isTrackingInit")){
                create("MyCalls",false)
                sharedPrefStorageRepo.save("isWatchlistInit",true)
            }
        }
    }

    fun create(trackingName:String,syncRequired:Boolean=true) : Boolean{
        return with(database.appDatabase.trackingNameRoomQueries){
            if (!isNameAlreadyExist(trackingName)){
                insert(trackingName)

                if (syncRequired)
                    userAccountSyncRepo.trackingUpdated()

                true
            }else
                false
        }
    }

    fun getNamesFlow(): Flow<List<TrackingNameTable>> {
        return with(database.appDatabase.trackingNameRoomQueries){
            selectAll().asFlow().flowOn(Dispatchers.Default).mapToList()
        }
    }

    fun updateName(selectedNameTable: TrackingNameTable, updatedName: String) : Boolean{
        return with(database.appDatabase.trackingNameRoomQueries){
            if (!isNameAlreadyExist(updatedName)){
                update(name = updatedName, trackingNamePKey = selectedNameTable.trackingNamePKey)
                userAccountSyncRepo.trackingUpdated()
                true
            }else
                false
        }
    }

    private fun isNameAlreadyExist(trackingName: String) : Boolean{
        return with(database.appDatabase.trackingNameRoomQueries){
            val nameList = selectByName(trackingName).executeAsOneOrNull()
            nameList!=null
        }
    }

    fun getRecommendationAsFlow(nseCode:String) : Flow<RecommendationModel?>{
        return with(database.appDatabase.trackingDataRoomQueries){
            selectByNse(nseCode).asFlow().flowOn(Dispatchers.Default).map {
                it.executeAsOneOrNull()?.let { maptedItem->
                    val stockDataVo = stockDao2022.getStockByNseCode(maptedItem.nseCode)!!
                    RecommendationModel(maptedItem,stockDataVo)
                }
            }
        }
    }

    fun getRecommendationList(selectedNameTable: TrackingNameTable):  Flow<List<RecommendationModel>> {
        return with(database.appDatabase.trackingDataRoomQueries){
            selectAll(selectedNameTable.trackingNamePKey).asFlow().flowOn(Dispatchers.Default).map {
                it.executeAsList().map { maptedItem->
                    val stockDataVo = stockDao2022.getStockByNseCode(maptedItem.nseCode)
                    RecommendationModel(maptedItem,stockDataVo)
                }
            }
        }
    }


    fun getRecommendationListSize(selectedNameTable: TrackingNameTable):  Int {
        return with(database.appDatabase.trackingDataRoomQueries) {
            selectAll(selectedNameTable.trackingNamePKey).executeAsList().size
        }
    }

    fun addRecommendation(trackingDataTable: TrackingDataTable) :Boolean{
        return with(database.appDatabase.trackingDataRoomQueries){
             try {
                 insert(trackingDataTable)
                 userAccountSyncRepo.trackingUpdated()
                 true
             }catch (ex:Exception){
                 false
             }
        }
    }

    fun updateRecommendation(trackingDataTable: TrackingDataTable) :Boolean{
        return with(database.appDatabase.trackingDataRoomQueries){
            try {
                update(
                    callDate = trackingDataTable.callDate,
                    callPrice = trackingDataTable.callPrice,
                    targetPrice = trackingDataTable.targetPrice,
                    stopLosePrice =trackingDataTable.stopLosePrice,
                    noteOnCall = trackingDataTable.noteOnCall,
                    nseCode = trackingDataTable.nseCode
                )
                userAccountSyncRepo.trackingUpdated()
                true
            }catch (ex:Exception){
                false
            }
        }
    }

    fun deleteRecommendation(recommendationModel: RecommendationModel) {
        with(database.appDatabase.trackingDataRoomQueries){
            delete(recommendationModel.trackingDataTable.nseCode,recommendationModel.trackingDataTable.trackingNamePKey)
        }
    }

    fun deleteCallName(trackingNameTable: TrackingNameTable) {
        with(database.appDatabase.trackingNameRoomQueries){
            delete(trackingNameTable.trackingNamePKey)
        }
    }
}