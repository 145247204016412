package com.halal.stocks.data.api.remoteconfig

import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.ApiHttpClient
import com.halal.stocks.utils.RConfig
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

private const val keyCSVVersion = "key_csvVersion_v2"
private const val keyBasketVersion = "key_basketVersion_v2"
private const val keyScreenerVersion = "key_screenerVersion_v2"
private const val keyStockVersion = "key_stockVersion_v2"

class RemoteConfigRepo(private val clientRepo: ApiHttpClient,
                       private val sharedPrefStorageRepo: SharedPrefStorageRepo,
                       private val rConfig: RConfig) {

    private val defaultRemoteConfigModel = RemoteConfigModel(4,1375,378,rConfig.getCSVRemoteVersion)

    suspend fun initRemoteConfigAPI(){
        try {
            val remoteConfigModel =  clientRepo.get<RemoteConfigModel>("/init")
            sharedPrefStorageRepo.save("remoteConfigModel", Json.encodeToString(remoteConfigModel))
        }catch (ex:Exception){
//            if (ex.toString().contains("401 Unauthorized")){
//                userRepo.logout()
//            }
        }
    }

/*    suspend fun resetRemoteConfig(){
        sharedPrefStorageRepo.save("remoteConfigModel", Json.encodeToString(defaultRemoteConfigModel))
    }*/

    private fun getRemoteConfigModel(): RemoteConfigModel = try {
        Json.decodeFromString(sharedPrefStorageRepo.getString("remoteConfigModel"))
    }catch (ex:Exception){
        defaultRemoteConfigModel
    }


    fun isBasketUpdateAvailable(): Boolean {
        val basketVersionOnServer = getRemoteConfigModel().basketVersion
        val basketVersionOnLocal = sharedPrefStorageRepo.getInt(keyBasketVersion,-1)
        return basketVersionOnServer > basketVersionOnLocal
    }
    fun basketUpdatedSuccessfully(){
        val basketVersionOnServer = getRemoteConfigModel().basketVersion
        sharedPrefStorageRepo.save(keyBasketVersion,basketVersionOnServer)
    }


    fun isScreenerUpdateAvailable(): Boolean {
        val versionOnServer = getRemoteConfigModel().screenerVersion
        val versionOnLocal = sharedPrefStorageRepo.getInt(keyScreenerVersion,-1)
        return versionOnServer > versionOnLocal
    }

    fun screenerUpdatedSuccessfully(){
        val versionOnServer = getRemoteConfigModel().screenerVersion
        sharedPrefStorageRepo.save(keyScreenerVersion,versionOnServer)
    }

    fun isStockUpdateAvailable(): Boolean {
        val versionOnServer = getRemoteConfigModel().stockVersion
        val versionOnLocal = sharedPrefStorageRepo.getInt(keyStockVersion,-1)
        return versionOnServer > versionOnLocal
    }
    fun stockUpdatedSuccessfully(){
        val versionOnServer = getRemoteConfigModel().stockVersion
        sharedPrefStorageRepo.save(keyStockVersion,versionOnServer)
    }


    fun isCsvSilentUpdateAvailableAvailable(): Boolean {
        val versionOnServer = getRemoteConfigModel().csvVersion
        val versionOnLocal = sharedPrefStorageRepo.getInt(keyCSVVersion,-1)
        return versionOnServer > versionOnLocal
    }

    fun isCSVUpdateFor1stTime(): Boolean {
        return !sharedPrefStorageRepo.getBoolean("isCSVUpdateFor1stTime")
    }
    fun csvUpdatedSuccessfully1stTime(){
        sharedPrefStorageRepo.save("isCSVUpdateFor1stTime",true)
    }

    fun csvUpdatedSuccessfullySilently(){
        val versionOnServer = getRemoteConfigModel().csvVersion
        sharedPrefStorageRepo.save(keyCSVVersion,versionOnServer)
    }

    fun getDatabaseUrl():String {
        val versionOnServer = getRemoteConfigModel().csvVersion
        return "${rConfig.getEncryptedDatabaseUrl}/$versionOnServer"
    }

    /***
     * from FireBase remote config
     * */
    fun isForceUpdate(currentVersionCode: Int): Boolean {
        return rConfig.getLastSupportedVersion > currentVersionCode
    }

}