package com.halal.stocks.data.api.basket.basket

import com.halal.stocks.data.api.ApiHttpClient
import com.halal.stocks.data.model.mapping.UserVo

class BasketNetworkRepo(private val client: ApiHttpClient) {

    suspend fun getRecentlyUpdatedBaskets(lastUpdatedTime:Long?): List<BasketVo> {
        return client.get("/getAllBaskets/${lastUpdatedTime}")?: emptyList()
    }

    suspend fun getAllIdeasByBasketId(basketId : Int): List<IdeasModel> {
        return client.get("/getAllIdeasOfBasket/$basketId")?: emptyList()
    }

    suspend fun followBasket(basketId: Long, userModel: UserVo?): String {
        return client.post("/followBasket/$basketId",userModel)
    }
}