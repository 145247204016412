package ui.pages

import AppDependenciesContext
import ApplicationProps
import csstype.AlignItems
import csstype.JustifyContent
import csstype.JustifyItems
import csstype.PlaceItems
import csstype.number
import csstype.pct
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import kotlinx.js.jso
import mainScope
import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.img
import react.router.useNavigate
import react.useContext
import react.useEffectOnce
import react.useState
import ui.components.StockAutoCompleteTextView

val HomePage = FC<ApplicationProps> { props ->

    val appDependencies = useContext(AppDependenciesContext)
    val sdk = appDependencies.ourSDK

    val navigate = useNavigate()
    var totalHalalStocks by useState<Long>(0)
    var totalStocks by useState<Long>(0)

    useEffectOnce {
        // if data not downloaded, navigate to download page
        if (!props.isDownloaded) {
            navigate("/download")
        } else {

            mainScope.launch {
                // load total counts
                sdk.halalScreener.getTotalNoOfStockFlow().collectLatest { count ->
                    totalStocks = count?.totalStocks ?: 0
                    totalHalalStocks = count?.totalHalalStocks ?: 0
                }
            }
        }
    }

    Box {
        Stack {
            direction = responsive(StackDirection.column)
            sx {
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
                justifyItems = JustifyItems.center
                placeItems = PlaceItems.center
            }
            Box {
                sx {
                    maxWidth = 90.pct
                }
                img {
                    src = "./images/logo-white.svg"
                }
            }

            StockAutoCompleteTextView()

            Typography {
                sx { flexGrow = number(1.0) }
                variant = TypographyVariant.h6
                noWrap = true
                component = ReactHTML.div

                +"$totalHalalStocks found Halal out of $totalStocks"
            }
        }
    }
}


external interface Stock {
    var label: String
    var nseCode: String
}

fun Stock(label: String, nseCode: String): Stock = jso {
    this.label = label
    this.nseCode = nseCode
}