package com.halal.stocks.data.api

import com.halal.stocks.data.model.DonationHistoryModel
import com.halal.stocks.data.room.watchlist.WatchListRepo
import com.halal.stocks.utils.FirebaseEventSender
import com.halal.stocks.utils.saveFileGetPath
import io.ktor.client.call.*
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import org.koin.core.component.KoinComponent
import our.libs.github.doyaaaaaken.kotlincsv.client.CsvFileReader
import our.libs.github.doyaaaaaken.kotlincsv.client.StringReaderImpl
import our.libs.github.doyaaaaaken.kotlincsv.dsl.context.CsvReaderContext

class NetworkRepo(private val apiClient: ApiClientRepo,private val watchListRepo: WatchListRepo) : KoinComponent{

    suspend fun downloadAnnualReport(savedName:String,url:String,stateFlow: MutableStateFlow<String>?) : String{
        return  try {
            val byteArray = apiClient.downloadFileWithdUrl(url,stateFlow).body<ByteArray>()
             byteArray.saveFileGetPath(savedName)
        }catch (ex:Exception){ "" }

    }

    suspend fun getCSVFileWithUrl(
        databaseUrl: String,
        _downloadProgress: MutableSharedFlow<String> = MutableStateFlow("")
    ): List<List<String>> {
        return  try {
            val csvData =  apiClient.downloadFileWithdUrl(databaseUrl, _downloadProgress).body<String>()
            CsvFileReader(CsvReaderContext(), StringReaderImpl(csvData)).readAllAsSequence().map {
                it.toList()
            }.toList()
        }catch (ex:Exception){
            ex.printStackTrace()
            emptyList()
        }
    }

//    suspend fun getLatestStockDatabase2022(databaseUrl:String) = apiClient.downloadFileWithdUrl(databaseUrl).body<List<List<String>>>().let { stockList1->
//        stockList1.map { arrayListToStockModelFromJsonStockDataVo2022(it) }
//    }

/*
    suspend fun registerLoginUser(userVo: UserVo) : UserVo{
        FirebaseEventSender.sendAPICallEvent("registerLoginUser")
        return apiClient.loginSuccess(userVo)
    }
*/

  /*  suspend fun getAllBaskets(withIdea:Boolean,loggedInUserId:String?) : List<BasketVo>? = try {
        FirebaseEventSender.sendAPICallEvent("getAllBaskets")
        apiClient.getAllBaskets(loggedInUserId, withIdea)
    }catch (ex:Exception){
        ex.toString()
        null
    }*/

//    suspend fun getAllBasketsOfUser(loggedInUserId: String?) : List<BasketVo>? = apiClient.getAllBasketsOfUser(loggedInUserId,loggedInUserId).body()

//    suspend fun getAllIdeasByBasketId(basketId: Int,loggedInUserId: String?) =
//        try {apiClient.getAllIdeasByBasketId(loggedInUserId,basketId).body()}catch (ex:Exception) { null }


   /* suspend fun followBasket(basketId: Long, userVo: UserVo?, loggedInUserId: String?) =
        try {
            FirebaseEventSender.sendAPICallEvent("followBasket")
            apiClient.followBasket(loggedInUserId,basketId,userVo)
        }catch (ex:Exception) {
            FirebaseEventSender.logException(ex)
            null
        }*/

/*
    suspend fun sendRegistrationToServer(loggedInUserId: String?, email:String, token: String) =
        try {
            FirebaseEventSender.sendAPICallEvent("sendRegistrationToServer")
            apiClient.updateFCMToken(loggedInUserId = loggedInUserId, UserFcmUpdateModel(email, token))
        }catch (ex:Exception) { null }
*/

    suspend fun registerDonationAtServer(donationHistoryModel: DonationHistoryModel) =
        try {
            FirebaseEventSender.sendAPICallEvent("registerDonationAtServer")
            apiClient.registerDonationAtServer(donationHistoryModel)
        }catch (ex:Exception) { null }



    /*suspend fun getRealTimeStockData(symbol:String,loggedInUserId:String?) : DailyUpdateModelApp? {
        return try {
            FirebaseEventSender.sendAPICallEvent("getRealTimeStockData")
            FirebaseEventSender.sendDailyDataCallEvent()
            apiClient.getRealTimeStockData(symbol,loggedInUserId)
        }catch (ex:Exception) {
            null
        }
    }*/

//    suspend fun getBasketIDeaPriceData(symbol:String,loggedInUserId:String?) : List<String> { //// 7D | 30D | 90D | 180D | 360D | LTP
//        return try {
//            FirebaseEventSender.sendAPICallEvent("getBasketIDeaPriceData")
//            apiClient.getBasketIdeaPrice(symbol,loggedInUserId)
//        }catch (ex:Exception) {
//            emptyList<String>()
//        }
//    }

}