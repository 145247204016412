package com.halal.stocks

import com.halal.stocks.shared.newInstance
import com.halal.stocks.shared.schema
import com.squareup.sqldelight.Transacter
import com.squareup.sqldelight.db.SqlDriver

public interface IslamicStockVN2 : Transacter {
  public val basketDataRoomQueries: BasketDataRoomQueries

  public val notificationRoomQueries: NotificationRoomQueries

  public val portfolioDataRoomQueries: PortfolioDataRoomQueries

  public val portfolioHoldingDataRoomQueries: PortfolioHoldingDataRoomQueries

  public val realtimeDataRoomQueries: RealtimeDataRoomQueries

  public val screenerDataRoomQueries: ScreenerDataRoomQueries

  public val stock2021DataRoomQueries: Stock2021DataRoomQueries

  public val stock2022DataRoomQueries: Stock2022DataRoomQueries

  public val subscriptionStatusDatabaseQueries: SubscriptionStatusDatabaseQueries

  public val trackingDataRoomQueries: TrackingDataRoomQueries

  public val trackingNameRoomQueries: TrackingNameRoomQueries

  public val userDataRoomQueries: UserDataRoomQueries

  public val watchlisDataRoomQueries: WatchlisDataRoomQueries

  public val watchlisNameRoomQueries: WatchlisNameRoomQueries

  public companion object {
    public val Schema: SqlDriver.Schema
      get() = IslamicStockVN2::class.schema

    public operator fun invoke(driver: SqlDriver): IslamicStockVN2 =
        IslamicStockVN2::class.newInstance(driver)
  }
}
