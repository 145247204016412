package com.halal.stocks.data.room.portfolio

import com.halal.stocks.PortfolioDataTable
import com.halal.stocks.PortfolioHoldingDataRoom
import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.user.UserAccountSyncRepo
import com.squareup.sqldelight.runtime.coroutines.asFlow
import com.squareup.sqldelight.runtime.coroutines.mapToList
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.launch

class PortfolioRepo(private val database: DatabaseFactory,
                    private val sharedPrefStorageRepo: SharedPrefStorageRepo,
                    private val userAccountSyncRepo: UserAccountSyncRepo,
                    externalScope: CoroutineScope) {

    init {
        externalScope.launch {
            if (!sharedPrefStorageRepo.getBoolean("isHoldingInit")){
                createPortfolio("My Holdings",false)
                sharedPrefStorageRepo.save("isHoldingInit",true)
            }
        }
    }

   fun createPortfolio(holdingName:String,syncRequired:Boolean=true) : Boolean{
      return with(database){
            if (!isPortfolioNameAlreadyExist(holdingName)){
                getPortfolioDao.insert(
                    holdingName
                )

                if (syncRequired)
                    userAccountSyncRepo.portfolioUpdated()

                true
            }else
                 false
        }
    }

    fun updatePortfolioName(portfolioPKey: Long, updatedName: String) : Boolean{
        return with(database){
            if (!isPortfolioNameAlreadyExist(updatedName)){
                getPortfolioDao.update(
                    portfolioName = updatedName, portfolioPKey = portfolioPKey
                )
                userAccountSyncRepo.portfolioUpdated()
                true
            }else
                false
        }
    }

    private fun isPortfolioNameAlreadyExist(holdingName: String) : Boolean{
        return with(database){
            val nameList = getPortfolioDao.selectByName(holdingName).executeAsOneOrNull()
            nameList!=null
        }
    }


    fun addStockToPortfolio(portfolioVo: PortfolioHoldingDataRoom) {
        with(database.appDatabase){
            getPortfolioHoldingDao.insert(portfolioVo)
            userAccountSyncRepo.portfolioUpdated()
        }
    }

    fun selectPortfolioHolding(nseCode: String) : PortfolioHoldingDataRoom? {
      return with(database.appDatabase){
                getPortfolioHoldingDao.selectHoldingByNse(nseCode).executeAsOneOrNull()
        }
    }

    fun getPortfolioNames() : Flow<List<PortfolioDataTable>>{
       return with(database.appDatabase){
           getPortfolioDao.selectAllPortfolio().asFlow().mapToList()
        }
    }


    fun getAllStockOfPortfolioAsFlow(portfolioPKey:Long):  Flow<List<PortfolioHoldingDataRoom>> {
        return with(database.appDatabase){
            getPortfolioHoldingDao.selectAllHolding(portfolioPKey).asFlow().mapToList()
        }
    }

    fun getAllStockOfPortfolio(portfolioPKey:Long):  List<PortfolioHoldingDataRoom> {
        return with(database.appDatabase){
            getPortfolioHoldingDao.selectAllHolding(portfolioPKey).executeAsList()
        }
    }

    fun deleteHolding(portfolioKey: Long) {
        with(database.appDatabase){
            getPortfolioHoldingDao.deleteAll(portfolioKey)
            getPortfolioDao.delete(portfolioKey)
            userAccountSyncRepo.portfolioUpdated()
        }
    }

    fun deleteHoldingStock(portfolioPKey: Long?, nseCode: String?) {
        with(database.appDatabase){
            if (portfolioPKey!=null && nseCode!=null) {
                getPortfolioHoldingDao.delete(portfolioPKey, nseCode)
                userAccountSyncRepo.portfolioUpdated()
            }
        }
    }

}