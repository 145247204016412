package com.halal.stocks.data.room.portfolio.usecases

import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.api.ApiHttpClient
import com.halal.stocks.data.api.IUseCase
import com.halal.stocks.data.room.portfolio.usecases.vo.PortfolioDataVo
import com.halal.stocks.data.room.portfolio.usecases.vo.PortfolioHoldingDataVo
import com.halal.stocks.utils.jsonify
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class PortfolioServerSyncVo(@SerialName("nameTable") val nameTable: PortfolioDataVo,@SerialName("listOfData") val listOfData: List<PortfolioHoldingDataVo>)

class UpdatePortfolioDataToServerUseCase(
    private val apiHttpClient: ApiHttpClient,
    private val database: DatabaseFactory,
) : IUseCase {

    override suspend fun execute(loggedInUserId: String): Boolean {
        return try {
            val data = database.appDatabase.getPortfolioDao.selectAllPortfolio().executeAsList().map { name->
                val list = database.appDatabase.getPortfolioHoldingDao.selectAllHolding(name.portfolioPKey).executeAsList().map { PortfolioHoldingDataVo(
                    portfolioPKey = it.portfolioPKey,
                    nseCode = it.nseCode,
                    qty = it.qty,
                    avgBuyPrice = it.avgBuyPrice,
                    transactionDate = it.transactionDate
                ) }
                PortfolioServerSyncVo(PortfolioDataVo(name.portfolioPKey,name.portfolioName), list)
            }
            apiHttpClient.post<String>("/updatePortfolioJson",data.jsonify())
            true
        } catch (ex: Exception) {
            ex.printStackTrace()
            false
        }
    }

}