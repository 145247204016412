package com.halal.stocks.data.api.remoteconfig

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RemoteConfigModel(
    @SerialName("basketVersion")
    val basketVersion:Int,
    @SerialName("screenerVersion")
    val screenerVersion:Int,
    @SerialName("stockVersion")
    val stockVersion:Int,
    @SerialName("csvVersion")
    val csvVersion:Int)