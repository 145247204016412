package com.halal.stocks.utils

import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo


import com.halal.stocks.data.model.FinancialResultType
import com.halal.stocks.data.model.mapping.*
import kotlin.math.abs

const val PREF_KEY_INTEREST_PERCENT = "PREF_KEY_INTEREST_PERCENT"
const val INTEREST_DEFAULT_PERCENT = 5.0 // percent

const val PREF_KEY_DEBT_PERCENT = "PREF_KEY_DEBT_PERCENT"
const val DEBT_DEFAULT_PERCENT = 33.0 //percent

const val PREF_KEY_ILLIQUID_PERCENT = "PREF_KEY_ILLIQUID_PERCENT"
const val ILLIQUID_DEFAULT_PERCENT = 20.0 //percent'

//const val PREF_KEY_MARKET_CAP_PERCENT = "PREF_KEY_MARKET_CAP_PERCENT"
//private const val MARKET_CAP_DEFAULT_PERCENT = 20F //percent'

//const val PREF_KEY_TOTAL_SHARE = "PREF_KEY_TOTAL_SHARE"
//const val PREF_KEY_TOTAL_HALAL_SHARE = "PREF_KEY_TOTAL_HALAL_SHARE"

class HalalScreener(private val sharedPrefStorageRepo: SharedPrefStorageRepo, private val stock2022DatabaseRepo: Stock2022DatabaseRepo){

    /************************************* RULE 1 ******************************************************************************/
    /**
     * Is core activity of business is Halal, as already marked in database
     * */
    fun isRule1BusinessHalal(stockDataVo: StockDataVo) : Boolean{
//        Log.d("ishroid","${stockDataVo.stockDataVo.nseCode} RULE 1 ${stockDataVo.stockDataVo.isCoreActivityHalal}")
        return stockDataVo.isCoreActivityHalal()
    }


    /************************************* RULE 2 ******************************************************************************/
    /**
     * Is non sharia compliant income is less than 5% of total revenue or as set in settings
     * */
    fun isRule2NonIncomeHalal(stockDataVo: StockDataVo) :Boolean {
//        Log.d("ishroid","${stockDataVo.stockDataVo.nseCode} RULE 2 ${abs(getNonPermissiblePercent(stockDataVo)) < getMaxNonPermissibleAllowedPercent()}")
        return abs(getNonPermissiblePercent(stockDataVo)) <= getRule2MaxNonPermissibleAllowedPercent()
    }

    fun getRule2MaxNonPermissibleAllowedPercent() : Double = sharedPrefStorageRepo.getDouble(PREF_KEY_INTEREST_PERCENT,INTEREST_DEFAULT_PERCENT)

    fun setRule2MaxNonPermissibleAllowedPercent(debtValue: Double){
        sharedPrefStorageRepo.save(PREF_KEY_INTEREST_PERCENT,debtValue)
    }

    fun getNonPermissiblePercent(stockDataVo: StockDataVo) :Double {
        return stockDataVo.let { dataVo ->
            (
                    (
                            (dataVo.totalInterestIncome()+dataVo.otherNonPermissibleIncome()+dataVo.nonPermissibleIncomeFromOperationSegment())
                                    /
                                    (dataVo.totalRevenue() + dataVo.totalInterestIncome() + dataVo.otherNonPermissibleIncome())
                            )*100
                    )
        }.roundTwoDecimal()
    }
    /*******************************************************************************************************************/

    /***************************************** RULE 3 ******************************************************************/
    /**
     * Interest-bearing debt to total asset should be 33% by default
     * */
    fun isRule3DebtHalal(stockDataVo: StockDataVo) : Boolean {
//        Log.d("ishroid","${nseCode} RULE 3 ${abs(getRule3DebtPercent(stockDataVo)) < getMaxDebtAllowedPercent()}")
        return abs(getRule3DebtPercent(stockDataVo)) <= getRule3MaxDebtAllowedPercent()
    }

    fun getRule3DebtPercent(stockDataVo: StockDataVo) :Double{
        return stockDataVo.let {dataVo -> ((dataVo.debt() / dataVo.totalAsset()) * 100) }.roundTwoDecimal()
    }

    fun getRule3MaxDebtAllowedPercent() : Double = sharedPrefStorageRepo.getDouble(PREF_KEY_DEBT_PERCENT,DEBT_DEFAULT_PERCENT)
    fun setRule3MaxDebtAllowedPercent(debtValue: Double){
        sharedPrefStorageRepo.save(PREF_KEY_DEBT_PERCENT,debtValue)
    }

    /*******************************************************************************************************************/

    /****************************************** Rule 4 *************************************************************************/
    /**
     * Illiquid asset should be at least 20% of total asset or more of total asset
     * */
    fun isRule4IlliquidAssetHalal(stockDataVo: StockDataVo) : Boolean {
//        Log.d("ishroid","${stockDataVo.nseCode} RULE 4 ${getRule4IlliquidAssetPercent(stockDataVo) > getMaxIlliquidAssetAllowedPercent()}")
        return getRule4IlliquidAssetPercent(stockDataVo) > getRule4MaxIlliquidAssetAllowedPercent().toFloat()
    }

    fun getRule4IlliquidAssetPercent(stockDataVo: StockDataVo) : Double{
        return stockDataVo.let {dataVo-> ((dataVo.fixedAsset() + dataVo.inventory() + dataVo.intangibleAssets())/dataVo.totalAsset()) * 100 }.roundTwoDecimal()
    }

    fun getRule4MaxIlliquidAssetAllowedPercent() : Double = sharedPrefStorageRepo.getDouble(PREF_KEY_ILLIQUID_PERCENT, ILLIQUID_DEFAULT_PERCENT)

    fun setRule4MaxIlliquidAssetAllowedPercent(debtValue: Double){
        sharedPrefStorageRepo.save(PREF_KEY_ILLIQUID_PERCENT,debtValue)
    }


    /****************************************** Rule 5 *************************************************************************/
    /**
     * Net Liquid Assets Vs. Market Capitalisation.
     * Net Liquid Assets = Total Assets – (Tangible Fixed Assets + Inventory) – Liabilities
     * */
    fun isRule5MarketCapHalal(stockDataVo: StockDataVo) : Boolean {
        return stockDataVo.let {dataVo->
            val netLiquidAsset = getNetLiquidAsset(stockDataVo)
//            Log.d("ishroid","${stockDataVo.stockDataVo.nseCode} RULE 5 ${netLiquidAsset > dataVo.marketCapitalization}")
            netLiquidAsset < dataVo.marketCapitalization()
        }
    }

    fun getNetLiquidAsset(stockDataVo: StockDataVo) : Double {
        return stockDataVo.let {dataVo->
            dataVo.totalAsset() - (dataVo.fixedAsset() + dataVo.inventory()) - dataVo.totalLiabilities()
        }.roundTwoDecimal()
    }

    fun isStockHalal(stockDataVo: StockDataVo)  :Boolean{
        return isRule1BusinessHalal(stockDataVo) && isRule2NonIncomeHalal(stockDataVo) && isRule3DebtHalal(stockDataVo) && isRule4IlliquidAssetHalal(stockDataVo)  && isRule5MarketCapHalal(stockDataVo)
    }

    fun getTotalNoOfStockFlow() = stock2022DatabaseRepo.getTotalNoOfStockFlow()

   /* fun setTotalNoOfStocks(totalNoOfShare:Int,financialResultType: FinancialResultType) {
         sharedPrefStorageRepo.save(PREF_KEY_TOTAL_SHARE+financialResultType.name,totalNoOfShare)
    }

    fun getTotalHalalNoOfStocks(financialResultType: FinancialResultType) : Int{
        return sharedPrefStorageRepo.getInt(PREF_KEY_TOTAL_HALAL_SHARE +financialResultType.name)
    }

    fun setTotalHalalNoOfStocks(totalHalalShare:Int,financialResultType: FinancialResultType) {
         sharedPrefStorageRepo.save(PREF_KEY_TOTAL_HALAL_SHARE+financialResultType.name,totalHalalShare)
    }*/

    suspend fun calculateAndSetTotalNoOfHalalStocksIfSettingChange(){
        val allStocks= stock2022DatabaseRepo.getAllStockList()
        var counterIsHalal = 0
        allStocks.forEach { StockDataVo ->
           val isHalal  = isStockHalal(StockDataVo)
            stock2022DatabaseRepo.setIsHalalStock(StockDataVo.bseCode,StockDataVo.nseCode,isHalal)
            if (isHalal){
                counterIsHalal++
            }
        }
    }
}