package com.halal.stocks.ui.downloads

import com.halal.stocks.data.model.FinancialResultType
import com.halal.stocks.data.model.mapping.StockDataVo2021
import com.halal.stocks.data.api.stocks.Stock2021DatabaseRepo
import com.halal.stocks.data.room.watchlist.WatchListRepo
import com.halal.stocks.utils.HalalScreener

class Download2021Repo(
    private val stockDoa2021: Stock2021DatabaseRepo,
    private val halalScreener: HalalScreener,
    private val watchListRepo: WatchListRepo
) {

    suspend fun insert2021CSVFile(csvDataList: List<List<String>>) {
        val listOfStocks = ArrayList<StockDataVo2021>()
        csvDataList.forEachIndexed { index, item ->
                if (index != 0) {
                    listOfStocks.add(arrayListToStockModelFromCsvStockDataVo2021(item))
                }
            }
            stockDoa2021.insertStockAll(listOfStocks)
    }

    private suspend fun arrayListToStockModelFromCsvStockDataVo2021(item:List<String>) : StockDataVo2021 {
        val nseCode = item[2]
        val isInWatchlist = false
        return StockDataVo2021(
            name = item[1],
            nseCode = nseCode,
            bseCode = item[3],
            industry = item[4],
            isCoreActivityHalal = item[5].toInt() == 1,
            businessDetail = item[6],
            debt = item[7].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            marketCapitalization = item[8].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            totalAsset = item[9].toDouble() / 10000,
            currentAsset = item[10].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            nonCurrentAsset = item[11].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            inventory = item[12].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            fixedAsset = item[13].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            intangibleAssets = item[14].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            currentLiabilities = item[15].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            nonCurrentLiabilities = item[16].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            totalLiabilities = item[17].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            totalRevenue = item[18].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            totalInterestIncome = item[19].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            annualReportUrl = item[20],
            watchListIndex= if(isInWatchlist) 1 else 0,
            isActive = item[21].toInt(),
            interestExpense = item[23].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            netInterestIncome = item[24].let {
                try {
                    it.toDouble() / 10000
                } catch (ex: Exception) {
                    0.0
                }
            },
            isIPO = item[25].toInt() == 1,
            ipoLogo = item[26],
            ipoTimetableJson = item[27]
        ).also {
            it.isHalal = halalScreener.isStockHalal(it)
        }
    }
}