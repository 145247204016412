package com.halal.stocks.data.room.watchlist

import kotlinx.coroutines.Dispatchers
import com.halal.stocks.WatchlistDataTable
import com.halal.stocks.WatchlistNameTable
import com.halal.stocks.data.DatabaseFactory
import com.halal.stocks.data.SharedPrefStorageRepo
import com.halal.stocks.data.api.stocks.Stock2022DatabaseRepo
import com.halal.stocks.data.api.user.UserAccountSyncRepo
import com.halal.stocks.data.model.mapping.StockDataVo
import com.halal.stocks.data.model.mapping.StockDataVo2022
import com.squareup.sqldelight.runtime.coroutines.asFlow
import com.squareup.sqldelight.runtime.coroutines.mapToList
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent

class WatchListRepo(
    private val database: DatabaseFactory,
    private val stockDao2022: Stock2022DatabaseRepo,
    private val sharedPrefStorageRepo: SharedPrefStorageRepo,
    private val externalScope: CoroutineScope,
    private val userAccountSyncRepo: UserAccountSyncRepo
) : KoinComponent {

    init {
        externalScope.launch {
            if (!sharedPrefStorageRepo.getBoolean("isWatchlistInit")){
                createWatchlist("Watchlist 1",false)
                sharedPrefStorageRepo.save("isWatchlistInit",true)
            }
        }
    }

    fun createWatchlist(watchlistName:String,syncRequired:Boolean=true) : Boolean{
        return with(database.appDatabase){
            if (!isWatchlistNameAlreadyExist(watchlistName)){
                getWatchlistNameDao.insert(watchlistName)

                if (syncRequired){
                    //Let the Ui know about change
                    userAccountSyncRepo.watchlistUpdated()
                }
                true
            }else
                false
        }
    }


    fun updateWatchlistName(selectedWatchlistNameTable: WatchlistNameTable, updatedName: String) : Boolean{
        return with(database.appDatabase){
            if (!isWatchlistNameAlreadyExist(updatedName)){
                getWatchlistNameDao.update(
                    watchlistName = updatedName, pKey = selectedWatchlistNameTable.pKey
                )
                //Let the Ui know about change
                userAccountSyncRepo.watchlistUpdated()
                true
            }else
                false
        }
    }

    fun getWatchlistNamesFlow(): Flow<List<WatchlistNameTable>> {
        return with(database.appDatabase){
            getWatchlistNameDao.selectAll().asFlow().mapToList()
        }
    }

    fun deleteWatchlist(nameTable: WatchlistNameTable){
        with(database.appDatabase){
            getWatchlistDataDao.deleteWatchlistData(nameTable.pKey)
            getWatchlistNameDao.delete(nameTable.pKey)
        }
    }

/*    private var watchlistNameReference:List<WatchlistNameTable>?=null
    fun getWatchlistNames(): List<WatchlistNameTable> {
        return with(database.appDatabase){
            if (watchlistNameReference!=null)
                watchlistNameReference?: emptyList()
            else {
                watchlistNameReference = getWatchlistNameDao.selectAll().executeAsList()
                watchlistNameReference?: emptyList()
            }
        }
    }*/

    fun getAllItemSizeInWatchlist(watchlistNameTable: WatchlistNameTable):  Int {
        return with(database.appDatabase){
            getWatchlistDataDao.selectAll(watchlistNameTable.pKey).executeAsList().size
        }
    }

    fun getWatchlistData(watchlistNameTable: WatchlistNameTable):  Flow<List<StockDataVo?>> {
        return with(database.appDatabase){
            getWatchlistDataDao.selectAll(watchlistNameTable.pKey).asFlow().map {
                it.executeAsList().map { watchlistData->
                    stockDao2022.getStockByNseCode(watchlistData.nseCode)
                }
            }
        }
    }

    fun addToWatchList(nseCode:String,selectedWatchlistNameTable: WatchlistNameTable):Boolean{
        return try {
            with(database.appDatabase){
                getWatchlistDataDao.insert(WatchlistDataTable(selectedWatchlistNameTable.pKey, nseCode))
                val pKey =selectedWatchlistNameTable.pKey
                stockDao2022.addedToWatchlist(nseCode,pKey.toString())
                //Let the Ui know about change
                userAccountSyncRepo.watchlistUpdated()
                true
            }
        }catch (ex:Exception){
            false
        }
    }

    fun remoteFromWatchlist(nseCode:String,selectedWatchlistNameTable: WatchlistNameTable){
        with(database.appDatabase){
            getWatchlistDataDao.remoteFromWatchlist(nseCode,selectedWatchlistNameTable.pKey)
            val pKey =selectedWatchlistNameTable.pKey
            stockDao2022.removedFromWatchlist(nseCode,pKey.toString())
            //Let the Ui know about change
            userAccountSyncRepo.watchlistUpdated()
        }
    }

    private fun isWatchlistNameAlreadyExist(watchlistName: String) : Boolean{
        return with(database.appDatabase){
            val nameList = getWatchlistNameDao.selectByName(watchlistName).executeAsOneOrNull()
            nameList!=null
        }
    }
}