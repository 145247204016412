package com.halal.stocks.data.api.stocks

import com.halal.stocks.data.api.user.UserRepo
import com.halal.stocks.utils.HalalScreener

class StockRepo(private val stockNetworkRepo: StockNetworkRepo,
                private val stock2022DatabaseRepo: Stock2022DatabaseRepo,
                private val userRepo: UserRepo,
                private val halalScreener: HalalScreener
) {

    suspend fun getStockByNseCode(nseCode:String) = stock2022DatabaseRepo.getStockByNseCode(nseCode)

    suspend fun syncRecentlyUpdatedStocks() : Boolean{
       return try {
            val lastUpdatedTime = stock2022DatabaseRepo.getMaxLastUpdatedTimeOfStock()
            val stockListOnServer = stockNetworkRepo.getAllRecentlyUpdatedStocks(lastUpdatedTime)
            stockListOnServer.forEach {
               it.isHalal = halalScreener.isStockHalal(it)
            }
            stock2022DatabaseRepo.insertOrReplaceStockAll(stockListOnServer)
           true
        }catch (ex:Exception){
           ex.printStackTrace()
            false
        }
    }
}