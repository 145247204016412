package ui.base

import react.useMemo
import ui.pages.HomePage
import ui.pages.IPOListPage

fun getBottomNavigationPageMap(): ScreenPageList {
    return useMemo {
        setOf(
//            ScreensPage("download", "Download", DownloadPage),
            ScreensPage("home", "Home", HomePage),
        )
    }
}

fun useScreenPageMapping(): ScreenPageList {
    return useMemo {
        setOf(
//            ScreensPage("download", "Download", DownloadPage),
            ScreensPage("home", "Home", HomePage),
//            ScreensPage("stock/:nseCode", "Stock", StockResultPage),
            ScreensPage("ipo", "IPO's", IPOListPage),
//            ScreensPage("backdrop", "Backdrop", BackdropShowcase),
//            ScreensPage("badges", "Badges", BadgesShowcase),
//            ScreensPage("bottom-navigation", "Bottom Navigation", BottomNavigationShowcase),
//            ScreensPage("breadcrumbs", "Breadcrumbs", BreadcrumbsShowcase),
//            ScreensPage("buttons", "Buttons", ButtonsShowcase),
//            ScreensPage("cards", "Cards", CardsShowcase),
//            ScreensPage("checkboxes", "Checkboxes", CheckboxesShowcase),
//            ScreensPage("chips", "Chips", ChipsShowcase),
//            ScreensPage("dialogs", "Dialogs", DialogsShowcase),
//            ScreensPage("drawers", "Drawers", DrawersShowcase),
//            ScreensPage("floating-action-button", "Floating Action Button", FloatingActionButtonShowcase),
//            ScreensPage("image-list", "Image List", ImageListShowcase),
//            ScreensPage("links", "Links", LinksShowcase),
//            ScreensPage("lists", "Lists", ListsShowcase),
//            ScreensPage("menus", "Menus", MenusShowcase),
//            ScreensPage("pagination", "Pagination", PaginationShowcase),
//            ScreensPage("paper", "Paper", PaperShowcase),
//            ScreensPage("popover", "Popover", PopoverShowcase),
//            ScreensPage("popper", "Popper", PopperShowcase),
//            ScreensPage("progress", "Progress", ProgressShowcase),
//            ScreensPage("radio-buttons", "Radio Buttons", RadioButtonsShowcase),
//            ScreensPage("rating", "Rating", RatingShowcase),
//            ScreensPage("selects", "Selects", SelectsShowcase),
//            ScreensPage("skeleton", "Skeleton", SkeletonShowcase),
//            ScreensPage("slider", "Slider", SliderShowcase),
//            ScreensPage("snackbars", "Snackbars", SnackbarsShowcase),
//            ScreensPage("steppers", "Steppers", SteppersShowcase),
//            ScreensPage("switches", "Switches", SwitchesShowcase),
//            ScreensPage("tables", "Tables", TablesShowcase),
//            ScreensPage("tabs", "Tabs", TabsShowcase),
//            ScreensPage("text-fields", "Text Fields", TextFieldsShowcase),
//            ScreensPage("toggle-button", "Toggle Button", ToggleButtonShowcase),
//            ScreensPage("tooltips", "Tooltips", TooltipsShowcase),
        )
    }
}